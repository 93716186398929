<template>
    <div class="tta-container">
        <NavbarTta :status_subscriptions="status_subscriptions" :loading_subscriptions="loading_subscriptions" :redirect-upgrade="willRedirectForUpgrade" @cancel="willRedirectForUpgrade = false"/>
        <section class="container-page p-60">
            <div class="container-fluid text-left text-white px-5" style="height:90vh">
                <div class="row h-100">
                    <div ref="audioCardsContainer" class="col-7 border-separator overflow-auto h-100">
                        <div class="pl-3 pr-5" style="padding-bottom:20rem;">
                            <div class="mt-4">
                                <!-- Component Input Script -->
                                <div v-for="(audio,index ) in newListAudio" :key="index" class="card list-audio mt-3 ml-5">
                                    <div v-if="audio.isFocused" class="list-option">
                                        <div class="d-flex align-items-center justify-content-between pt-2 px-3">
                                            <div class="d-flex align-items-center">
                                                <div v-if="audio.from_ai == true" class="dropdown">
                                                    <button
                                                        class="btn btn-language dropdown-toggle"
                                                        type="button"
                                                        id="characterAvatar"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        <img v-if="audio.avatar == null" src="@/assets/images/icons/sovia_editor.svg" style="width:22px; height:22px" class="img-fluid"/>
                                                        <img v-else :src="audio.avatar" style="width:22px; height:22px" class="img-fluid"/>
                                                        <span class="ml-2"> {{ audio.sound_type }}</span>
                                                    </button>
                                                    <div class="dropdown-menu" aria-labelledby="characterAvatar">
                                                        <button
                                                            v-for="avatar in audio.characters"
                                                            class="btn btn-language-options"
                                                            :key="avatar.Name"
                                                            @click="selectSoundType(index, avatar.Name, avatar.PathAvatar)"
                                                        >
                                                            <div class="d-flex align-items-center">
                                                                <img :src="avatar.PathAvatar" style="width:22px; height:22px"/>
                                                                <span class="ml-2 text-white"> {{ avatar.Name }}</span>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <button
                                                        class="btn btn-language"
                                                        type="button"
                                                        id="characterAvatar"
                                                        @click="openMasterLang(index, 'input')"
                                                    >
                                                        <img v-if="audio.avatar == null" src="@/assets/images/icons/sovia_editor.svg" style="width:22px; height:22px" class="img-fluid"/>
                                                        <img v-else :src="audio.avatar" style="width:22px; height:22px" class="img-fluid"/>
                                                        <span class="ml-2"> {{ audio.sound_type }}</span>
                                                    </button>
                                                </div>

                                                <!-- <div class="dropdown">
                                                    <div class="dropdown-menu" aria-labelledby="characterAvatar">
                                                        <button
                                                            v-for="data in characters"
                                                            class="btn btn-language-options"
                                                            :key="data.name"
                                                            @click="selectSoundType(index, data.name, data.pict)"
                                                        >
                                                            <div class="d-flex align-items-center">
                                                                <img :src="require(`@/assets/images/icons/${data.pict}`)" />
                                                                <span class="ml-2 text-white"> {{ data.name }}</span>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div> -->

                                                <!-- <div class="dropdown ml-2">
                                                    <button
                                                        class="btn btn-language dropdown-toggle"
                                                        type="button"
                                                        id="soundEffect"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        <span class="ml-2"> {{ audio.emotion ? audio.emotion : 'Normal' }}</span>
                                                    </button>
                                                    <div class="dropdown-menu" aria-labelledby="soundEffect">
                                                        <button
                                                            v-for="data in effect"
                                                            class="btn btn-language-options"
                                                            :key="data"
                                                            @click="selectEmotion(index, data)"
                                                        >
                                                            <div class="d-flex align-items-center">
                                                                <span class="ml-2 text-white"> {{ data }}</span>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div> -->

                                                <!-- <div v-if="audio.emotion != 'Excited'" class="dropdown ml-2">
                                                    <button
                                                        class="btn btn-language dropdown-toggle"
                                                        type="button"
                                                        id="pitch"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        <span class="ml-2">Pitch</span>
                                                    </button>
                                                    <div class="dropdown-menu" style="width:155px;" aria-labelledby="pitch">
                                                        <div class="container-fluid w-100">
                                                            <b-form-input id="range-1" v-model="audio.pitch" type="range" min="-10" max="10" step="5" class="ml-1"></b-form-input>
                                                            <div class="numbers">
                                                                <span v-for="num in numbers" :key="num" @click="selectPitch(index, num)">{{ num }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> -->

                                                <!-- <div class="dropdown ml-2">
                                                    <button
                                                        class="btn btn-language dropdown-toggle"
                                                        type="button"
                                                        id="speed"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        <span class="ml-2">Speed</span>
                                                    </button>
                                                    <div class="dropdown-menu" style="width:155px;" aria-labelledby="speed">
                                                        <div class="container-fluid w-100">
                                                            <b-form-input id="range-2" v-model="audio.speed" type="range" min="-2" max="2" class="ml-1"></b-form-input>
                                                            <div class="numbers">
                                                                <span v-for="num in numbers" :key="num">{{ num }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> -->
                                            </div>
                                            <div class="d-flex">
                                                <!-- <img v-if="play" src="@/assets/images/icons/ic_pause_shape_editor.svg" alt="Pause" class="cursor-pointer" @click="play = !play">
                                                <img v-else src="@/assets/images/icons/ic_play_shape_editor.svg" alt="Play" class="cursor-pointer" @click="play = !play"> -->
                                                <button
                                                    type="button"
                                                    title="Expand"
                                                    class="btn btn-expand d-flex justify-content-center align-items-center mr-3 rounded"
                                                    @click="expandCardModal(index)"
                                                >
                                                    <font-awesome-icon icon="fa-solid fa-expand" />
                                                </button>
                                                <div v-if="audio.loading_generate" class="d-flex align-items-center">
                                                    <div class="spinner-border spinner-border-sm" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                                <button
                                                    v-else-if="generateCheck(index)"
                                                    type="button"
                                                    title="Generate"
                                                    class="btn btn-generate-card d-flex justify-content-center align-items-center rounded"
                                                    @click="regenerateAudio(index, 'default')"
                                                >
                                                    <font-awesome-icon icon="fa-solid fa-play" />
                                                </button>
                                                <button
                                                    v-else
                                                    type="button"
                                                    title="Generate"
                                                    class="btn btn-expand d-flex justify-content-center align-items-center rounded"
                                                    @click="regenerateAudio(index, 'default')"
                                                >
                                                    <font-awesome-icon :icon="audio.iconPlay == 'ic_play_shape_editor.svg' ? 'fa-solid fa-play' : 'fa-solid fa-pause'" />
                                                </button>
                                                <!-- <img v-else-if="generateCheck(index)" src="@/assets/images/icons/ic_play_generate.svg" alt="Play" class="cursor-pointer" style="width:26px; height:26px;" @click="regenerateAudio(index)"> -->
                                                <b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none text-white p-0" no-caret class="ml-4 text-white">
                                                    <template #button-content>
                                                        <img src="@/assets/images/icons/ic_options_editor.svg" alt="Options" class="img-fluid" style="width:4px">
                                                    </template>
                                                    <!-- <b-dropdown-item class="" @click="duplicateAudio(index)">Duplicate</b-dropdown-item> -->
                                                    <b-dropdown-item :class="listTextToAudio.length == 1 && listTextToAudio[0].text == '' ? 'cursor-disabled-item' : ''" @click="listTextToAudio.length == 1 && listTextToAudio[0].text == '' ? '' : deleteIndex(index)">
                                                        <div class="d-flex align-items-center">
                                                            <img src="@/assets/images/icons/ic_delete_sb.svg" alt="Delete" class="mr-2" width="13px">
                                                            Delete
                                                        </div>
                                                    </b-dropdown-item>
                                                </b-dropdown>
                                            </div>
                                        </div>
                                        <hr class="mt-2 mb-0" style="background : #2D2D2D;">
                                    </div>
                                    <div class="inner-card" :class="play ? 'bg-played' : ''">
                                        <div class="text-white">
                                            <!-- <div class="w-input-text-group">
                                                <div id="w-input-text"><p contenteditable class="mb-0 text-white text-left" @input="onInputScript($event, index)"></p></div>
                                                <div v-if="audio.text == null || audio.text == ''" class="w-placeholder">
                                                    Input your text
                                                </div>
                                            </div> -->
                                            <!-- <textarea name="" id="" class="w-100 form-control" placeholder="Input your text" v-model="audio.text" @focus="scriptInputFocus(index)"></textarea> -->
                                            <TTAScriptEditor
                                                v-model="audio.text"
                                                placeholder="Input your text"
                                                @focus="scriptInputFocus(index)"
                                                @get-editor="(editor) => defaultScriptEditors[index] = editor"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="add-script-option mt-4">
                                <button class="add-script-option__button btn mr-2" @click="addIndexAudio">
                                    <img src="@/assets/images/icons/ic_plus_editor.svg" alt="Add">
                                    <p class="mb-0 ml-2">Add New</p>
                                </button>
                                <button class="add-script-option__button btn" @click="$bvModal.show('import-script-modal')">
                                    <img src="@/assets/images/icons/ic_import_script.svg" alt="Add">
                                    <p class="mb-0 ml-2">Import Script</p>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-5" style="height:50vh">
                        <div class="pl-5 h-100">
                            <h5 class="text-white mt-4">Preview</h5>
                            <div v-if="newFinalPathTTA != null" class="d-flex align-items-center justify-content-center h-100">
                                <video id="video-tta" ref="videoPlayer" width="100%" height="250px" controls controlsList="nodownload" @timeupdate="updateTimeVideo" @playing="stopAudio(); updateTimeVideo()">
                                    <source id="change-src" :src="newFinalPathTTA" type="video/mp4" class="shadow-video">
                                </video>
                            </div>
                            <div v-else-if="newFinalPathTTA == null && newImageArray.length > 0" class="text-center d-flex align-items-center justify-content-center h-100">
                                <img :src="newImageArray[0].image_url" alt="" class="img-fluid mt-2" style="height:250px">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="position-absolute w-100 d-flex flex-column justify-content-end pb-2" style="bottom: 0; background-color:black;">
                    <div class="section-function pt-2" style="border-top:0.1px solid #2D2D2D;">
                        <div class="float-left my-auto ml-3">
                            <div class="pill-timeline mt-1" @click="timelineShow">
                                <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg" :class="show_timeline ? '' : 'rotate-arrow' ">
                                    <path d="M1 1L4 4L7 1" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <span class="ml-1">Timeline</span>
                            </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-center">
                            <button class="button__backward-skip" :style="sliderPosition == 0 ? 'color:#2D2D2D' : 'color:white;'" @click="sliderPosition > 0 ? backwardAudio() : ''">
                                <font-awesome-icon icon="fa-solid fa-backward-step" />
                            </button>
                            <div v-if="loadingFinalGenerate" class="d-flex align-items-center mx-3">
                                <div class="spin-load">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="8" cy="8" r="7" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-dasharray="3 3"/>
                                    </svg>
                                </div>
                                <span class="text-white ml-2">Generating audio</span>
                            </div>
                            <div v-else>
                                <button v-if="newListAudio.length > 0 && newListAudio[0].course_id == ''" class="button__audio mx-1" @click="alertTts()">
                                    <font-awesome-icon icon="fa-solid fa-play" />
                                </button>
                                <button v-else class="button__audio mx-1">
                                    <font-awesome-icon :icon="!final_video_play ? 'fa-solid fa-play' : 'fa-solid fa-pause'" @click="generateTTA()"/>
                                </button>
                                <!-- <img v-if="newListAudio.length > 0 && newListAudio[0].course_id == ''" src="@/assets/images/icons/ic_play_circle_editor.svg" alt="Play" class="img-fluid mx-3 cursor-pointer" @click="alertTts()">
                                <img v-else-if="!final_video_play" src="@/assets/images/icons/ic_play_circle_editor.svg" alt="Play" class="img-fluid mx-3 cursor-pointer" @click="generateTTA()">
                                <img v-else src="@/assets/images/icons/ic_pause_circle_editor.svg" alt="Pause" class="img-fluid mx-3 cursor-pointer" @click="generateTTA()"> -->
                            </div>
                            <p class="timer mb-0 mx-2">{{ currentTimeVideo != null ? currentTimeVideo : '00:00' }} <span style="color:#2D2D2D;">/ {{ generatedVideoDuration != null ? generatedVideoDuration : '00:00' }}</span></p>
                            <div class="dropdown dropup ml-2">
                                <button type="button" class="btn btn-equalizer position-relative" data-toggle="dropdown" aria-expanded="false">
                                    <img src="@/assets/images/icons/ic_settings_timeline.svg" alt="Setting" class="img-fluid cursor-pointer">
                                </button>
                                <div class="dropdown-menu inner-equalizer dropdown-menu-right text-center mb-2 ml-5">
                                    <p class="mb-0 text-white mt-2" style="font-size:12px;">Equalizer</p>
                                    <div class="d-flex my-3 mx-2">
                                        <div class="d-flex flex-column align-items-center mx-3" data-stopPropagation="true">
                                            <vue-slider data-stopPropagation="true" v-model="volume_video" direction="btt" :width="8" :height="120" :min="0" :max="1" :interval="0.1" :tooltip="'none'" @change="changeVolumeVideo"/>
                                            <!-- <input type="range" class="range-audio" id="voiceRange" orient="vertical" min="0" max="1" step="0.01" v-model="volume_video" @change="changeVolumeVideo" @input="updateRangeColorAudio"> -->
                                            <img src="@/assets/images/icons/ic_mic_eq.svg" alt="Mic" class="ic-equalizer mt-3">
                                        </div>
                                        <div class="d-flex flex-column align-items-center mx-3" data-stopPropagation="true">
                                            <vue-slider data-stopPropagation="true" v-model="volume_music" direction="btt" :width="8" :height="120" :min="0" :max="1" :interval="0.1" :tooltip="'none'" @change="changeVolumeMusic"/>
                                            <!-- <input type="range" class="" id="musicRange" orient="vertical" min="0" max="1" step="0.01" v-model="volume_music" @change="changeVolumeMusic"> -->
                                            <img src="@/assets/images/icons/ic_music_eq.svg" alt="Music" class="ic-equalizer mt-3">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="dropdown dropup ml-2">
                                <button type="button" class="btn btn-equalizer position-relative" data-toggle="dropdown" aria-expanded="false">
                                    <img src="@/assets/images/icons/ic-menu-phone.svg" alt="Setting" class="img-fluid cursor-pointer">
                                </button>
                                <div class="dropdown-menu inner-equalizer dropdown-menu-right text-center mb-2 ml-5" style="background-color: #1F1F1F;">
                                    <p class="mb-0 text-white mt-2" style="font-size:12px;">Video Preference</p>
                                    <div class="d-flex my-3 mx-4">
                                        <div class="large-menu-video cursor-pointer" @click="setRatio('9:16')">
                                            <img src="@/assets/images/icons/large-video-phone.png" alt="large-video" width="100" class="ratio-image mb-2" :class="{ 'ratio-image__active' : set_ratio == '9:16' }">
                                            <span class="text-white" style="font-size:10px;">Reels, Shorts & Tiktok</span>
                                        </div>
                                        <div class="mx-3 medium-menu-video cursor-pointer" @click="setRatio('1:1')">
                                            <img src="@/assets/images/icons/medium-video-phone.png" alt="large-video" width="100" class="ratio-image mb-2" :class="{ 'ratio-image__active' : set_ratio == '1:1' }">
                                            <span class="text-white" style="font-size:10px;">FB & IG Feeds</span>
                                        </div>
                                        <div class="small-menu-video cursor-pointer" @click="setRatio('')">
                                            <img src="@/assets/images/icons/small-video-phone.png" alt="large-video" width="100" class="ratio-image mb-2" :class="{ 'ratio-image__active' : set_ratio == '' || set_ratio == null }">
                                            <span class="text-white" style="font-size:10px;">Youtube</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="section-timeline pt-2 overflow-auto">
                        <div class="d-flex">
                            <div class="section-editor sticky-section">
                                <div class="d-flex section-ruler">
                                    &nbsp;
                                </div>
                                <div v-if="show_timeline" class="d-flex align-items-center justify-content-center section-layer">
                                    <div class="mx-4">
                                        <img src="@/assets/images/icons/ic_mic_editor.svg" alt="Mic" class="ic-actions">
                                    </div>
                                </div>
                                <div v-if="show_timeline" class="d-flex align-items-center justify-content-center section-layer">
                                    <div class="mx-4">
                                        <img src="@/assets/images/icons/ic_music_editor.svg" alt="Music" class="ic-actions">
                                    </div>
                                </div>
                                <div v-if="show_timeline" class="d-flex align-items-center justify-content-center section-layer">
                                    <div class="mx-4">
                                        <img src="@/assets/images/icons/ic_img_editor.svg" alt="Image" class="ic-actions">
                                    </div>
                                </div>
                            </div>
                            <div class="section-editor">
                                <div class="line-slider ml-2 cursor-pointer" :style="{left: sliderPosition + 'px'}" @mousedown="startDrag"></div>
                                <div ref="timeline" class="d-flex align-items-center justify-content-center section-ruler cursor-pointer">
                                    &nbsp;
                                    <div class="bg-transparent d-flex align-items-center position-absolute" :class="index == 0 ? 'ml-2' : ''" v-for="(value, index) in arrRuler" :key="index" :style="{left: value*20 + 'px'}" @click="moveSliderTo">
                                        <!-- <svg v-for="i in 4" :key="i" width="19" height="8">
                                            <circle cx="6" cy="3" r="3" fill="#2D2D2D"/>
                                        </svg>
                                        {{value}}  -->
                                        <template v-if="value % 10 != 0">
                                            <svg width="12" height="8" class="ml-1">
                                                <circle cx="6" cy="3" r="3" fill="#2D2D2D"/>
                                            </svg>
                                        </template>
                                        <template v-else-if="value == 0">
                                            {{value}}s
                                        </template>
                                        <template v-else>
                                            {{value}}s
                                        </template>
                                    </div>
                                </div>
                                <div v-if="show_timeline" class="d-flex section-layer">
                                    <!-- <button v-if="listTextToAudio[0].course_id == null" class="btn btn-add ml-2" disabled>
                                        <img src="@/assets/images/icons/ic_plus_editor.svg" alt="Add" class="img-fluid mx-4 ic-actions">
                                    </button>

                                    <button v-else class="btn btn-add ml-2" @click="addIndexAudio">
                                        <img src="@/assets/images/icons/ic_plus_editor.svg" alt="Add" class="img-fluid mx-4 ic-actions">
                                    </button> -->
                                    <div id="resizable-tta" :class="index == 0 ? 'ml-2' : ''" v-for="(value, index) in newListAudio" :key="index" v-bind:style="{'width': value.length * 20 + 'px' }">
                                        <av-waveform
                                            :key="value.length"
                                            :audio-controls="false"
                                            :playtime-slider="false"
                                            :playtime-clickable="false"
                                            :canv-width="value.length*20"
                                            :canv-height = "30"
                                            noplayed-line-color="#ffffff"
                                            played-line-color="#aeaeae"
                                            :playtime="false"
                                            :playtime-slider-width="0"
                                            playtime-slider-color="#00000000"
                                            :audio-src="value.path"
                                            v-if="listTextToAudio.length > 0 && newListAudio[0].course_id != null && value.length > 0"
                                        ></av-waveform>
                                    </div>
                                </div>
                                <div v-if="show_timeline" class="d-flex section-layer">
                                    <div id="resizable-music" :class="index == 0 ? `${index} ml-2` : `${index}`" v-for="(value, index) in newMusicArray" :key="index" v-bind:style="{'width': value.duration * 20 + 'px', 'max-width': value.durationOrigin * 20 + 'px' }" ref="menuContainer" @contextmenu="showMenuDeleteMusic">
                                        <av-waveform
                                            :audio-controls="false"
                                            :playtime-slider="false"
                                            :playtime-clickable="false"
                                            :canv-width="value.durationOrigin * 20"
                                            :canv-height = "30"
                                            noplayed-line-color="#ffffff"
                                            played-line-color="#aeaeae"
                                            :playtime="false"
                                            :playtime-slider-width="0"
                                            playtime-slider-color="#00000000"
                                            :audio-src="value.fileAudio"
                                        ></av-waveform>
                                    </div>
                                    <button class="btn btn-add ml-2" @click="$bvModal.show('upload-music-editor')">
                                        <img src="@/assets/images/icons/ic_plus_editor.svg" alt="Add" class="img-fluid ic-actions">
                                    </button>
                                </div>
                                <div v-if="show_timeline" class="d-flex section-layer" >
                                    <div id="resizable-image" :class="index == 0 ? `${index} ml-2` : `${index}`" v-for="(value, index) in newImageArray" :key="index" v-bind:style="{ 'background-image': 'url(' + value.image_thumb + ')','width': value.length * 20 + 'px' }" @contextmenu="showMenu">
                                    </div>
                                    <button class="btn btn-add ml-2" @click="$bvModal.show('upload-image-editor')">
                                        <img src="@/assets/images/icons/ic_plus_editor.svg" alt="Add" class="img-fluid ic-actions">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>

            <!-- MODAL UPLOAD IMG -->
            <b-modal id="upload-image-editor" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="lg" modal-class="upload-image-editor-modal">
                <div class="position-relative mt-2">
                    <button class="btn btn-close d-flex justify-content-center align-items-center position-absolute close-image" @click="$bvModal.hide('upload-image-editor')"><b-icon icon="x"></b-icon></button>
                    <vs-tabs :color="colorx">
                        <vs-tab label="Gallery">
                            <div class="con-tab-ejemplo gallery mt-2">
                                <div style="height:28rem;">
                                    <label for="search">
                                        <input type="text" class="search-bar" placeholder="Find a picture" v-model="searchImage" @change="getImageSearch(searchImage)">
                                    </label>
                                    <div class="d-flex align-items-center ml-3 mt-2">
                                        <span class="text-muted powered-by">Powered By </span>
                                        <img src="@/assets/images/icons/unsplash-logo.svg" alt="unsplash" class="img-fluid ml-2" width="70">
                                    </div>
                                    <div class="container-fluid overflow-auto container-thumbnail mt-3" id="img-load" @scroll="onScrollLoadImg" ref="imgLoad">
                                        <b-card-group columns>
                                            <b-card
                                            v-for="(value, index) in list_images" :key="index" class="px-0"
                                            :img-src="value.urls.thumb"
                                            img-alt="Image"
                                            img-top
                                            body-class="p-0 m-0"
                                            @click="selectImage(value.urls.regular, value.urls.thumb)"
                                            :class="selectedImage.length > 0 && selectedImage[0].image_url  == value.urls.regular ? 'image-selected' : ''"
                                            >

                                            </b-card>
                                        </b-card-group>
                                        <!-- <b-overlay :show="loadingUnsplash" rounded="sm" class="mt-3"></b-overlay> -->
                                        <!-- <div class="container-galery">
                                            <div class="box">
                                                <div class="colum-img">
                                                    <div v-for="(value, index) in list_images" :key="index" class="mb-3 px-0">
                                                        <button class="btn-thumbnail" @click="selectImage(value.urls.regular, value.urls.thumb)">
                                                            <img :src='value.urls.thumb' alt="" class="img-fluid gallery-img" :class="selectedImage.length > 0 && selectedImage[0].image_url  == value.urls.regular ? 'image-selected' : ''"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>   -->
                                    </div>
                                </div>
                            </div>
                        </vs-tab>
                        <vs-tab label="Image Generator">
                            <div class="con-tab-ejemplo gaim">
                                <div class="mt-3">
                                    <AiGeneratorImage v-model="formGaim" :is-generating="generateImage" @generate="handleGenerateAiImage">
                                        <template #settings>
                                            <div
                                                class="ac__settings align-items-center p-3 text-white text-left"
                                            >
                                                <!-- <div class="ac__settings-section">
                                                    <p class="ac__settings-title m-0">Resolution</p>
                                                    <span class="ac__settings-divider">:</span>
                                                    <ul class="ac__settings-list d-flex m-0 list-unstyled">
                                                        <li v-for="resolution in resolutions" :key="resolution">
                                                            <button
                                                                type="button"
                                                                class="ac__settings-button ac__settings-button--resolution btn d-inline-flex justify-content-center align-items-center py-1 text-white"
                                                                :class="{ 'ac__settings-button--active': resolution === formGaim.resolution }"
                                                                :title="`Select ${resolution}`"
                                                                @click="formGaim.resolution = resolution"
                                                            >
                                                                {{ resolution }}
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div> -->
                                                <div class="ac__settings-section">
                                                    <p class="ac__settings-title m-0">Ratio</p>
                                                    <span class="ac__settings-divider">:</span>
                                                    <ul class="ac__settings-list d-flex m-0 list-unstyled">
                                                        <li v-for="ratio in ratios" :key="ratio.id">
                                                            <button
                                                                type="button"
                                                                class="ac__settings-button ac__settings-button--ratio btn d-inline-flex justify-content-center align-items-center py-1 text-white"
                                                                :class="{ 'ac__settings-button--active': ratio.id === formGaim.ratio }"
                                                                :title="`Select ${ratio.name}`"
                                                                @click="formGaim.ratio = ratio.id"
                                                            >
                                                                <span
                                                                    class="ac__settings-ratio-box"
                                                                    :style="{ aspectRatio: ratio.css }"
                                                                />
                                                                {{ ratio.name }}
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="ac__settings-section">
                                                    <p class="ac__settings-title m-0">Outputs</p>
                                                    <span class="ac__settings-divider">:</span>
                                                    <ul class="ac__settings-list d-flex m-0 list-unstyled">
                                                        <li v-for="output in outputs" :key="output">
                                                            <button
                                                                type="button"
                                                                class="ac__settings-button ac__settings-button--output btn d-inline-flex justify-content-center align-items-center py-1 text-white"
                                                                :class="{ 'ac__settings-button--active': output === formGaim.output }"
                                                                :title="`Select ${output}`"
                                                                @click="formGaim.output = output"
                                                            >
                                                                {{ output }}
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </template>
                                    </AiGeneratorImage>
                                </div>
                                <!-- <p class="agi--text-info mt-2 mb-3">
                                    <img src="@/assets/images/icons/ic_info_navtt.svg" alt="information" width="14">
                                    &nbsp;you will be charged 5 SWC
                                </p> -->
                                <ul class="agi--list mt-2 list-unstyled">
                                    <li v-for="(data, index) in outputImageAi" :key="index">
                                        <button
                                            type="button"
                                            class="agi__character-button agi__character-button--selectable btn p-0"
                                            :class="{ 'agi__character-button--selected' : selectedImage[0]?.image_url == data }"
                                            title="Select image"
                                            @click="selectImage(data, data)"
                                            >
                                            <img
                                                :src="data"
                                                class="agi__character-button-image"
                                            />
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </vs-tab>
                        <vs-tab label="Upload">
                            <div class="con-tab-ejemplo upload-img" @dragover.prevent @dragenter.prevent @dragleave.prevent @drop.prevent>
                                <div class="container-fluid">
                                    <b-form-group class="mt-2">
                                        <b-alert variant="danger" :show="showAlertImgUpload" fade dismissible @dismissed="showAlertImgUpload=false" class="mb-0">
                                            {{responseImg}}
                                        </b-alert>
                                    </b-form-group>
                                    <label @drop="dragImage($event)" class="btn btn-impor-custom w-100 d-flex flex-column py-5 align-items-center text-center mt-3">
                                        <!-- <div v-if="img_upload != '-' && img_upload != '' &&  img_upload != null && img_upload != undefined" class="pt-3 h-100 d-flex align-items-center">
                                            <div>
                                                <img :src="img_upload"  alt="" class="img-form img-fluid">
                                                <small class="my-auto ml-2 text-white">{{ typeof imageFileBefore === "string" ? 'image.jpg' : imagenameBefore }}</small>
                                            </div>
                                        </div> -->
                                        <div class="h-100 d-flex align-items-center">
                                            <div v-if="!loadingImageUpload">
                                                <img src="@/assets/images/logo/paper_impor.svg" alt="" class="img-fluid" width="30px;">
                                                <h5 class="font-weight-normal mt-2" style="color:#6D75F6;">Choose File or Drag Here</h5>
                                                <h6 class="font-weight-normal text-muted mt-1"><i>format file : .jpg .png</i></h6>
                                                <h6 class="font-weight-normal text-muted mt-1"><i>max. file size 1MB</i></h6>
                                            </div>
                                            <div v-else class="spinner-border spinner-border-sm text-white" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                        <input v-if="!loadingImageUpload" type="file" accept="image/jpeg, image/png" style="display: none" @change="imageUpload($event)"/>
                                    </label>
                                </div>
                                <div class="container-fluid">
                                    <h6 class="mt-4 font-weight-normal">Past Uploads</h6>
                                    <div v-if="!loadingGetImage">
                                        <div v-if="imageLibrary.length > 0" class="row past-upload overflow-auto mt-1 pb-2">
                                            <div v-for="(data, index) in newImageLibrary" :key="index" class="col-3 mt-3">
                                                <div class="card cursor-pointer position-relative" :class="index % 4 == 0 ? 'mr-2 ml-3' : index % 4 == 4 ? 'ml-2 mr-3' : 'mx-2'"  :style="selectedImgLibrary == data._id ? 'border: 3px solid #6D75F6' : ''" @click="selectImageLibrary(data._id, data.image_url)">
                                                    <img :src="data.image_url" alt="" class="card-img-top">
                                                    <img v-if="selectedImgLibrary == data._id" src="@/assets/images/icons/ic_imglib_selected.svg" alt="" class="position-absolute selected-img">
                                                    <div class="card-body p-2">
                                                        <div class="d-flex align-items-center justify-content-between">
                                                            <p class="mb-0 text-truncate" style="font-size: 10px;">{{ data.filename ? data.filename : '-' }}</p>
                                                            <div class="dropdown">
                                                                <button
                                                                    class="btn btn-transparent p-0 d-flex align-items-start"
                                                                    type="button"
                                                                    id="imglibrary"
                                                                    data-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false"
                                                                >
                                                                    <b-icon icon="three-dots-vertical" style="color:#fff;" font-scale="0.8"></b-icon>
                                                                </button>
                                                                <div class="dropdown-menu" aria-labelledby="imglibrary">
                                                                    <button
                                                                        class="btn btn-transparent pl-1 pr-2 py-0" @click="deleteImageLibrary(data._id)"
                                                                    >
                                                                        <div class="d-flex align-items-center">
                                                                            <span class="ml-2 text-white">Delete</span>
                                                                        </div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else class="past-upload">
                                            <div class="d-flex align-items-center justify-content-center h-100">
                                                <h6 class="font-weight-normal" style="color:#979797;">
                                                    You don’t have any uploads
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="row past-upload overflow-auto mt-1 pb-2">
                                            <div v-for="index in 4" :key="index" class="col-3 mt-3">
                                                <div class="card" :class="index % 4 == 0 ? 'mr-2 ml-3' : index % 4 == 4 ? 'ml-2 mr-3' : 'mx-2'">
                                                    <b-skeleton-img card-img="top"></b-skeleton-img>
                                                    <div class="card-body p-2">
                                                        <b-skeleton animation="wave" width="30%"></b-skeleton>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </vs-tab>
                    </vs-tabs>
                    <hr style="background-color: #2D2D2D;">
                    <div class="float-right">
                        <button class="btn btn-md btn-grey mr-2" @click="cancelImg"><p class="mb-0 mx-2">Cancel</p></button>
                        <button class="btn btn-purple btn-md text-white" @click="useImage()"><p class="mb-0 mx-2">Use</p></button>
                    </div>
                </div>
            </b-modal>

            <!-- MODAL UPLOAD MUSIC -->
            <b-modal id="upload-music-editor" centered no-close-on-backdrop header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="lg">
                <div class="d-flex align-items-start">
                    <vs-tabs :color="colorx">
                        <vs-tab label="Music Library">
                            <div class="container-fluid">
                                <div class="row" style="max-height:99vh">
                                    <div class="col-md-3">
                                        <div class="d-flex align-items-center justify-content-between mt-3">
                                            <p class="title-filter">Filter</p>
                                            <!-- <p :class="statusCheck ? 'pr-3 btn-clearall cursor-pointer' : 'pr-3 btn-clearall cursor-pointer d-none'" @click="clearFilter()">Clear all</p> -->
                                        </div>
                                        <div class="list-filter">
                                            <ul>
                                                <li class="d-flex mb-2" v-for="(item, index) in listCheckBoxMusic" :key="index" ><vs-checkbox v-model="filterMusic" :vs-value="item" color="#6D75F6" :id="'check-'+item" :disabled="filterMusic.length == 0 ? false : filterMusic[0] == item ? false : true ">{{ item }}</vs-checkbox></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-md-1 hr-vertical">
                                        <span class="d-none"></span>
                                    </div>
                                    <div class="col-md-8 h-100">
                                        <div class="position-relative mt-2 text-white">
                                            <div class="gallery">
                                                <label for="search">
                                                    <input type="text" v-model="inputMusic" class="search-bar" placeholder="Find a Music">
                                                </label>
                                                <div v-show="!loadMusic" class="container-music overflow-auto mt-3"  ref="containerMusic">
                                                    <div v-show="searchMusic.length > 0">
                                                        <div v-for="(data, index) in searchMusic" :key="index" class="card card-music mt-3"  :class="isSelectedMusic == index && 'selected-music'">
                                                            <div class="container-fluid py-3 w-100" @click="selectMusic(index)" >
                                                                <div class="row px-3">
                                                                    <div class="col-4 d-flex align-items-center">
                                                                        <img v-if="!data.loading" :src="require(`@/assets/images/icons/${data.iconFill}`)" alt="Play" class="img-fluid cursor-pointer" @click="playAudio(index)">
                                                                        <div v-else class="spinner-border spinner-border-sm" role="status">
                                                                            <span class="sr-only">Loading...</span>
                                                                        </div>
                                                                        <p class="mx-3 mb-0 text-truncate">{{ data.name }}</p>
                                                                    </div>
                                                                    <div class="col-8 d-flex align-items-center">
                                                                        <div class="w-100">
                                                                            <b-progress height="5px" :min="0" :max="data.durationOrigin" variant="primary" :value="data.currentTime"></b-progress>
                                                                        </div>
                                                                        <p class="ml-3 mb-0">{{ formatTime(data.timeLabel) }}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-show="loadScrollMusic" class="w-100 my-3 text-center">
                                                            <div class="spinner-border spinner-border-sm" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="searchMusic.length == 0">
                                                        <p class="mb-0 mt-5 pt-5 text-center">Music not found</p>
                                                    </div>
                                                </div>
                                                <div v-if="loadMusic" class="h-100 d-flex align-items-center justify-content-center mt-5 pt-5">
                                                    <div class="spinner-border spinner-border-sm" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </vs-tab>
                        <vs-tab label="Upload" disabled>
                                <div class="con-tab-ejemplo" @dragover.prevent @dragenter.prevent @dragleave.prevent @drop.prevent>
                                    <label @drop="dragMusic($event)" class="btn btn-impor-custom w-100 py-5 text-center mt-3 d-flex justify-content-center align-items-center" v-if="music_upload == null">
                                        <div class="h-100 d-flex align-items-center">
                                            <div>
                                                <img src="@/assets/images/logo/paper_impor.svg" alt="" class="img-fluid">
                                                <h4 class="font-weight-normal mt-2" style="color:#6D75F6;">Select a music file to upload</h4>
                                                <h5 class="font-weight-normal text-muted mt-1">or drag & drop here</h5>
                                                <h5 class="font-weight-normal text-muted mt-1">format file : .mp3</h5>
                                            </div>
                                        </div>
                                        <input type="file" accept="audio/mp3" style="display: none" @change="musicUpload($event)"/>
                                    </label>
                                    <div v-if="music_upload != '-' && music_upload != '' &&  music_upload != null && music_upload != undefined" class="pt-3 btn-impor-custom w-100 py-5 text-center mt-3 d-flex align-items-center">
                                        <div class="container-fluid">
                                            <div class="row d-flex justify-content-center">
                                                <div class="col-md-6">
                                                    <div class="card-music-upload d-flex alig-items-center py-3 w-100 justify-content-start px-3">
                                                        <img :src="require(`@/assets/images/icons/${audioForUpload.icon}`)" alt="Play" class="img-fluid cursor-pointer" @click="playAudioForUpload(audioForUpload)">
                                                        <p class="mx-3 mb-0 text-white">{{ musicnameBefore }}</p>
                                                        <div class="d-flex align-items-center w-100">
                                                            <div class="w-100">
                                                                <b-progress height="5px" :min="0" :max="100" variant="primary" :value="audioForUpload.progress"></b-progress>
                                                            </div>
                                                        </div>
                                                        <p class="text-white mb-0 ml-2 mt-1">{{ audioForUpload.timeProgress }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <small class="my-auto ml-2 text-white">{{ typeof musicFileBefore === "string" ? 'image.jpg' : musicnameBefore }}</small> -->
                                    </div>
                                </div>
                            </vs-tab>
                    </vs-tabs>
                    <button class="btn btn-close d-flex justify-content-center align-items-center position-absolute close-image mr-3 mt-1" @click="closeMusic"><b-icon icon="x"></b-icon></button>
                </div>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <hr style="background-color: #2D2D2D;">
                            <div class="float-right">
                                <button class="btn btn-md btn-grey mr-2" @click="closeMusic"><p class="mb-0 mx-2">Cancel</p></button>
                                <button class="btn btn-purple btn-md text-white px-3" @click="useMusic()"><p class="mb-0 mx-2">Use</p></button>
                            </div>
                        </div>
                    </div>
                </div>
            </b-modal>

            <!-- MODAL SELECT A VOICE -->
            <b-modal id="select-voice-modal" centered no-close-on-backdrop header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="lg">
                <div class="container-svm">
                    <div class="separator-bottom">
                        <div class="container-fluid mt-2 mb-4">
                            <div class="d-flex align-items-center w-100">
                                <div>
                                    <h4>Select a Voice</h4>
                                    <small class="text-nowrap">Choose from 50+ voices in 20+ Languages</small>
                                </div>
                                <label for="search" class="w-100 mr-4 ml-5 mb-0">
                                    <input type="text" class="search-bar-voice text-white" placeholder="Find a voice name">
                                </label>
                                <button class="btn btn-close d-flex justify-content-center align-items-center close-image" @click="closeMasterLang"><b-icon icon="x"></b-icon></button>
                            </div>
                        </div>
                    </div>
                    <div class="container-fluid" style="height:28rem;">
                        <div class="row h-100 px-3">
                            <div class="col-4 pt-3" style="border-right: 0.1px solid #2D2D2D;">
                                <div class="dropdown mr-4">
                                    <button
                                        class="btn btn-lang d-flex align-items-center"
                                        type="button"
                                        id="chooseLang"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <div class="d-flex align-items-center justify-content-between px-3 w-100">
                                            <div class="d-flex">
                                                <img :src="selectedMasterLang.flags" class="img-fluid" style="width:18px; height: 18px;"/>
                                                <span class="ml-2 mr-1" style="font-size:15px !important"> {{ selectedMasterLang.country }}</span>
                                            </div>
                                            <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 1L4 4L7 1" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </button>
                                    <div class="dropdown-menu mt-2 w-100" aria-labelledby="chooseLang">
                                        <div v-if="!loadMasterLang" class="px-1">
                                            <div class="select-lang overflow-auto px-1 pt-0">
                                                <button
                                                    v-for="(val, index) in sortedCountry"
                                                    class="btn btn-transparent px-3 d-block w-100"
                                                    :key="index"
                                                    @click="changeLang(val.DisplayName, val.Flags)"
                                                >
                                                    <div class="d-flex align-items-center">
                                                        <img :src="baseurl_ml + val.Flags" class="img-fluid" style="width:18px; height: 18px;"/>
                                                        <span class="ml-2 text-white" style="font-size:15px !important"> {{ val.DisplayName }}</span>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                        <div v-else class="px-1">
                                            <div class="select-lang overflow-auto px-1 pt-0">
                                                <button
                                                    v-for="index in 15"
                                                    class="btn btn-transparent px-3 d-block mr-0 w-100"
                                                    :key="index"
                                                >
                                                    <div class="d-flex align-items-center w-100">
                                                        <b-skeleton type="avatar"></b-skeleton>
                                                        <b-skeleton animation="wave" width="100%" class="ml-2"></b-skeleton>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center mt-2 mr-4">
                                    <button class="btn text-white w-100 mr-1" :style="selected_gender == 'Male' ? 'background-color:#6D75F6;': 'background-color:#2d2d2d;'" @click="selectGenderLang('Male')">Male</button>
                                    <button class="btn text-white w-100 ml-1" :style="selected_gender == 'Female' ? 'background-color:#6D75F6;': 'background-color:#2d2d2d;'" @click="selectGenderLang('Female')">Female</button>
                                </div>
                            </div>
                            <div class="col-8 pt-3">
                                <div v-if="!loadMasterLangFilter" class="container-fluid ml-3">
                                    <div class="row">
                                        <div class="col-4" v-for="(data, index) in filteredCharacter.slice(0, 2)" :key="index">
                                            <div class="card-avatar card mr-2">
                                                <img :src="data.PathThumbnailAvatar" :alt="data.Name">
                                                <div class="card-body">
                                                    <div class="d-flex align-items-center">
                                                        <button class="card-avatar__button-audio btn" @click="playPreviewAudio(index, data.Emotion, data.Name)">
                                                            <font-awesome-icon :icon="data.icon_audio == 'play_svm.svg' ? 'fa-solid fa-play' : 'fa-solid fa-pause'" style="width: 9px;"/>
                                                        </button>
                                                        <button v-if="index_card != null && !from_ai" class="card-avatar__button-select btn btn-block ml-2" :class="listTextToAudio[index_card].sound_type == data.Name ? 'btn-selected' : 'btn-purple'" @click="selectCharacter(data.Name, data.Emotion, data.PathAvatar)">{{ listTextToAudio[index_card].sound_type == data.Name ? 'Selected' : 'Select'}}</button>
                                                        <button v-else class="card-avatar__button-select btn btn-block ml-2 btn-purple" @click="selectCharacter(data.Name, data.Emotion, data.PathAvatar)">Select</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div v-for="(data, index) in filteredCharacter" :key="index" class="card" style="background-color: #2d2d2d !important;" :class="index > 0 ? 'mt-2' : ''">
                                        <div class="card-body py-2">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <div class="d-flex align-items-center">
                                                    <img :src="data.PathAvatar" alt="Sovia" class="img-fluid" style="width:32px; height:32px;">
                                                    <h6 class="ml-3">{{ data.Name }}</h6>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                    <div class="dropdown ml-2">
                                                        <button
                                                            class="btn d-flex align-items-center"
                                                            type="button"
                                                            id="soundEffect"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                            style="border: 1px solid #585859; border-radius: 20px; height: 24px"
                                                        >
                                                            <div class="d-flex align-items-center justify-content-between pl-2">
                                                                <span class="text-white mr-3">{{ data.Emotion }}</span>
                                                                <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M1 1L4 4L7 1" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>
                                                            </div>
                                                        </button>
                                                        <div class="dropdown-menu" aria-labelledby="soundEffect">
                                                            <button
                                                                v-for="efek in effect"
                                                                class="btn btn-language-options"
                                                                :key="efek"
                                                            >
                                                                <div class="d-flex align-items-center" @click="setEmotionMasterLang(index, efek)">
                                                                    <span class="ml-2 text-white"> {{ efek }}</span>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="mx-2">
                                                        <img :src="require(`@/assets/images/icons/${data.icon_audio}`)" alt="" class="img-fluid cursor-pointer" @click="playPreviewAudio(index, data.Emotion, data.Name)">
                                                    </div>
                                                    <button class="btn btn-purple d-flex align-items-center" style="height:24px;" @click="selectCharacter(data.Name, data.Emotion, data.PathAvatar)">
                                                        <small class="px-2">Select</small>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                                <div v-else class="d-flex align-items-center justify-content-center h-100">
                                    <div class="spinner-border spinner-border-sm" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-modal>

            <!-- EXPANDED CARD MODAL -->
            <b-modal id="expanded-card-modal" size="xl" centered hide-footer busy header-class="p-0" body-class="p-0">
                <template #modal-header>
                    <div class="list-option list-option--modal w-100">
                        <div class="d-flex align-items-center justify-content-between pt-2 px-3">
                            <div class="d-flex align-items-center">
                                <div v-if="newListAudio[index_card_expand].from_ai == true" class="dropdown">
                                    <button
                                        class="btn btn-language btn-language--modal dropdown-toggle"
                                        type="button"
                                        id="characterAvatar"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <img v-if="newListAudio[index_card_expand].avatar == null" src="@/assets/images/icons/sovia_editor.svg" style="width:25px; height:25px" class="img-fluid"/>
                                        <img v-else :src="newListAudio[index_card_expand].avatar" style="width:25px; height:25px" class="img-fluid"/>
                                        <span class="ml-2"> {{ newListAudio[index_card_expand].sound_type }}</span>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="characterAvatar">
                                        <button
                                            v-for="avatar in newListAudio[index_card_expand].characters"
                                            class="btn btn-language-options"
                                            :key="avatar.Name"
                                            @click="selectSoundType(index_card_expand, avatar.Name, avatar.PathAvatar)"
                                        >
                                            <div class="d-flex align-items-center">
                                                <img :src="avatar.PathAvatar" style="width:22px; height:22px"/>
                                                <span class="ml-2 text-white"> {{ avatar.Name }}</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                <div v-else>
                                    <button
                                        class="btn btn-language btn-language--modal"
                                        type="button"
                                        id="characterAvatar"
                                        @click="openMasterLang(index_card_expand, 'input')"
                                    >
                                        <img v-if="newListAudio[index_card_expand].avatar == null" src="@/assets/images/icons/sovia_editor.svg" style="width:25px; height:25px" class="img-fluid"/>
                                        <img v-else :src="newListAudio[index_card_expand].avatar" style="width:25px; height:25px" class="img-fluid"/>
                                        <span class="ml-2"> {{ newListAudio[index_card_expand].sound_type }}</span>
                                    </button>
                                </div>

                                <!-- <div class="dropdown ml-2">
                                    <button
                                        class="btn btn-language btn-language--modal dropdown-toggle"
                                        type="button"
                                        id="soundEffect"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <span class="ml-2"> {{ newListAudio[index_card_expand].emotion ? newListAudio[index_card_expand].emotion : 'Normal' }}</span>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="soundEffect">
                                        <button
                                            v-for="data in effect"
                                            class="btn btn-language-options"
                                            :key="data"
                                            @click="selectEmotion(index_card_expand, data)"
                                        >
                                            <div class="d-flex align-items-center">
                                                <span class="ml-2 text-white"> {{ data }}</span>
                                            </div>
                                        </button>
                                    </div>
                                </div> -->

                                <!-- <div v-if="newListAudio[index_card_expand].emotion != 'Excited'" class="dropdown ml-2">
                                    <button
                                        class="btn btn-language btn-language--modal dropdown-toggle"
                                        type="button"
                                        id="pitch"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <span class="ml-2">Pitch</span>
                                    </button>
                                    <div class="dropdown-menu" style="width:155px;" aria-labelledby="pitch">
                                        <div class="container-fluid w-100">
                                            <b-form-input id="range-1" v-model="newListAudio[index_card_expand].pitch" type="range" min="-10" max="10" step="5" class="ml-1"></b-form-input>
                                            <div class="numbers">
                                                <span v-for="num in numbers" :key="num" @click="selectPitch(index_card_expand, num)">{{ num }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <div class="d-flex">
                                <button
                                    type="button"
                                    title="Compress"
                                    class="btn btn-expand btn-expand--modal d-flex justify-content-center align-items-center mr-3 rounded"
                                    @click="compressCardModal"
                                >
                                    <font-awesome-icon icon="fa-solid fa-compress" />
                                </button>
                                <div v-if="newListAudio[index_card_expand].loading_generate" class="d-flex align-items-center">
                                    <div class="spinner-border spinner-border-sm" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <button
                                    v-else-if="generateCheck(index_card_expand)"
                                    type="button"
                                    title="Generate"
                                    class="btn btn-generate-card btn-generate-card--modal d-flex justify-content-center align-items-center rounded"
                                    @click="regenerateAudio(index_card_expand, 'fullscreen')"
                                >
                                    <font-awesome-icon icon="fa-solid fa-play" />
                                </button>
                                <button
                                    v-else
                                    type="button"
                                    title="Play"
                                    class="btn btn-expand btn-expand--modal d-flex justify-content-center align-items-center rounded"
                                    @click="regenerateAudio(index_card_expand, 'fullscreen')"
                                >
                                    <font-awesome-icon :icon="newListAudio[index_card_expand].iconPlay == 'ic_play_shape_editor.svg' ? 'fa-solid fa-play' : 'fa-solid fa-pause'" />
                                </button>
                                <b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none text-white p-0" no-caret class="ml-4 text-white">
                                    <template #button-content>
                                        <img src="@/assets/images/icons/ic_options_editor.svg" alt="Options" class="img-fluid" style="width:4px">
                                    </template>
                                    <b-dropdown-item :class="listTextToAudio.length == 1 && listTextToAudio[0].text == '' ? 'cursor-disabled-item' : ''" @click="listTextToAudio.length == 1 && listTextToAudio[0].text == '' ? '' : deleteIndex(index_card_expand)">Delete</b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </div>
                        <hr class="mt-2 mb-0" style="background : #2D2D2D;">
                    </div>
                </template>
                <template #default>
                    <div class="inner-card" :class="play ? 'bg-played' : ''">
                        <!-- <textarea name="" id="" class="w-100 form-control text-white" :style="{ height: '20rem', fontSize: '1.15rem' }" placeholder="Input your text" v-model="newListAudio[index_card_expand].text"></textarea> -->
                        <TTAScriptEditor
                            fullscreen
                            v-model="newListAudio[index_card_expand].text"
                            placeholder="Input your text"
                            @get-editor="(editor) => fullscreenScriptEditor = editor"
                            @generate-preview-ssml="handleGeneratePreviewSSML"
                        />
                    </div>
                </template>
            </b-modal>

            <!-- MODAL IMPORT SCRIPT -->
            <b-modal id="import-script-modal" centered no-close-on-backdrop header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="lg">
                <div v-if="uploadedScript.length == 0" class="container-fluid my-2">
                    <div class="d-flex justify-content-between">
                        <h3 class="font-weight-normal">Import Script</h3>
                        <button class="btn btn-close d-flex justify-content-center align-items-center close-image" @click="closeModalImportScript"><b-icon icon="x"></b-icon></button>
                    </div>
                    <div class="upload-script mt-3" @dragover.prevent @dragenter.prevent @dragleave.prevent @drop.prevent>
                        <label @drop="dragScript($event)" class="btn btn-impor-custom w-100 d-flex flex-column py-5 align-items-center text-center mt-3">
                            <div class="h-100 w-100 d-flex align-items-center justify-content-center">
                                <div v-if="script_file == null && script_file_name == null">
                                    <img src="@/assets/images/logo/paper_impor.svg" alt="" class="img-fluid" width="30px;">
                                    <h5 class="font-weight-normal mt-2" style="color:#6D75F6;">Choose File or Drag Here</h5>
                                    <h6 class="font-weight-normal text-muted mt-1"><i>format file : .docs .txt .vtt</i></h6>
                                    <h6 class="font-weight-normal text-muted mt-1"><i>max. file size 1MB</i></h6>
                                </div>
                                <div v-else class="w-75">
                                    <p v-if="showProgress" class="mb-2 text-white">Uploading...</p>
                                    <h5 class="text-white">" {{ script_file_name }} "</h5>
                                    <div v-if="showProgress" class="w-100 mt-3">
                                        <div class="progress progress__upload" height="10px">
                                            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" :style="{ width: uploadProgress + '%' }"></div>
                                        </div>
                                    </div>

                                    <button v-if="showProgress" class="btn btn-grey mt-4" @click="cancelWhileUpload"><p class="mb-0 font-weight-bold mx-2">Cancel</p></button>
                                </div>
                            </div>
                            <input type="file" accept=".doc,.txt,.srt, .docs, .docx, .vtt" style="display: none" @change="uploadScript($event)"/>
                        </label>
                        <div v-if="!showProgress" class="w-100 d-flex justify-content-end mt-5">
                            <button class="btn btn-grey mr-2" @click="closeModalImportScript">Cancel</button>
                            <button v-if="script_file == null && script_file_name == null" class="btn btn-disable">Upload</button>
                            <button v-else class="btn btn-purple" @click="uploadScriptProgress">Upload</button>
                        </div>
                    </div>
                </div>
                <div v-else class="container-svm">
                    <div class="separator-bottom">
                        <div class="container-fluid mt-2 mb-4">
                            <div class="d-flex align-items-center justify-content-between w-100">
                                <div>
                                    <h4 class="font-weight-normal">Select an Import Preference</h4>
                                    <small class="text-nowrap">Importing <span class="font-weight-bold">"{{ script_file_name }}"</span></small>
                                </div>
                                <button class="btn btn-close d-flex justify-content-center align-items-center close-image" @click="closeModalImportScript"><b-icon icon="x"></b-icon></button>
                            </div>
                        </div>
                    </div>
                    <div class="container-fluid" style="height:28rem;">
                        <div class="row pl-3" style="height:24rem; border-bottom: 0.1px solid #2D2D2D;">
                            <div class="col-4 pt-3" style="border-right: 0.1px solid #2D2D2D;">
                                <div class="separator__bottom">
                                    <h6 class="mb-2 font-weight-normal">Read all script by</h6>
                                    <div class="dropdown mr-4">
                                        <button
                                            class="btn btn-lang d-flex align-items-center"
                                            type="button"
                                            id="chooseLang"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <div class="d-flex align-items-center justify-content-between px-3 w-100">
                                                <div class="d-flex">
                                                    <img :src="selectedMasterLang.flags" class="img-fluid" style="width:18px; height: 18px;"/>
                                                    <span class="ml-2 mr-1" style="font-size:15px !important"> {{ selectedMasterLang.country }}</span>
                                                </div>
                                                <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 1L4 4L7 1" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                        </button>
                                        <div class="dropdown-menu mt-2 w-100" aria-labelledby="chooseLang">
                                            <div v-if="!loadMasterLang" class="px-1">
                                                <div class="select-lang overflow-auto px-1 pt-0">
                                                    <button
                                                        v-for="(val, index) in sortedCountry"
                                                        class="btn btn-transparent px-3 d-block w-100"
                                                        :key="index"
                                                        @click="changeLang(val.DisplayName, val.Flags)"
                                                    >
                                                        <div class="d-flex align-items-center">
                                                            <img :src="baseurl_ml + val.Flags" class="img-fluid" style="width:18px; height: 18px;"/>
                                                            <span class="ml-2 text-white" style="font-size:15px !important"> {{ val.DisplayName }}</span>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                            <div v-else class="px-1">
                                                <div class="select-lang overflow-auto px-1 pt-0">
                                                    <button
                                                        v-for="index in 15"
                                                        class="btn btn-transparent px-3 d-block mr-0 w-100"
                                                        :key="index"
                                                    >
                                                        <div class="d-flex align-items-center w-100">
                                                            <b-skeleton type="avatar"></b-skeleton>
                                                            <b-skeleton animation="wave" width="100%" class="ml-2"></b-skeleton>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center mt-2 mr-4 pb-3">
                                        <button class="btn text-white w-100 mr-1" :style="selected_gender == 'Male' ? 'background-color:#6D75F6;': 'background-color:#2d2d2d;'" @click="selectGenderLang('Male')">Male</button>
                                        <button class="btn text-white w-100 ml-1" :style="selected_gender == 'Female' ? 'background-color:#6D75F6;': 'background-color:#2d2d2d;'" @click="selectGenderLang('Female')">Female</button>
                                    </div>
                                </div>

                                <div class="d-flex align-items-center mt-3 mr-4">
                                    <div v-for="(data, index) in filteredCharacter.slice(0, 2)" :key="index" class="card-avatar card" :class="{ 'mr-2' : index == 0, 'card-avatar__single' : filteredCharacter.length == 1 }">
                                        <img :src="data.PathThumbnailAvatar" alt="">
                                        <!-- <img src="@/assets/images/sovia_example.png" alt=""> -->
                                        <div class="card-body p-3">
                                            <div class="d-flex align-items-center">
                                                <button class="card-avatar__button-audio btn" @click="playPreviewAudio(index, data.Emotion, data.Name)">
                                                    <font-awesome-icon :icon="data.icon_audio == 'play_svm.svg' ? 'fa-solid fa-play' : 'fa-solid fa-pause'" style="width: 9px;"/>
                                                </button>
                                                <button class="card-avatar__button-select btn ml-2" :class="sound_type_upload == data.Name ? 'btn-selected' : 'btn-purple'" @click="selectCharacterUpload(data.Name, data.PathAvatar)">{{ sound_type_upload == data.Name ? 'Selected' : 'Select' }}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-8 pt-3">
                                <div class="ml-3 pr-3 container-list__script">
                                    <div v-for="(data, i) in uploadedScript" :key="i" class="card mb-3" style="background-color: #2d2d2d !important;">
                                        <div class="container-fluid py-2 px-4">
                                            <div class="container-list__script__char">
                                                <img :src="avatar_upload ? avatar_upload : require(`@/assets/images/icons/sovia_editor.svg`)" alt="" style="height:22.5px; width: 22.5px; border-radius:20px;" class="mx-2">
                                                <span class="text-white">{{ sound_type_upload ? sound_type_upload : 'Sovia' }}</span>
                                            </div>
                                        </div>
                                        <hr class="m-0" style="height: 0.1px; background-color:#585859;">
                                        <div class="container-fluid py-2 px-4">
                                            <span>{{ data }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="bottom-note">
                                    <div class="bottom-note__inner">
                                        <div class="pl-4 py-1">
                                            <small>*Script split by paragraph</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-100 d-flex justify-content-end align-items-center" style="height:4rem;">
                            <button class="btn btn-grey mr-2 font-weight-bold" @click="closeModalImportScript">Cancel</button>
                            <button class="btn btn-purple font-weight-bold" @click="importResultUpload">Import</button>
                        </div>
                    </div>
                </div>
            </b-modal>

            <div v-if="showContextMenu" :style="{ left: menuX + 'px', top: menuY + 'px' }" class="context-menu">
                <ul class="mb-0">
                    <li @click="deleteItemTimeline()">Delete</li>
                </ul>
            </div>

            <div class="position-absolute float-ai d-flex align-items-center" :class="!show_timeline ? 'float-ai-active-timeline' : ''">
                <div class="inner-float cursor-pointer" @click="showAi" :class=" { 'prompt__button--active' :  showInputAi} ">
                    <font-awesome-icon icon="fa-solid fa-wand-magic-sparkles" />
                </div>
            </div>
            <transition name="fade">
                <div v-show="showInputAi" class="position-absolute card-ai-generator" :class="!show_timeline ? 'float-ai-active-timeline' : ''" style="width: 48vw">
                    <div class="input-generator-container w-100">
                        <div class="w-input-text-group">
                            <textarea
                                ref="inputPrompt"
                                id="w-input-text"
                                class="w-100 form-control"
                                v-model="promptedText"
                                :placeholder="placeholderType"
                                spellcheck="false"
                                @input="autoResizeInputPrompt"
                            />
                            <!-- <div id="w-input-text"><p contenteditable class="mb-0 text-white text-left" @input="onInput"></p></div>
                            <div v-if="promptedText == null || promptedText == ''" class="w-placeholder">
                                {{ placeholderType }}
                            </div> -->
                        </div>
                        <div class="d-flex align-items-center list-dropdown-ai-generator mt-2">
                            <div class="dropdown">
                                <button
                                    class="btn btn-transparent p-0"
                                    type="button"
                                    id="scriptType"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <span class="mr-1"> {{ categoryUppercase ? categoryUppercase : 'Lesson' }}</span>
                                    <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1L4 4L7 1" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="scriptType">
                                    <button
                                        v-for="(val, index) in scriptTypeList"
                                        class="btn btn-transparent pl-1 pr-2"
                                        :key="index"
                                        @click="setScriptType(val.text)"
                                    >
                                        <div class="d-flex align-items-center">
                                            <span class="ml-2 text-white"> {{ val.text }}</span>
                                        </div>
                                    </button>
                                </div>
                            </div>

                            <button
                                class="btn btn-transparent p-0 d-flex align-items-center ml-4"
                                type="button"
                                id="chooseLang"
                                @click="openMasterLang(1, 'ai')"
                            >
                                <img v-if="!ai_masterlang.flags" :src="require(`@/assets/images/icons/${choosenLanguage.flag}`)" />
                                <img v-else :src="ai_masterlang.flags" class="img-fluid" style="width:16px; height: 16px;" />
                                <span class="ml-2 mr-1"> {{ langCode }}</span>
                            </button>

                            <button v-if="loadingGenerateScript" class="btn btn-generate btn-active ml-auto">
                                <span class="mx-4 font-weight-bold">
                                    <div class="spinner-border spinner-border-sm" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </span>
                            </button>
                            <button v-else-if="promptedText != null && promptedText != ''" class="btn btn-generate btn-active ml-auto" @click="generateTextToAudio">Generate</button>
                            <button v-else class="btn btn-generate ml-auto">Generate</button>
                        </div>
                    </div>
                </div>
            </transition>

            <b-modal id="delete-audio-tta" centered no-close-on-backdrop header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm">
                <div class="float-right">
                    <button class="btn d-flex justify-content-center align-items-center pr-0" @click="cancelDeleteAudio()"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
                </div>
                <div class="mt-5 text-center mb-3">
                    <img src="@/assets/images/icons/modal_warning.svg" alt="sovia-delete">
                    <div class="mt-3">
                        <h5 class="font-weight-bold mb-2" v-html="$t('confirm-delete-content--audio')"></h5>
                    </div>
                    <div class="d-flex justify-content-center mt-4">
                        <button class="btn btn-secondary text-white mr-2" @click="cancelDeleteAudio()"><p class="mb-0 font-weight-bold">{{ $t('cancel') }}</p></button>
                        <button class="btn btn-primary btn-purple-nb" @click="deleteAudioByIndex()"><p class="mx-2 mb-0 font-weight-bold">{{ $t('yes') }}</p></button>
                    </div>
                </div>
            </b-modal>
            <QuotaHabis :click-upgrade="handleClickUpgrade" @handle-upgrade-tta="handleClickUpgradeTta"/>
            <ScriptKosong />
            <SentimentAnalysisWarning @hide="handleHideSentimentAnalysisWarning" />
            <IntroduceFeature
                id="introduce-ai-speech-editor"
                title="AI Speech Editor"
                :slides="introduceAISpeechEditorSlides"
                with-new-icon
            />
            <ServerError :status_api="status_api_tts"/>
        </section>
    </div>
</template>

<script>
    import $ from 'jquery';
    import interact from 'interactjs'
    import Swal from "sweetalert2";
    import axios from "axios"

    import { getGeneratedTextToAudio, getGeneratedTextToAudioWithAgreements, getGeneratedTextToAudioSSML, getGeneratedTextToAudioFinal, getJobStatus, importScriptAudio} from '@/services/generative-ai-text-to-audio/generative-ai-text-to-audio.service';
    import { base_url_machine_learning } from "../../../../config/base_url";
    import { getMasterMusics, getMasterMusicsByClass, getSearchImage } from "@/services/course/course.service";
    import { getDataContent, postImageLibrary, getImageLibrary, deleteImageLibraryById } from "@/services/contents/contents.service";
    import { subscriptionsStatus } from '@/services/subscriptions/subscriptions.service'
    import { checkStatusApi } from '@/services/third_party/third_party.service'
    import { getMasterLanguages } from '@/services/master-languages/master_languages.service'
    import { AlertUtils } from "@/mixins/AlertUtils";
    import { getImageAiGenerator } from '@/services/ttv/ttv.service.js';

    // MODAL / KOMPONEN
    import QuotaHabis from '@/components/modal/QuotaHabis'
    import ScriptKosong from '@/components/modal/ScriptKosong'
    import SentimentAnalysisWarning from '@/components/modal/SentimentAnalysisWarning'
    import IntroduceFeature from '@/components/modal/IntroduceFeature';
    import ServerError from '@/components/modal/ServerError'
    import NavbarTta from '@/components/navigation/NavbarTta'
    import AiGeneratorImage from '@/components/generative-ai-text-to-audio/ai-generator-image'

    import TTAScriptEditor from '@/components/tta-script-editor/TTAScriptEditor';

    import VueSlider from 'vue-slider-component'
    import 'vue-slider-component/theme/antd.css'
    export default {
        mixins: [AlertUtils],

        components : {
            QuotaHabis,
            ScriptKosong,
            SentimentAnalysisWarning,
            IntroduceFeature,
            ServerError,
            NavbarTta,
            TTAScriptEditor,
            AiGeneratorImage,
            VueSlider
        },

        beforeRouteLeave(to, from, next) {
            const mediaElements = this.listTextToAudio.length > 0 ? this.listTextToAudio : null

            // Turn off audio
            if(mediaElements != null){
                mediaElements.forEach(element => {
                    if(element.audio == null || !(element.audio instanceof HTMLAudioElement)){
                        element.audio = new Audio(element.path)
                    }
                    element.audio.pause();
                });
            }

            // next navigate/router
            next();
        },

        data() {
            return {
                loadingUnsplash: false,
                perPageUnsplash: 30,
                pageUnsplash: 1,
                promptedText: '',
                isEditing: false,
                isGenerated: false,
                showInputAi: true,
                generatedTtaFinal : true,
                icFloatAi : 'ic_float_ai.svg',
                choosenGender: 'F',
                play: false,
                playTimeline: false,
                colorx: '#6D75F6',
                pitchVal : 0,
                img_upload : null,
                music_upload : null,
                deleted_audio_index : null,
                showAlertMusic: false,
                imagenameBefore: "",
                imageFileBefore: "",
                musicnameBefore: "",
                musicFileBefore: "",
                iconFill: 'ic_play_circle_editor.svg',
                filterMusic: [],
                statusCheck: false,
                inputMusic: null,
                audioForUpload: {
                    audio: null,
                    icon: 'ic_play_circle_editor.svg',
                    progress: 0,
                    timeProgress: '00:00'
                },
                listCheckBoxMusic: [
                    "All",
                    "Acoustic",
                    "Beat",
                    "Blues",
                    "Classic",
                    "Country",
                    "Fun",
                    "Funky",
                    "Hip Hop",
                    "Indie",
                    "Jazz",
                    "Metal",
                    "Middle East",
                    "Reggae",
                    "Rock",
                    "Soft",
                    "Techno"
                ],
                listTextToAudio : [
                    // {
                    //     generate_first: false,
                    //     text : '',
                    //     length : 0,
                    //     course_id : null,
                    //     path : null,
                    //     audio: null,
                    //     tts_id: null,
                    //     sound_type: "Soleh",
                    //     emotion: "Normal",
                    //     pitch: 0,
                    //     speed: null,
                    //     iconPlay: 'ic_play_shape_editor.svg'
                    // }
                ],
                audio_play: false,
                course_id: "",
                audioArray: [],
                languages: [
                    {
                        language: 'id',
                        flag: 'ic_flag_indonesia.svg'
                    },
                ],
                characters: [
                    {
                        pict : 'sovia_editor.svg',
                        name : 'Sovia'
                    },
                    {
                        pict : 'soleh_editor.svg',
                        name : 'Soleh'
                    }
                ],

                choosenLanguage: {
                    language: 'ID',
                    flag: 'ic_flag_indonesia.svg'
                },

                choosenCharacters: {
                    name: 'Sovia',
                    pict: 'sovia_editor.svg'
                },

                effect: ['Normal', 'Excited'],
                choosenEffect: 'Normal',
                lengthAudio : 1,

                generatedScript:'',
                generatedAudio:'',
                arrTta: [
                    {
                        duration: 120,
                        src: require('@/assets/music/techno/The Tech full.mp3')
                    },
                    {
                        duration: 25,
                        src: require('@/assets/music/soft/Soft Piano.mp3')
                    },
                ],
                arrRuler: [],
                ttaDurations: 0,
                musicDurations: 140,
                numbers: [-2, -1, 0, 1, 2],
                width: 40,
                layers: {
                    musicLayer: {},
                    imageLayer: {}
                },
                rulerReady: false,
                audio: null,
                duration: 0,
                currentTime: 0,
                timeLabel: '00:00',
                //data music
                musicList : [],
                isSelectedMusic: null,
                musicArray: [],
                volume_music : 0.35,
                volume_video : 1,
                pageMusic : 1,
                totalPage : null,
                loadMusic : false,
                loadScrollMusic : false,

                //data image
                searchImage: null,
                isLoadingSearch: false,
                list_images: [],
                selectedImage: [],
                imageArray:[],

                //final data by job id
                jobsId : null,
                finalPath : null,
                intervalPath : null,

                //video final path play?
                final_video_play : false,

                // show menu while right click
                showContextMenu: false,
                menuX: 0,
                menuY: 0,

                // Regenerate data
                loadingRegenerate : false,
                loadingGenerateScript : false,

                // Final generate
                loadingFinalGenerate: false,

                //Video
                currentTimeVideo : null,
                generatedVideoDuration : null,

                // Placeholder
                placeholderType : null,

                itemTimelineDelete: null,
                itemLayerDelete: null,

                //Drag needle / needle position / timeline
                sliderPosition: 0,
                lastSliderPosition: 0,
                startX: 0,
                isDragging: false,
                show_timeline: true,

                //image user library
                imageLibrary : [],
                showAlertImgUpload : false,
                loadingImageUpload : false,
                loadingGetImage : false,
                responseImg : null,
                selectedImgLibrary : null,

                // Props navbar
                status_subscriptions: {},
                loading_subscriptions: false,

                //Status Api
                status_api : [],
                status_api_tts : false,

                // Script Type
                scriptType : null,
                scriptTypeList : [
                    {
                        val : 'lessons',
                        text : 'LESSON'
                    },
                    {
                        val : 'presentation',
                        text : 'PRESENTATION'
                    },
                    {
                        val : 'promotion',
                        text : 'PROMOTION'
                    },
                    {
                        val : 'storytelling',
                        text : 'STORYTELLING'
                    },
                ],

                //master lang
                loadMasterLang : false,
                loadMasterLangFilter : false,
                masterLanguages : [],
                masterLanguagesFilter : {
                    id: null,
                    Detail: []
                },
                set_lang: 'Indonesia',
                selected_gender : null,
                index_card : null,
                index_card_expand : null,
                from_ai : false,
                audio_card : {},
                baseurl_ml : base_url_machine_learning,
                selectedMasterLang : {
                    country : 'Indonesia',
                    flags : base_url_machine_learning + 'static/template/mp3/asset_sample_languages/Indonesia/flags.png'
                },

                ai_masterlang : {
                    avatar : null,
                    emotion : null,
                    lang : null,
                    flags : null,
                    language_code : null,
                    sound_type : null,
                },

                defaultLangId: null,

                // RATIO
                set_ratio : '',

                //Upload Script
                script_file : null,
                script_file_name : null,
                cancelTokenSource : null,
                timerUpload : null,
                sound_type_upload : null,
                avatar_upload : null,
                showProgress: false,
                uploadProgress : 0,
                uploadedScript : [],

                activeInputEditor: null,
                defaultScriptEditors: [],
                fullscreenScriptEditor: null,

                changeSSMLActiveSpeakerEvent: new Event('change-ssml-active-speaker'),

                introduceAISpeechEditorSlides: [
                    {
                        name: 'step-1',
                        animationPath: '/assets/animations/introduce-ai-speech-editor/step-1.json',
                        rawText: 'Block word or sentence<br />from the script.',
                    },
                    {
                        name: 'step-2',
                        animationPath: '/assets/animations/introduce-ai-speech-editor/step-2.json',
                        rawText: 'Adjust speed, tone or add pauses<br />betwen words.',
                    },
                    {
                        name: 'step-3',
                        animationPath: '/assets/animations/introduce-ai-speech-editor/step-3.json',
                        rawText: 'Play to hear<br />the new speech.',
                    },
                ],

                willRedirectForUpgrade: false,
                generateImage: false,
                resolutions: ['256px', '512px', '1080px'],
                ratios: [
                    {
                        id: '1:1',
                        css: '1 / 1',
                        name: '1:1',
                    },
                    {
                        id: '16:9',
                        css: '16 / 9',
                        name: '16:9',
                    },
                    {
                        id: '4:5',
                        css: '4 / 5',
                        name: '4:5',
                    },
                    {
                        id: '3:2',
                        css: '3 / 2',
                        name: '3:2',
                    },
                ],
                outputs: [1, 2, 3],
                outputImageAi: [],
                formGaim: {
                    text: '',
                    engine: '1',
                    // resolution: '512px',
                    size: '512x512',
                    ratio: '1:1',
                    output: 2,
                }
            }
        },

        watch: {
            searchImage(value){
                console.log(value)
            },
            filterMusic(value){
                this.loadMusic =  true;
                this.filterMusic = value;
                this.pageMusic = 1

                if(this.musicList.length > 0){
                    this.musicList.forEach((music) => {
                        music.audio.pause();
                        clearInterval(music.intervalId);
                        music.audio.currentTime = 0;
                        music.currentTime = music.audio.currentTime;
                        music.timeLabel = music.durationOrigin
                        music.iconFill = 'ic_play_circle_editor.svg';
                    });

                }

                if(this.filterMusic.length > 0){
                    if(this.filterMusic[0] != 'All') {
                        this.getListMusicByClass (this.filterMusic[0])
                    } else {
                        this.musicList = []
                        this.getListMusic()
                    }
                } else {
                    this.musicList = []
                    this.getListMusic()
                }
                // this.statusCheck = true;
                // if(value != null){

                //     if(value.length < 1){
                //         this.statusCheck = false;
                //     }

                //     if(value.includes('All')){
                //         this.filterMusic = this.listCheckBoxMusic;
                //     }
                //     else if(!value.includes('All')){
                //         this.filterMusic = null;
                //     }
                // }

            }
        },

        computed: {
            newListAudio(){
                return this.listTextToAudio
            },
            newImageArray(){
                return this.imageArray
            },
            newMusicArray(){
                return this.musicArray
            },
            newFinalPathTTA(){
                return this.finalPath
            },
            newImageLibrary(){
                return this.imageLibrary
            },
            searchMusic(){
                if (this.inputMusic) {
                    return this.musicList.filter((item) => {
                    return this.inputMusic
                        .toLowerCase()
                        .split(" ")
                        .every(
                        (v) =>
                            item.name.toLowerCase().includes(v)
                        );
                    });
                } else {
                    return this.musicList;
                }
            },

            filteredCharacter(){
                if(this.selected_gender) {
                    return this.masterLanguagesFilter.Detail.filter(char => char.Gender === this.selected_gender);
                } else {
                    return this.masterLanguagesFilter.Detail
                }
            },

            langCode(){
                if(this.ai_masterlang.language_code) {
                    return this.ai_masterlang.language_code.toUpperCase()
                } else {
                    return this.choosenLanguage.language
                }
            },

            sortedCountry(){
                if(this.masterLanguages.length > 0) {
                    let sortedMasterLang = this.masterLanguages
                    return sortedMasterLang.sort((a,b) => {
                        const nameA = a.DisplayName.toLowerCase()
                        const nameB = b.DisplayName.toLowerCase()
                        if (nameA < nameB) {
                            return -1
                        }
                        if (nameA > nameB) {
                            return 1
                        }
                        return 0
                    })
                } else {
                    return this.masterLanguages
                }
            },

            categoryUppercase(){
                if(this.scriptType){
                    return this.scriptType.toUpperCase()
                } else {
                    return "LESSON"
                }
            },

        },

        methods: {
            setSideBar() {
                this.$store.commit('IS_SIDEBAR_ACTIVE', false);
            },
            setGenderVoice(gender) {
                this.choosenGender = gender
            },
            setLanguage(language) {
                this.choosenLanguage = language
            },
            editPromptText() {
                this.isEditing = !this.isEditing;
            },
            onScrollLoadImg(){

                const positionScroll = this.$refs.imgLoad.scrollTop;
                const scrollHeight = positionScroll - this.$refs.imgLoad.scrollHeight
                const clientHeight = positionScroll - this.$refs.imgLoad.clientHeight

                const maxScroll = clientHeight - scrollHeight

                // console.log("Position : "+positionScroll);
                // console.log("Max : "+maxScroll);

                if(maxScroll == positionScroll || maxScroll - 1 == positionScroll){
                    // this.getImageSearch(this.searchImage);
                }
            },
            playAudioForUpload(data){
                if(!data.audio.paused){
                    data.icon = 'ic_pause_circle_editor.svg'
                    data.audio.pause();
                }else{
                    data.audio.play();
                    data.icon = 'ic_pause_circle_editor.svg'
                    data.audio.ontimeupdate = function() {
                        var duration = data.audio.duration;
                        let currentTime = data.audio.currentTime;

                        data.progress = Math.floor(currentTime*100 / duration);

                        let minutes = Math.floor(currentTime / 60)
                        let seconds = Math.floor(currentTime % 60)

                        data.timeProgress = minutes+":"+seconds;

                        data.audio.onended = function(){
                            data.progress = 0;
                            data.icon = 'ic_play_circle_editor.svg';
                        }
                    }

                }
            },
            generateTTA(){

                if(this.generatedTtaFinal == false){
                    // PLAY VIDEO
                    const video = this.$refs.videoPlayer;
                    if(!video.paused){
                        this.final_video_play = false;
                        video.pause();
                    } else {
                        this.final_video_play = true;
                        video.play();
                    }

                    this.stopAudio() //Stop audio card
                } else {
                    let audioArray = JSON.parse(sessionStorage.getItem('listTextToAudio'))
                    let musicArray = JSON.parse(sessionStorage.getItem('musicArray'))
                    let imageArray = JSON.parse(sessionStorage.getItem('imageArray'))

                    let tts = []
                    let musics = []
                    let images = []
                    let course_id = ""
                    this.loadingFinalGenerate = true;

                    if(audioArray != null){
                        audioArray.forEach( data => {
                            if(data.tts_id != null){
                                tts.push({
                                    "tts_id":data.tts_id,
                                    "text":data.text,
                                    "speaker":data.sound_type.toLowerCase()
                                })
                            }
                        })
                        course_id = audioArray[0].course_id
                    }

                    if(musicArray != null){
                        musicArray.forEach( data => {
                           musics.push({
                               "music_id": data.id,
                               "start": data.start,
                               "end": data.end
                           })
                        })
                    }

                    if(imageArray != null){
                        imageArray.forEach( data => {
                            images.push({
                                "url": data.image_url,
                                "start": data.start,
                                "end": data.end
                            })
                        })
                    }

                    if(tts.length != 0){
                        const form_data = {
                            "course_id": course_id,
                            "params": {
                                "text_to_speech": tts,
                                "music": musics,
                                "images": images,
                                "volume_music": this.volume_music != null || this.volume_music != undefined ? parseFloat(this.volume_music) : 0.35,
                                "volume_tts": this.volume_video != null || this.volume_video != undefined  ? parseFloat(this.volume_video) : 1,
                                "ratio" : this.set_ratio ? this.set_ratio : ''
                            }
                        }

                        getGeneratedTextToAudioFinal(form_data)
                        .then((res) => {
                            this.jobsId = res.data.job_id

                            if(imageArray != undefined){
                                if(imageArray.length != 0 && imageArray != null) {
                                    localStorage.setItem("image_thumbnail_tta", imageArray[0].image_url)
                                } else {
                                    localStorage.setItem("image_thumbnail_tta", res.data.all_slide.images.url)
                                }
                            } else {
                                localStorage.setItem("image_thumbnail_tta", res.data.all_slide.images.url)
                            }

                            if(this.jobsId != null){
                                this.intervalPath = setInterval(() => {
                                    getJobStatus(this.jobsId)
                                    .then((response) => {
                                        if(response.status == 200){
                                            if(response.data.status === "finish"){
                                                this.finalPath = base_url_machine_learning +  response.data.final_path
                                                this.stopAudio()
                                                localStorage.setItem("final_path_tta", this.finalPath)


                                                this.finalPath = localStorage.getItem("final_path_tta")

                                                var video = document.getElementById('video-tta');
                                                var source = document.getElementById('change-src');

                                                video.pause();
                                                source.setAttribute('src', this.finalPath + '?v=' + Math.random());

                                                video.load();
                                                video.play();

                                                this.$store.state.generatedAudioContent = true;
                                                if(this.finalPath != null){
                                                    clearInterval(this.intervalPath)
                                                }

                                                this.loadingFinalGenerate = false;
                                                this.generatedTtaFinal = false;

                                                localStorage.setItem("generatedTtaFinal", false)
                                                localStorage.setItem("volume_music_tta", this.volume_music)
                                                localStorage.setItem("volume_video_tta", this.volume_video)
                                                localStorage.setItem("jobs_id_tta", this.jobsId)
                                                localStorage.setItem("ratio_tta", this.set_ratio)

                                                this.getHistoryData()
                                                this.getStatusSubscriptions()
                                            }
                                        } else {
                                            this.loadingFinalGenerate = false;
                                        }
                                    })
                                }, 5000)
                            }
                        }).catch((err) => {
                            this.loadingFinalGenerate = true;
                            console.log("Error ini",err);
                        });
                    } else {
                        this.loadingFinalGenerate = true;
                        this.alertFail("No audio to generate")
                    }
                }
            },
            getImageSearch(search = 'wave audio') {
                var vm = this
                vm.isLoadingSearch = true
                vm.loadingUnsplash = true
                vm.list_images = [];
                getSearchImage(search, vm.perPageUnsplash, vm.pageUnsplash)
                .then((response) => {
                    if (response.status) {
                        vm.list_images = response.data.results;
                        // vm.list_images = vm.list_images.concat(response.data.results);
                        vm.pageUnsplash += 1;
                    } else {
                        vm.list_images = [];
                    }
                    vm.isLoadingSearch = false
                    vm.loadingUnsplash = false
                })
                .catch((err) => {
                    console.log("data err", err);
                    vm.isLoadingSearch = false
                });
            },
            selectImage(image_url, image_thumb){
                this.selectedImage = [{
                    image_url : image_url,
                    image_thumb : image_thumb
                }]
            },
            useImage(){
                this.$bvModal.hide('upload-image-editor')
                if(this.imageArray.length != 0){
                    let last_image_end = this.imageArray[this.imageArray.length - 1].end + 0.01
                    this.imageArray.push({
                        image_url: this.selectedImage[0].image_url,
                        image_thumb: this.selectedImage[0].image_thumb,
                        start:parseFloat(last_image_end.toFixed(2)),
                        end:parseFloat((last_image_end + 20).toFixed(2)),
                        length:20
                    })
                } else {
                    this.imageArray.push({
                    image_url: this.selectedImage[0].image_url,
                    image_thumb: this.selectedImage[0].image_thumb,
                    start:0,
                    end:20,
                    length:20
                    })
                }

                this.generatedTtaFinal = true;
                sessionStorage.setItem("imageArray", JSON.stringify(this.imageArray))
                localStorage.setItem("generatedTtaFinal", true)
                this.stopAudio()
                this.getHistoryData()
                this.getRuler()
            },
            selectMusic(index){
                this.isSelectedMusic = index
            },
            useMusic(){
                this.musicList.forEach((music) => {
                    music.audio.pause();
                    clearInterval(music.intervalId);
                    music.audio.currentTime = 0;
                    music.currentTime = music.audio.currentTime;
                    music.iconFill = 'ic_play_circle_editor.svg';
                });

                this.$bvModal.hide('upload-music-editor')
                if(this.isSelectedMusic != null){
                    if(this.musicArray.length != 0 ){
                        let last_music_end = this.musicArray[this.musicArray.length - 1].end + 0.01
                        this.musicArray.push({
                                id: this.musicList[this.isSelectedMusic].id,
                                name : this.musicList[this.isSelectedMusic].name,
                                fileAudio: this.musicList[this.isSelectedMusic].fileAudio,
                                audio : this.musicList[this.isSelectedMusic].audio,
                                duration: this.musicList[this.isSelectedMusic].duration,
                                durationOrigin: this.musicList[this.isSelectedMusic].durationOrigin,
                                end: parseFloat((last_music_end + this.musicList[this.isSelectedMusic].duration).toFixed(2)),
                                start: parseFloat(last_music_end.toFixed(2)),
                                currentTime: this.musicList[this.isSelectedMusic].currentTime,
                                timeLabel: this.musicList[this.isSelectedMusic].timeLabel,
                                iconFill: this.musicList[this.isSelectedMusic].iconFill,
                                loading: this.musicList[this.isSelectedMusic].loading
                        })
                    } else {
                        this.musicArray.push(this.musicList[this.isSelectedMusic])
                    }
                }
                this.generatedTtaFinal = true;
                sessionStorage.setItem("musicArray", JSON.stringify(this.musicArray))
                localStorage.setItem("generatedTtaFinal", true)
                this.getRuler()
            },
            getListMusic() {
                this.loadScrollMusic = true;

                this.musicList.forEach((music) => {
                    music.audio.pause();
                    clearInterval(music.intervalId);
                    music.audio.currentTime = 0;
                    music.currentTime = music.audio.currentTime;
                    music.iconFill = 'ic_play_circle_editor.svg';
                });

                let params = {
                    page : this.pageMusic
                }
                getMasterMusics(params)
                .then(data => {
                    if(data.status == true) {
                        this.pageMusic = this.pageMusic + 1 ;
                        this.totalPage = data.totalPage;

                        data.data.forEach(data => {
                            this.musicList.push({
                                id: data._id.$oid,
                                name : data.name,
                                fileAudio: base_url_machine_learning + data.path.replace('src/', ''),
                                audio : new Audio(base_url_machine_learning + data.path.replace('src/', '')),
                                duration: parseFloat(data.duration.toFixed(2)),
                                durationOrigin: parseFloat(data.duration.toFixed(2)),
                                end: parseFloat(data.duration.toFixed(2)),
                                start: 0,
                                currentTime: 0,
                                timeLabel: parseFloat(data.duration.toFixed(2)),
                                iconFill: 'ic_play_circle_editor.svg',
                                loading : false,
                            })
                        })
                        this.loadMusic =  false;
                        this.loadScrollMusic = false;
                    } else {
                        this.loadMusic =  false;
                        this.loadScrollMusic = false;
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            },
            getListMusicByClass(val){
                let params = {
                    page : 1,
                    perPage : 20,
                    class : val
                }

                this.musicList.forEach((music) => {
                    music.audio.pause();
                    clearInterval(music.intervalId);
                    music.audio.currentTime = 0;
                    music.currentTime = music.audio.currentTime;
                    music.iconFill = 'ic_play_circle_editor.svg';
                });

                this.musicList = []

                this.loadMusic = true;

                getMasterMusicsByClass(params)
                .then(data => {
                    if(data.status == true) {

                        this.musicList = []
                        data.data.forEach(data => {
                            this.musicList.push({
                                id: data._id.$oid,
                                name : data.name,
                                fileAudio: base_url_machine_learning + data.path.replace('src/', ''),
                                audio : new Audio(base_url_machine_learning + data.path.replace('src/', '')),
                                duration: parseFloat(data.duration.toFixed(2)),
                                durationOrigin: parseFloat(data.duration.toFixed(2)),
                                end: parseFloat(data.duration.toFixed(2)),
                                start: 0,
                                currentTime: 0,
                                timeLabel: parseFloat(data.duration.toFixed(2)),
                                iconFill: 'ic_play_circle_editor.svg',
                                loading: false,
                            })
                        })
                        this.loadMusic =  false;
                    } else {
                        this.loadMusic =  false;
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            },
            getHistoryData() {

                 if(sessionStorage.getItem('listTextToAudio')){
                    this.listTextToAudio = JSON.parse(sessionStorage.getItem('listTextToAudio'))

                    if(this.listTextToAudio[0].course_id != null && this.course_id != ''){
                        localStorage.setItem('course_id', this.listTextToAudio[0].course_id)
                    }

                    if(this.listTextToAudio.length > 0) {
                        this.listTextToAudio.forEach(music => {
                            if(music.audio == null || !(music.audio instanceof HTMLAudioElement)){
                                music.audio = music.path ? new Audio(music.path) : null
                            }
                        })
                    }
                 }

                 if(sessionStorage.getItem('imageArray')){
                     this.imageArray = JSON.parse(sessionStorage.getItem('imageArray'))
                 }

                 if(sessionStorage.getItem('musicArray')){
                     this.musicArray = JSON.parse(sessionStorage.getItem('musicArray'))
                 }

                 if(localStorage.getItem('course_id')){
                     this.course_id = localStorage.getItem('course_id')
                 }

                 if(localStorage.getItem('final_path_tta')){
                     this.finalPath = localStorage.getItem('final_path_tta')
                 }

                 if(localStorage.getItem('content_name_tta')){
                     this.content_name = localStorage.getItem('content_name_tta')
                 }

                 if(localStorage.getItem('volume_music_tta')) {
                    this.volume_music = localStorage.getItem('volume_music_tta')
                 }

                 if(localStorage.getItem('volume_video_tta')) {
                    this.volume_video = localStorage.getItem('volume_video_tta')
                 }

                 if(localStorage.getItem("ratio_tta")) {
                    this.set_ratio = localStorage.getItem("ratio_tta")
                 }

                this.stopAudio()
            },
            stopAudio(){

                const mediaElements = this.listTextToAudio.length > 0 ? this.listTextToAudio : null

                // Turn off audio
                if(mediaElements != null){
                    this.listTextToAudio.forEach(element => {
                        if(element.audio == null || !(element.audio instanceof HTMLAudioElement)){
                            element.audio = new Audio(element.path)
                        }
                        element.audio.pause();
                        clearInterval(element.intervalId);
                        element.iconPlay = 'ic_play_shape_editor.svg'
                        element.audio.currentTime = 0
                    });
                }
            },
            stopVideo() {
                const mediaElements = this.$refs.videoPlayer;

                // Turn off video
                // console.log(mediaElements)
                mediaElements.pause();
            },
            selectSoundType(index, name, pict) {
                this.choosenCharacters.pict = pict
                this.listTextToAudio[index].sound_type = name
                this.listTextToAudio[index].avatar = pict
                this.listTextToAudio[index].lang = this.defaultLangId;
                this.changeSSMLActiveSpeaker({ name: name.toLowerCase() });
            },
            selectEmotion(index, emotion){
                this.listTextToAudio[index].emotion = emotion
            },
            selectPitch(index, pitch){
                let convert_pitch = '0'

                if(pitch == '-2'){
                    convert_pitch = '-10'
                } else if (pitch == '-1'){
                    convert_pitch = '-5'
                } else if (pitch == '0'){
                    convert_pitch = '0'
                } else if (pitch == '1') {
                    convert_pitch = '5'
                } else if (pitch == '2') {
                    convert_pitch = '10'
                }

                this.listTextToAudio[index].pitch = convert_pitch
            },
            regenerateAudio(index, editorType){
                if(this.listTextToAudio[index].text == ''){
                    // Show modal to text input script
                    this.$bvModal.show('script-kosong')
                } else {
                    let audioLocalStorage = JSON.parse(sessionStorage.getItem('listTextToAudio'))

                    if(audioLocalStorage[0].course_id == "" || audioLocalStorage[0].course_id == null){
                        // DEAR AKU ALIAS TEGAR KALO ADA ERROR FIXING DISINI YA
                            this.loadingRegenerate = true;
                            this.listTextToAudio[index].loading_generate = true;
                            let regenerate = "True"
                            console.log("course_id", this.course_id)
                            const form_data = {
                                "course_id" : this.course_id,
                                "text": this.listTextToAudio[index].text,
                                "params":{
                                    "speaker": this.listTextToAudio[index].sound_type.toLowerCase(),
                                    "pitch": this.listTextToAudio[index].pitch,
                                    "speed": 1,
                                    "emotion": this.listTextToAudio[index].emotion.toLowerCase(),
                                    "regenerate": regenerate,
                                    "lang": this.listTextToAudio[index].lang ? this.listTextToAudio[index].lang : '',
                                },
                                "context": this.scriptType === null ? "lessons" : this.scriptType
                            }

                            sessionStorage.setItem("listTextToAudio", JSON.stringify(this.listTextToAudio))

                            getGeneratedTextToAudioSSML(form_data)
                                .then((res) => {
                                    if(res.status == 403){
                                        this.$bvModal.show('audio-quota')
                                        this.loadingRegenerate = false
                                        this.listTextToAudio[index].loading_generate = false;
                                        sessionStorage.setItem("listTextToAudio", JSON.stringify(this.listTextToAudio))
                                        this.$vs.loading.close()
                                    } else if (res.status == 402){
                                        this.$bvModal.show('server-error-modal')
                                        this.loadingRegenerate = false
                                        this.listTextToAudio[index].loading_generate = false;
                                    } else if (res.status == 406) {
                                        sessionStorage.setItem('sentimentFormData', JSON.stringify(form_data));
                                        sessionStorage.setItem('sentimentInput', this.listTextToAudio[index].text);
                                        sessionStorage.setItem('sentimentSource', JSON.stringify({ inputType: `script-${editorType}`, index, populateType: 1 }));
                                        sessionStorage.setItem('isAgreedSentiment', false);

                                        this.activeInputEditor = editorType === 'default' ? this.defaultScriptEditors[index].commands : this.fullscreenScriptEditor.commands;
                                        this.$bvModal.show('sentiment-analysis-warning');

                                        this.loadingRegenerate = false;
                                        this.listTextToAudio[index].loading_generate = false;
                                    } else if(res.status == 200) {
                                        this.populateScriptGeneratedData(res.data, index, 1);
                                        this.regenerateAudio(index, editorType)
                                    }
                                }).catch((err) => {
                                    this.loadingRegenerate = false;
                                    this.$bvModal.show('server-error-modal')
                                    console.log("Error ini",err);
                                });
                                this.getHistoryData()
                    } else {
                        if(
                            this.listTextToAudio[index].text != audioLocalStorage[index].text
                            ||
                            this.listTextToAudio[index].emotion != audioLocalStorage[index].emotion
                            ||
                            this.listTextToAudio[index].pitch != audioLocalStorage[index].pitch
                            ||
                            this.listTextToAudio[index].sound_type != audioLocalStorage[index].sound_type
                            ){
                            this.loadingRegenerate = true;
                            this.listTextToAudio[index].loading_generate = true;
                            let regenerate = "True"
                            this.stopAudio()
                            console.log("masuk sini , edit regenerate")

                            const form_data = {
                                "course_id" : this.listTextToAudio[index].course_id,
                                "text": this.listTextToAudio[index].text,
                                "params":{
                                    "speaker": this.listTextToAudio[index].sound_type.toLowerCase(),
                                    "pitch": this.listTextToAudio[index].pitch,
                                    "speed": 1,
                                    "emotion": this.listTextToAudio[index].emotion.toLowerCase(),
                                    "regenerate": regenerate,
                                    "lang": this.listTextToAudio[index].lang ? this.listTextToAudio[index].lang : '',
                                },
                                "context": this.scriptType === null ? "lessons" : this.scriptType
                            }

                            sessionStorage.setItem("listTextToAudio", JSON.stringify(this.listTextToAudio))

                            getGeneratedTextToAudioSSML(form_data)
                                .then((res) => {
                                    console.log("response", res)
                                    if(res.status == 403){
                                        this.$bvModal.show('audio-quota')
                                        // this.loadingGenerateScript = false
                                        this.loadingRegenerate = false;
                                        this.listTextToAudio[index].loading_generate = false;
                                        sessionStorage.setItem("listTextToAudio", JSON.stringify(this.listTextToAudio))
                                        this.$vs.loading.close()
                                    } else if (res.status == 402){
                                        this.$bvModal.show('server-error-modal')
                                        this.loadingRegenerate = false
                                        this.listTextToAudio[index].loading_generate = false;
                                    } else if (res.status == 406) {
                                        sessionStorage.setItem('sentimentFormData', JSON.stringify(form_data));
                                        sessionStorage.setItem('sentimentInput', this.listTextToAudio[index].text);
                                        sessionStorage.setItem('sentimentSource', JSON.stringify({ inputType: `script-${editorType}`, index, populateType: 2 }));
                                        sessionStorage.setItem('isAgreedSentiment', false);

                                        this.activeInputEditor = editorType === 'default' ? this.defaultScriptEditors[index].commands : this.fullscreenScriptEditor.commands;
                                        this.$bvModal.show('sentiment-analysis-warning');

                                        this.loadingRegenerate = false;
                                        this.listTextToAudio[index].loading_generate = false;
                                    } else if(res.status == 200) {
                                        this.populateScriptGeneratedData(res.data, index, 2);
                                        this.regenerateAudio(index, editorType)
                                    }
                                }).catch((err) => {
                                    this.loadingRegenerate = false;
                                    this.listTextToAudio[index].loading_generate = false;
                                    this.$bvModal.show('server-error-modal')
                                    console.log("Error ini",err);
                                });

                            } else if (this.listTextToAudio[index].path == null){
                                this.loadingRegenerate = true;
                                this.listTextToAudio[index].loading_generate = true;

                                let regenerate = "True"
                                this.stopAudio()

                                const form_data = {
                                    "course_id" : this.listTextToAudio[index].course_id,
                                    "text": this.listTextToAudio[index].text,
                                    "params":{
                                        "speaker": this.listTextToAudio[index].sound_type.toLowerCase(),
                                        "pitch": this.listTextToAudio[index].pitch,
                                        "speed": 1,
                                        "emotion": this.listTextToAudio[index].emotion.toLowerCase(),
                                        "regenerate": regenerate,
                                        "lang": this.listTextToAudio[index].lang ? this.listTextToAudio[index].lang : '',
                                    },
                                    "context": this.scriptType === null ? "lessons" : this.scriptType
                                }

                                sessionStorage.setItem("listTextToAudio", JSON.stringify(this.listTextToAudio))

                                getGeneratedTextToAudioSSML(form_data)
                                    .then((res) => {
                                        console.log("response", res)
                                        if(res.status == 403){
                                            this.$bvModal.show('audio-quota')
                                            // this.loadingGenerateScript = false
                                            this.loadingRegenerate = false;
                                            this.listTextToAudio[index].loading_generate = false;
                                            sessionStorage.setItem("listTextToAudio", JSON.stringify(this.listTextToAudio))
                                            this.$vs.loading.close()
                                        } else if (res.status == 402){
                                            this.$bvModal.show('server-error-modal')
                                            this.loadingRegenerate = false
                                            this.listTextToAudio[index].loading_generate = false;
                                        } else if (res.status == 406) {
                                            sessionStorage.setItem('sentimentFormData', JSON.stringify(form_data));
                                            sessionStorage.setItem('sentimentInput', this.listTextToAudio[index].text);
                                            sessionStorage.setItem('sentimentSource', JSON.stringify({ inputType: `script-${editorType}`, index, populateType: 3 }));
                                            sessionStorage.setItem('isAgreedSentiment', false);

                                            this.activeInputEditor = editorType === 'default' ? this.defaultScriptEditors[index].commands : this.fullscreenScriptEditor.commands;
                                            this.$bvModal.show('sentiment-analysis-warning');

                                            this.loadingRegenerate = false;
                                            this.listTextToAudio[index].loading_generate = false;
                                        } else if(res.status == 200) {
                                            this.populateScriptGeneratedData(res.data, index, 3);
                                            this.regenerateAudio(index, editorType)
                                        }
                                    }).catch((err) => {
                                        this.loadingRegenerate = false;
                                        this.$bvModal.show('server-error-modal')
                                        console.log("Error ini",err);
                                    });
                            } else {

                                // Stop all audio except index audio
                                this.listTextToAudio.forEach((music, i) => {
                                    if(i != index) {
                                        if(music.audio == null || !(music.audio instanceof HTMLAudioElement)){
                                            music.audio = new Audio(this.listTextToAudio[index].path)
                                            music.audio.pause();
                                            clearInterval(music.intervalId);
                                            music.iconPlay = 'ic_play_shape_editor.svg';
                                        } else {
                                            music.audio.pause();
                                            clearInterval(music.intervalId);
                                            music.iconPlay = 'ic_play_shape_editor.svg';
                                        }
                                    }
                                });

                                //Play Audio
                                if(this.listTextToAudio[index].audio == null || !(this.listTextToAudio[index].audio instanceof HTMLAudioElement)){
                                    this.listTextToAudio[index].audio = new Audio(this.listTextToAudio[index].path)
                                }

                                const video = this.$refs.videoPlayer;

                                // Stop video
                                if(video){
                                    if(!video.paused){
                                        this.final_video_play = false;
                                        video.pause();
                                    }
                                }

                                let music = this.listTextToAudio[index].audio
                                if (!music.paused) {
                                    music.pause();
                                    clearInterval(music.intervalId);
                                    this.listTextToAudio[index].iconPlay = 'ic_play_shape_editor.svg';

                                } else {
                                    music.play();
                                    document.dispatchEvent(new Event('play-card-audio-tta'));
                                    music.intervalId = setInterval(() => {
                                        this.updateTimeAudio(index, music.currentTime) // Disini buat update
                                        music.paused ? clearInterval(music.intervalId) : null;
                                        if(!music.paused){
                                            this.listTextToAudio[index].iconPlay = 'ic_pause_shape_editor.svg';
                                        }
                                        if (music.currentTime === music.duration) {
                                            this.listTextToAudio[index].iconPlay = 'ic_play_shape_editor.svg';
                                            clearInterval(music.intervalId);
                                        }
                                    }, 100);
                                }

                                document.addEventListener('play-ssml-audio-tta', () => {
                                    music.pause();
                                    clearInterval(music.intervalId);
                                    this.listTextToAudio[index].iconPlay = 'ic_play_shape_editor.svg';
                                }, { once: true });


                                // console.log("audio play", this.audio_play)


                                // if(this.audio_play == false){
                                //     if(this.listTextToAudio[index].audio == null || !(this.listTextToAudio[index].audio instanceof HTMLAudioElement)){
                                //         this.listTextToAudio[index].audio = new Audio(this.listTextToAudio[index].path)
                                //     }
                                // }

                                // let music = this.listTextToAudio[index].audio


                                // if (!music.paused) {
                                //     music.pause();
                                //     clearInterval(music.intervalId);
                                //     this.listTextToAudio[index].iconPlay = 'ic_play_shape_editor.svg';
                                //     this.audio_play = false
                                // } else {
                                //     music.play();
                                //     music.intervalId = setInterval(() => {
                                //     this.listTextToAudio[index].iconPlay = 'ic_pause_shape_editor.svg';
                                //     this.audio_play = true
                                //     if (music.currentTime === music.duration) {
                                //         this.listTextToAudio[index].iconPlay = 'ic_play_shape_editor.svg';
                                //         clearInterval(music.intervalId);
                                //         this.audio_play = false
                                //     }
                                //     }, 100);
                                // }
                            }
                    }

                }
            },
            generateTextToAudio() {
                let course_id = ""
                if(localStorage.getItem("course_id") != undefined){
                    console.log("ambil course id dari localstorage")
                    course_id = localStorage.getItem("course_id")
                }

                this.loadingGenerateScript = true;
                const form_data = {
                    "course_id" : course_id,
                    "text": this.promptedText,
                    "params":{
                        "speaker": this.ai_masterlang.sound_type ? this.ai_masterlang.sound_type : 'Sovia',
                        "pitch": 0,
                        "speed": 1,
                        "emotion": this.ai_masterlang.emotion ? this.ai_masterlang.emotion : "normal",
                        "regenerate": 'true',
                        "lang" : this.ai_masterlang.lang ? this.ai_masterlang.lang : null
                    },
                    "context": this.scriptType === null ? "lessons" : this.scriptType
                }

                getGeneratedTextToAudio(form_data)
                    .then((res) => {
                        if (res.status === 403){
                            this.$bvModal.show('audio-quota')
                            this.loadingGenerateScript = false
                            this.$vs.loading.close()
                        } else if (res.status === 402 || res.status === 500){
                            this.$bvModal.show('server-error-modal')
                            this.loadingGenerateScript = false
                        } else if (res.status === 406) {
                            sessionStorage.setItem('sentimentFormData', JSON.stringify(form_data));
                            sessionStorage.setItem('sentimentInput', this.promptedText);
                            sessionStorage.setItem('sentimentSource', JSON.stringify({ inputType: 'prompt', scriptType: this.scriptType, AIMasterLang: this.ai_masterlang }));
                            sessionStorage.setItem('isAgreedSentiment', false);

                            this.activeInputEditor = this.$refs.inputPrompt;
                            this.$bvModal.show('sentiment-analysis-warning');

                            this.loadingGenerateScript = false;
                        } else {
                            this.populatePromptGeneratedData({
                                reader: res.body.getReader(),
                                formData: form_data,
                            });
                        }
                        this.getRuler()
                    }).catch((err) => {
                        console.log(err)
                        this.loadingGenerateScript = false
                        this.$vs.loading.close()
                    });
                this.getHistoryData()
                this.getRuler()
            },
            directToEditTextToAudio() {
                this.$router.push({ name: 'Edit-Text-To-Audio' })
            },
            getTtaDurations() {
                var vm = this
                this.arrTta.forEach((val, index) => {
                    vm.ttaDurations += val.duration

                    if(index+1 == vm.arrTta.length) {
                        this.getRuler()
                        this.setHandlerSection()
                    }
                })
            },
            getRuler() {
                this.arrRuler = []
                let width = $(document).width() / 21;
                $('.section-editor').width($(document).width())
                // var width = $(document).width() + 2500/100+2;
                // $('.section-editor').width($(document).width()+2500)

                let widthAudio = 0
                let widthMusic = 0
                let widthImage = 0

                if(this.imageArray != null && this.imageArray.length != 0){
                    this.imageArray.forEach((data) => {
                        widthImage += data.length
                    })
                }

                if(this.listTextToAudio.length != 0){
                    this.listTextToAudio.forEach((data) => {
                        widthAudio += data.length
                    })
                }

                if(this.musicArray != null && this.musicArray.length != 0){
                    this.musicArray.forEach((data) => {
                      widthMusic += data.duration
                    })
                }

                let widthBiggest = Math.max(widthAudio, widthMusic, widthImage);

                if((widthBiggest * 20) < ($(document).width())){
                        width = 0
                        width = $(document).width() / 21
                        // console.log("Width 2",width)
                        $('.section-editor').width($(document).width() + 30)
                } else {
                        width = 0
                        width = widthBiggest
                        $('.section-editor').width((widthBiggest * 20) + 30)
                }

                // console.log(width)

                for(var i = 0; i <= width; i++) {
                    var seconds = parseInt(i);
                    this.arrRuler.push(seconds)
                }
            },
            setHandlerSection() {
                // $('#resizable-music').width(this.ttaDurations*10)
                $('#resizable-image').width(this.ttaDurations*10)

                this.interactHandler('#resizable-music', 'musicLayer')
                this.interactHandler('#resizable-image', 'imageLayer')
            },
            interactHandler(selector, layer) {
                var vm = this;
                interact(selector)
                .resizable({
                    edges: { top: false, left: false, bottom: false, right: true },
                    listeners: {
                        move: function (event) {
                            let { x, y } = event.target.dataset

                            x = (parseFloat(x) || 0) + event.deltaRect.left
                            y = (parseFloat(y) || 0) + event.deltaRect.top

                            // var width = event.rect.width >= vm.ttaDurations*10 ? vm.ttaDurations*10 : event.rect.width
                            // x = x < 0 ? 0 : x
                            // x = x > vm.ttaDurations*10-width ? vm.ttaDurations*10-width : x

                            var width = event.rect.width

                            vm.layers[layer]['width'] = `${width}px`
                            vm.layers[layer]['height'] = `${event.rect.height}px`
                            vm.layers[layer]['transform'] = `translate(${x}px, ${y}px)`
                            vm.layers[layer]['x'] = x
                            vm.layers[layer]['y'] = y

                            let indexElement = event.target.className.split(" ")[0]
                            if(layer === 'imageLayer'){
                                vm.imageArray[indexElement].length = Math.round(width/20)
                                vm.imageArray[indexElement].end = parseFloat((vm.imageArray[indexElement].start + vm.imageArray[indexElement].length).toFixed(2))
                                for(let i = parseInt(indexElement) + 1; i <= vm.imageArray.length - 1; i++){
                                    vm.imageArray[i].start = vm.imageArray[i - 1].length + vm.imageArray[i - 1].start + 0.01
                                    vm.imageArray[i].end = parseFloat((vm.imageArray[i].start + vm.imageArray[i].length).toFixed(2))
                                }
                                sessionStorage.setItem("imageArray", JSON.stringify(vm.imageArray))
                                // this.generatedTtaFinal = false
                            }

                            if(layer === 'musicLayer'){
                                vm.musicArray[indexElement].duration = Math.round(width/20)
                                vm.musicArray[indexElement].end = parseFloat((vm.musicArray[indexElement].start + vm.musicArray[indexElement].duration).toFixed(2))
                                for(let i = parseInt(indexElement) + 1; i <= vm.musicArray.length - 1; i++){
                                    vm.musicArray[i].start = vm.musicArray[i - 1].duration + vm.musicArray[i - 1].start + 0.01
                                    vm.musicArray[i].end = parseFloat((vm.musicArray[i].start + vm.musicArray[i].duration).toFixed(2))
                                }
                                sessionStorage.setItem("musicArray", JSON.stringify(vm.musicArray))
                                // this.generatedTtaFinal = false
                            }

                            Object.assign(event.target.style, {
                                width: `${width}px`,
                                height: `${event.rect.height}px`,
                                transform: `translate(${x}px, ${y}px)`
                            })

                            Object.assign(event.target.dataset, { x, y })
                            vm.generatedTtaFinal = true
                            vm.getHistoryData()
                            vm.getRuler()
                        }
                    },
                })
                // .draggable({
                //     onmove: this.moveInteract,
                //     startAxis: 'x',
                //     lockAxis: 'x'
                // });
            },
            moveInteract(event) {
                var target = event.target,
                x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx,
                y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

                x = x < 0 ? 0 : x
                x = x > this.ttaDurations*10-event.rect.width ? this.ttaDurations*10-event.rect.width : x

                target.style.webkitTransform =
                target.style.transform =
                    'translate(' + x + 'px, ' + y + 'px)';
                target.setAttribute('data-x', x);
                target.setAttribute('data-y', y);
            },
            getAttributeLayer(layer) {
                var start = this.layers[layer]['x'] / 10

                this.layers[layer]['player'] = {
                    start: start,
                    end: (parseFloat(this.layers[layer]['width'].replace("px",""))/10) + start
                }
            },
            submit() {
                this.getAttributeLayer('musicLayer')
                this.getAttributeLayer('imageLayer')

                console.log(this.layers)
            },
            convertImage(file, form, name) {
                var vm = this;
                var reader = new FileReader();
                reader.onloadend = function () {
                    switch (form) {
                        case 'image_upload':
                            vm.img_upload = reader.result
                            vm.postImageLibrary(reader.result, name)
                            break;
                        default:
                            break;
                    }
                };
                reader.readAsDataURL(file);
            },
            convertMusic(file, form) {
                var vm = this;
                var reader = new FileReader();
                reader.onloadend = function () {
                    switch (form) {
                        case 'music_upload':
                            vm.music_upload = reader.result
                            break;
                        default:
                            break;
                    }
                };
                reader.readAsDataURL(file);
            },
            imageUpload(e) {
                let type = e.target.files[0].type;
                const fileSize = e.target.files[0].size;

                if (
                    type == "image/png" ||
                    type === "image/jpeg"
                ) {
                    if(fileSize > 1000000){
                        Swal.fire({
                            position: 'top',
                            background: '#FF000080',
                            imageWidth: 18,
                            imageHeight: 18,
                            color: 'white',
                            title: this.$t("file-size-max-1mb"),
                            titleFontSize: '14px',
                            showConfirmButton: false,
                            toast: true,
                            timer: 2000,
                            customClass: {
                                container: 'p-0'
                            },
                            didOpen: () => {
                                const titleElement = document.querySelector('.swal2-title');

                                // TITLE
                                titleElement.style.display = 'inline-block';
                                titleElement.style.setProperty('padding-top', '0px');
                                titleElement.style.setProperty('margin', '0px');
                                titleElement.style.verticalAlign = 'middle';
                                titleElement.style.fontSize = '14px';
                                titleElement.style.fontWeight = 'normal';

                                const containerElement = document.querySelector('.swal2-popup');
                                containerElement.style.display = 'flex';
                                containerElement.style.justifyContent = 'center';
                                containerElement.style.alignItems = 'center';
                                containerElement.style.setProperty('padding', '15px 30px 15px 30px');
                                containerElement.style.setProperty('margin-top', '15px');
                                containerElement.style.setProperty('width', 'auto');
                            }
                        })
                        // this.showAlertImgUpload = true
                        // this.responseImg = this.$t("file-size-max-1mb")
                    } else {
                        this.showAlertImgUpload = false
                        this.imagenameBefore = e.target.files[0].name ? e.target.files[0].name : "Undefined";
                        this.imageFileBefore = e.target.files[0];
                        this.img_upload = URL.createObjectURL(this.imageFileBefore)
                        this.convertImage(this.imageFileBefore, 'image_upload', e.target.files[0].name)
                    }
                }
            },
            musicUpload(e) {

                let type = e.target.files[0].type;
                if (type == "audio/mpeg") {
                    this.showAlertMusic = false;
                    this.musicnameBefore = e.target.files[0].name ? e.target.files[0].name : "Undefined";
                    this.musicFileBefore = e.target.files[0];
                    this.music_upload = URL.createObjectURL(this.musicFileBefore)
                    this.audioForUpload.audio = new Audio(URL.createObjectURL(this.musicFileBefore));
                    this.convertMusic(this.musicFileBefore, 'music_upload')
                }else{
                    this.showAlertMusic = true;
                }
            },
            dragImage(e){
                const file = [...e.dataTransfer.files];

                let type = file[0].type;
                const fileSize = file[0].size;

                if (
                    type == "image/png" ||
                    type === "image/jpeg"
                ) {
                     if(fileSize > 1000000){
                        Swal.fire({
                            position: 'top',
                            background: '#FF000080',
                            imageWidth: 18,
                            imageHeight: 18,
                            color: 'white',
                            title: this.$t("file-size-max-1mb"),
                            titleFontSize: '14px',
                            showConfirmButton: false,
                            toast: true,
                            timer: 2000,
                            customClass: {
                                container: 'p-0'
                            },
                            didOpen: () => {
                                const titleElement = document.querySelector('.swal2-title');

                                // TITLE
                                titleElement.style.display = 'inline-block';
                                titleElement.style.setProperty('padding-top', '0px');
                                titleElement.style.setProperty('margin', '0px');
                                titleElement.style.verticalAlign = 'middle';
                                titleElement.style.fontSize = '14px';
                                titleElement.style.fontWeight = 'normal';

                                const containerElement = document.querySelector('.swal2-popup');
                                containerElement.style.display = 'flex';
                                containerElement.style.justifyContent = 'center';
                                containerElement.style.alignItems = 'center';
                                containerElement.style.setProperty('padding', '15px 30px 15px 30px');
                                containerElement.style.setProperty('margin-top', '15px');
                                containerElement.style.setProperty('width', 'auto');
                            }
                        })
                        // this.showAlertImgUpload = true
                        // this.responseImg = this.$t("file-size-max-1mb")
                    } else {
                        this.showAlertImgUpload = false;
                        this.imagenameBefore = file[0].name ? file[0].name : "Undefined";
                        this.imageFileBefore = file[0];
                        this.img_upload = URL.createObjectURL(this.imageFileBefore)
                        this.convertImage(this.imageFileBefore, 'image_upload', file[0].name)
                    }
                } else {
                    this.alertFail("The file must be JPG or PNG format")
                }
            },
            dragMusic(e){
                const file = [...e.dataTransfer.files];

                let type = file[0].type;
                if (type == "audio/mpeg") {
                    this.showAlertMusic = false;
                    this.musicnameBefore = file[0].name ? file[0].name : "Undefined";
                    this.musicFileBefore = file[0];
                    this.music_upload = URL.createObjectURL(this.musicFileBefore)
                    this.audioForUpload.audio = new Audio(URL.createObjectURL(this.musicFileBefore));
                    this.convertMusic(this.musicFileBefore, 'music_upload')
                }else{
                    this.showAlertMusic = true;
                }
            },
            cancelImg(){
                this.imagenameBefore = "";
                this.imageFileBefore = "";
                this.img_upload = null;
                this.$bvModal.hide('upload-image-editor')
            },
            updateTime(){
                this.player.currentTime = this.currentTime;
            },
            timeUpdate(){
                this.currentTime = this.audio.currentTime
                const hr = Math.floor(this.currentTime / 3600);
                const min = Math.floor((this.currentTime - (hr * 3600)) / 60);
                const sec = Math.floor(this.currentTime - (hr * 3600) - (min * 60));
                this.timeLabel = `${min.toString()
                    .padStart(2, '0')}:${sec.toString()
                    .padStart(2, '0')}`;

                if(this.currentTime == this.audio.duration){
                    this.iconFill = "ic_play_circle_editor.svg"
                }
            },
            playAudio(index) {
                this.musicList.forEach((music, i) => {
                    if (i !== index) {
                        music.audio.pause();
                        clearInterval(music.intervalId);
                        music.audio.currentTime = 0;
                        music.currentTime = music.audio.currentTime;
                        music.timeLabel = music.durationOrigin
                        music.iconFill = 'ic_play_circle_editor.svg';
                    }
                });

                let music = this.musicList[index];

                // turn on loading if music still rendering
                music.audio.onwaiting = () => {
                    music.loading = true;
                };

                //turn off loading if music already rendering
                music.audio.onplaying = () => {
                    music.loading = false;
                };

                if (!music.audio.paused) {
                    music.audio.pause();
                    clearInterval(music.intervalId);
                    music.iconFill = 'ic_play_circle_editor.svg';
                } else {
                    music.audio.play();
                    music.intervalId = setInterval(() => {
                    music.currentTime = music.audio.currentTime;
                    music.iconFill = 'ic_pause_circle_editor.svg';
                    music.timeLabel = music.durationOrigin - music.audio.currentTime
                    if (music.currentTime === music.audio.duration) {
                        music.iconFill = 'ic_play_circle_editor.svg';
                        clearInterval(music.intervalId);
                    }
                    }, 100);
                }
            },
            formatTime(time) {
                let minutes = Math.floor(time / 60)
                let seconds = Math.floor(time % 60)
                return `${minutes < 10  ? '0' + minutes : minutes}:${seconds < 10  ? '0' + seconds : seconds}`
            },

            addIndexAudioInit(){
                if(sessionStorage.getItem("listTextToAudio") == undefined){
                    this.listTextToAudio.push(
                    {
                        generate_first: this.listTextToAudio.length == 0 ? false : true,
                        text: "",
                        course_id : this.listTextToAudio.length > 0 ? this.listTextToAudio[0].course_id : "",
                        path : null,
                        length : 0,
                        tts_id: null,
                        sound_type: "Sovia",
                        emotion: "Normal",
                        pitch: 0,
                        speed:null,
                        avatar: null,
                        lang: null,
                        iconPlay:'ic_play_shape_editor.svg',
                        isFocused : false,
                        from_ai : false,
                        loading_generate : false,
                        characters: [],
                    })
                    sessionStorage.setItem("listTextToAudio", JSON.stringify(this.listTextToAudio))
                }
            },
            addIndexAudio(){
                this.listTextToAudio.push(
                    {
                        generate_first: this.listTextToAudio.length == 0 ? false : true,
                        text: "",
                        course_id : this.listTextToAudio.length > 0 ? this.listTextToAudio[0].course_id : "",
                        path : null,
                        length : 0,
                        tts_id: null,
                        sound_type: "Sovia",
                        emotion: "Normal",
                        pitch: 0,
                        speed:null,
                        avatar: null,
                        lang: null,
                        iconPlay:'ic_play_shape_editor.svg',
                        isFocused : false,
                        from_ai : false,
                        characters: [],
                        loading_generate : false
                    }
                )
                sessionStorage.setItem("listTextToAudio", JSON.stringify(this.listTextToAudio))
            },
            duplicateAudio(index){
                this.listTextToAudio.push(
                    {
                        text: this.listTextToAudio[index].text,
                        course_id : this.listTextToAudio[index].course_id,
                        path : this.listTextToAudio[index].path,
                        length : this.listTextToAudio[index].length,
                    }
                )
            },

            deleteIndex(index){
                this.deleted_audio_index = index
                this.$bvModal.show('delete-audio-tta')
            },

            cancelDeleteAudio(){
                this.deleted_audio_index = null
                this.$bvModal.hide('delete-audio-tta')
            },

            deleteAudioByIndex(){
                this.listTextToAudio.splice(this.deleted_audio_index, 1);

                if(this.listTextToAudio.length == 0){
                    this.listTextToAudio.push({
                        generate_first: false,
                        text : '',
                        length : 0,
                        course_id : null,
                        path : null,
                        audio: null,
                        tts_id: null,
                        sound_type: "Sovia",
                        emotion: "Normal",
                        pitch: 0,
                        speed: null,
                        iconPlay: 'ic_play_shape_editor.svg',
                        isFocused: true
                    })
                }

                sessionStorage.setItem("listTextToAudio", JSON.stringify(this.listTextToAudio))
                this.getHistoryData()
                this.$bvModal.hide('delete-audio-tta')
                this.compressCardModal();
            },

            showMenuDeleteMusic(event) {
                event.preventDefault();
                this.showContextMenu = true;
                this.menuX = event.clientX;
                this.menuY = event.clientY;
                this.itemLayerDelete = 'music'
                this.itemTimelineDelete = event.target.closest("div[id='resizable-music']").className.split(" ")[0]
            },
            showMenu(event) {
                event.preventDefault();
                this.showContextMenu = true;
                this.menuX = event.clientX;
                this.menuY = event.clientY;
                console.log("menuX: " + this.menuX, "menuY: " + this.menuY);
                this.itemLayerDelete = 'image'
                this.itemTimelineDelete = event.target.className.split(" ")[0]
            },
            hideMenu() {
                this.showContextMenu = false;
            },
            deleteItemTimeline(){
                if(this.itemLayerDelete === 'image'){
                    this.imageArray.splice(this.itemTimelineDelete, 1);
                    for(let i = 0; i <= this.imageArray.length - 1; i++){
                        if(i == 0){
                            this.imageArray[i].start = 0
                        } else {
                            this.imageArray[i].start = this.imageArray[i - 1].length + this.imageArray[i - 1].start + 0.01
                        }
                        this.imageArray[i].end = parseFloat((this.imageArray[i].start + this.imageArray[i].length).toFixed(2))
                    }
                    sessionStorage.setItem("imageArray", JSON.stringify(this.imageArray))
                    this.getHistoryData()
                    this.getRuler()
                }
                if(this.itemLayerDelete === 'music'){
                    this.musicArray.splice(this.itemTimelineDelete, 1);
                    for(let i = 0; i <= this.musicArray.length - 1; i++){
                        if(i == 0){
                            this.musicArray[i].start = 0
                        } else {
                            this.musicArray[i].start = this.musicArray[i - 1].duration + this.musicArray[i - 1].start + 0.01
                        }
                        this.musicArray[i].end = parseFloat((this.musicArray[i].start + this.musicArray[i].duration).toFixed(2))
                    }
                    sessionStorage.setItem("musicArray", JSON.stringify(this.musicArray))
                    this.getHistoryData()
                    this.getRuler()
                }

                this.generatedTtaFinal = true;
                localStorage.setItem("generatedTtaFinal", true)

            },
            typePlaceholder() {
                var i = 0;
                var listIndex = 1;
                const speed = 150;
                var placeholder = ''
                const placeholderValue1 = 'Buatkan audio tentang webinar otomotif';
                const placeholderValue2 = 'Sebuah deskripsi terkait presentasi yang bertemakan lingkungan';
                const placeholderValue3 = 'Ceritakan bagaimana pengaruh pemanasan global dengan ekonomi';
                // const el = document.getElementById('describe-tta');

                // if(el != null) {
                    setInterval(() => {
                        if(listIndex == 1){
                            placeholder += placeholderValue1.charAt(i);
                        } else if(listIndex == 2){
                            placeholder += placeholderValue2.charAt(i);
                        } else if(listIndex == 3) {
                            placeholder += placeholderValue3.charAt(i);
                        }
                        // el.setAttribute("placeholder", placeholder);
                        this.placeholderType = placeholder
                        i++;

                        if(listIndex == 1){
                            if (this.placeholderType == placeholderValue1) {
                                placeholder = '';
                                listIndex++;
                                i = 0;
                            }
                        } else if(listIndex == 2){
                            if (this.placeholderType == placeholderValue2) {
                                placeholder = '';
                                listIndex++;
                                i = 0;
                            }
                        } else if(listIndex == 3) {
                            if (this.placeholderType == placeholderValue3) {
                                placeholder = '';
                                listIndex = 1;
                                i = 0;
                            }
                        }
                    }, speed)
                // }
            },
            getInitData(){
                let mode = this.$route.params.mode
                let id = this.$route.params.id
                if(mode === 'draft' || mode === 'edit'){
                    getDataContent(id).then(res => {
                    if(res.status == true) {
                        res.data.image_array == "[]" || res.data.image_array == null || res.data.image_array == "null" ? this.imageArray = [] : this.imageArray = res.data.image_array
                        res.data.music_array == "[]" || res.data.music_array == null || res.data.music_array == "null" ? this.musicArray = [] : this.musicArray = res.data.music_array

                        if (sessionStorage.getItem('sentimentFormData')) {
                            this.getHistoryData();
                        } else {
                            if(res.data.audio_array == "[]" || null) {
                                this.listTextToAudio = [{
                                    generate_first: false,
                                    text : '',
                                    length : 0,
                                    course_id : null,
                                    path : null,
                                    audio: null,
                                    tts_id: null,
                                    sound_type: "Soleh",
                                    emotion: "Normal",
                                    pitch: 0,
                                    speed: null,
                                    iconPlay: 'ic_play_shape_editor.svg'
                                }]
                            } else {
                                this.listTextToAudio = res.data.audio_array
                            }
                        }

                        localStorage.setItem("content_name_tta", res.data.content_name)
                        localStorage.setItem("final_path_tta", res.data.content_file)
                        localStorage.setItem("image_thumbnail_tta", res.data.image_thumbnail)
                        localStorage.setItem("generatedTtaFinal", res.data.generate_tta_final)
                        localStorage.setItem("volume_music_tta", res.data.volume_music)
                        localStorage.setItem("volume_video_tta", res.data.volume_audio)
                        localStorage.setItem("ratio_tta", res.data.ratio == 'undefined' ? '' : res.data.ratio ? res.data.ratio : '')
                        localStorage.setItem("jobs_id_tta", res.data.job_id)
                        sessionStorage.setItem("imageArray", this.imageArray.length == 0 ? JSON.stringify([]) : this.imageArray)
                        sessionStorage.setItem("musicArray", this.musicArray.length == 0 ? JSON.stringify([]) : this.musicArray)

                        if (!sessionStorage.getItem('sentimentFormData')) {
                            res.data.audio_array == "[]" ? sessionStorage.setItem("listTextToAudio", JSON.stringify(this.listTextToAudio)) : sessionStorage.setItem("listTextToAudio", this.listTextToAudio)
                        }

                        // INITIAL DATA
                        localStorage.setItem("init_content_name_tta", res.data.content_name)
                        localStorage.setItem("init_volume_music_tta", res.data.volume_music)
                        localStorage.setItem("init_volume_video_tta", res.data.volume_audio)
                        localStorage.setItem("init_ratio_tta", res.data.ratio == 'undefined' ? '' : res.data.ratio ? res.data.ratio : '')
                        sessionStorage.setItem("init_imageArray", this.imageArray.length == 0 ? JSON.stringify([]) : this.imageArray)
                        sessionStorage.setItem("init_musicArray", this.musicArray.length == 0 ? JSON.stringify([]) : this.musicArray)

                        if (!sessionStorage.getItem('sentimentFormData')) {
                            res.data.audio_array == "[]" ? sessionStorage.setItem("init_listTextToAudio", JSON.stringify(this.listTextToAudio)) : sessionStorage.setItem("init_listTextToAudio", this.listTextToAudio)
                        }

                        if (!sessionStorage.getItem('sentimentFormData')) {
                            this.getHistoryData();
                        }

                        if(res.data.content_file != null){
                            this.$store.state.generatedAudioContent = true;
                        }

                        this.handleSentimentFormData();
                    }
                    })
                    .catch(err => {
                        console.log(err);
                        this.handleSentimentFormData();
                    })
                } else {
                    // this.removeDataStorage()
                }
            },

            closeMusic(){
                this.musicList.forEach((music) => {
                    music.audio.pause();
                    clearInterval(music.intervalId);
                    music.audio.currentTime = 0;
                    music.currentTime = music.audio.currentTime;
                    music.timeLabel = music.durationOrigin
                    music.iconFill = 'ic_play_circle_editor.svg';
                });
                this.filterMusic = [];
                this.$bvModal.hide('upload-music-editor')
            },

            clearFilter(){
                this.filterMusic = [];
            },

            testModal(){
                Swal.fire({
                    icon: 'warning',
                    title: 'Huftt...',
                    html: 'Your audio duration has exceeded <br> the maximum limit.',
                })
            },

            onInput(e){
                this.promptedText = e.target.innerText
                // console.log(this.promptedText);
            },

            onInputScript(e, val){
                this.listTextToAudio[val].text = e.target.innerText
                console.log(e.target.innerText, val, this.listTextToAudio[val].text)
            },

            setScriptType(val){
                this.scriptType = val
            },

            showAi(){
                if(this.status_api[0].status == "ok"){
                    if(this.showInputAi){
                        this.icFloatAi = 'ic_float_ai_inactive.svg'
                        this.showInputAi = false
                    } else {
                        this.icFloatAi = 'ic_float_ai.svg'
                        this.showInputAi = true
                    }
                } else {
                    this.icFloatAi = 'ic_float_ai_inactive.svg'
                    this.showInputAi = false
                    this.$bvModal.show('server-error-modal')
                }
            },

            // Needle function
            updateTimeVideo() {
                const video = this.$refs.videoPlayer;
                if(!video.paused){
                    this.final_video_play = true;
                } else {
                    this.final_video_play = false;
                }

                if(video.currentTime == video.duration){
                    this.final_video_play = false;
                }

                this.currentTimeVideo = this.formatTime(video.currentTime)
                this.generatedVideoDuration = this.formatTime(video.duration)
                this.sliderPosition = video.currentTime * 20;
                // console.log('slider position (video)', this.sliderPosition)
            },

            updateTimeAudio(index, currentTime) {
                const sumBeforeIndex = this.listTextToAudio.slice(0, index).reduce((acc, curr) => acc + curr.length, 0);
                this.sliderPosition = (currentTime + sumBeforeIndex) * 20;
            },

            moveSliderTo(event) {
                if(this.finalPath != null){
                    const video = this.$refs.videoPlayer;

                    // const posX = event.clientX - 58;
                    // console.log(event.clientX - 58 , posX / 20)
                    video.currentTime = (event.clientX - this.$refs.timeline.getBoundingClientRect().left - 10) / 20

                }
            },

            startDrag(event) {
                this.isDragging = true;
                this.startX = event.clientX;
                this.lastSliderPosition = this.sliderPosition;
            },

            stopDrag() {
                this.isDragging = false;
            },

            updatePosition(e) {
                if (!this.isDragging) {
                    return this.lastSliderPosition;
                }

                const video = this.$refs.videoPlayer;
                const newSliderPosition = this.lastSliderPosition + e.clientX - this.startX;
                if (newSliderPosition < 0) {
                    this.sliderPosition = 0;
                } else if (newSliderPosition > this.$el.offsetWidth - 40) {
                    this.sliderPosition = this.$el.offsetWidth - 40;
                } else {
                    this.sliderPosition = newSliderPosition;
                }

                video.currentTime = this.sliderPosition / 20;
                return this.sliderPosition; // simpan posisi terakhir
            },

            timelineShow(){
                if(!this.show_timeline) {
                    this.show_timeline = true
                } else {
                    this.show_timeline = false
                }
            },

            scriptInputFocus(index){
                this.listTextToAudio.forEach((e, i) => {
                    if(i !== index) {
                        e.isFocused = false
                    } else {
                        e.isFocused = true
                    }
                })
            },

            changeVolumeMusic(e){
                console.log(e)
                var volume_music_before = 0.35
                if(localStorage.getItem("volume_music_tta")){
                    volume_music_before = localStorage.getItem("volume_music_tta")
                }

                if(this.listTextToAudio.length > 0 && this.listTextToAudio[0].course_id != ""){
                    if(volume_music_before != e){
                        this.generatedTtaFinal = true
                    } else {
                        this.generatedTtaFinal = false
                    }
                }


                // localStorage.setItem("volume_music_tta", this.volume_music)
            },

            changeVolumeVideo(e){
                var volume_video_before = 1
                if(localStorage.getItem("volume_video_tta")){
                    volume_video_before = localStorage.getItem("volume_video_tta")
                }

                if(this.listTextToAudio.length > 0 && this.listTextToAudio[0].course_id != ""){
                    if(volume_video_before != e){
                        this.generatedTtaFinal = true
                    } else {
                        this.generatedTtaFinal = false
                    }
                }
            },

            postImageLibrary(file, name){
                this.loadingImageUpload = true;

                let formData = {
                    images : file,
                    filename : name
                }

                postImageLibrary(formData)
                .then((response) => {
                    if(response.status == true) {
                        this.loadingImageUpload = false;
                        this.getImageLibrary()
                    } else {
                        this.loadingImageUpload = false;
                    }
                })
            },

            getImageLibrary(){
                this.loadingGetImage = true;
                getImageLibrary()
                .then((res) => {
                    this.imageLibrary = res.data != null ? res.data : []
                    this.loadingGetImage = false;
                })
            },

            deleteImageLibrary(id){
                deleteImageLibraryById(id)
                .then((response) => {
                    if(response.status == true){
                        this.getImageLibrary()
                    }
                })
            },

            selectImageLibrary(id, image_url){
                this.selectedImgLibrary = id
                this.selectedImage = [{
                    image_url : image_url,
                    image_thumb : image_url
                }]
            },

            getStatusSubscriptions(){
                this.loading_subscriptions = true;

                subscriptionsStatus()
                .then((response) =>{
                    if(response.status == true ){
                        this.status_subscriptions = response.data
                        this.loading_subscriptions = false;
                    } else if(response.status == false && response.message == 'Allocations Not Found') {
                        this.loading_subscriptions = false;
                    } else {
                        this.loading_subscriptions = false;
                        // localStorage.clear();
                        // this.$router.push({ name : 'SignIn' })
                        console.log(response)
                    }
                })
            },

            generateCheck(index){
                let audioLocalStorage = JSON.parse(sessionStorage.getItem('listTextToAudio'))
                if(this.listTextToAudio[index].text == ''){
                    return "Generate Audio"
                } else if(this.listTextToAudio[index].path == null){

                    return true
                } else if (
                    this.listTextToAudio[index].text == audioLocalStorage[index].text
                    &&
                    this.listTextToAudio[index].emotion == audioLocalStorage[index].emotion
                    &&
                    this.listTextToAudio[index].pitch == audioLocalStorage[index].pitch
                    &&
                    this.listTextToAudio[index].sound_type == audioLocalStorage[index].sound_type
                ) {
                    return false
                } else {

                    return true
                }
            },

            alertTts(){
                let vm = this
                vm.alertFail("No audio to generate")
            },

            checkStatusApi(){
                checkStatusApi()
                .then((response) => {
                    if(response.status == 200){
                        this.status_api = response.data
                        if(this.status_api[0].status != "ok"){
                            this.icFloatAi = 'ic_float_ai_inactive.svg'
                            this.showInputAi = false
                        }

                        if(this.status_api[1].status == 'nok'){
                            this.status_api_tts = true
                            this.$bvModal.show('server-error-modal')
                        }
                    }
                })
            },

            removeDataStorage(){
                sessionStorage.removeItem("imageArray")
                sessionStorage.removeItem("listTextToAudio")
                sessionStorage.removeItem("musicArray")
                localStorage.removeItem("final_path_tta")
                localStorage.removeItem("image_thumbnail_tta")
                localStorage.removeItem("content_name_tta")
                localStorage.removeItem("generatedTtaFinal")
                localStorage.removeItem("course_id")
                localStorage.removeItem("volume_music_tta")
                localStorage.removeItem("volume_video_tta")
                localStorage.removeItem("jobs_id_tta")
                localStorage.removeItem("ratio_tta")
            },

            removeSentimentStorage() {
                sessionStorage.removeItem('sentimentFormData');
                sessionStorage.removeItem('sentimentInput');
                sessionStorage.removeItem('sentimentSource');
                sessionStorage.removeItem('isAgreedSentiment');
            },

            getMasterLanguages(){
                let params = {
                    "page" : 1,
                    "perPage" : 100
                }

                this.loadMasterLang = true
                this.loadMasterLangFilter = true

                getMasterLanguages(params)
                .then((response) => {
                    if(response.status == 200){
                        this.masterLanguages = response.data
                        this.getMasterLanguagesFilter()
                        this.loadMasterLang = false
                    } else {
                        this.loadMasterLang = false
                    }
                })
            },

            getMasterLanguagesFilter(lang = null, callback = null){
                this.masterLanguagesFilter = {
                    id: null,
                    Detail: []
                }

                let params = {
                    "page" : 1,
                    "perPage" : 100,
                    "filter" : lang || this.set_lang
                }

                this.loadMasterLangFilter = true

                getMasterLanguages(params)
                .then((response) => {
                    if(response.status == 200){
                        let data = response.data[0]
                        this.masterLanguagesFilter.id = data._id.$oid
                        this.masterLanguagesFilter.LanguageCode = data.LanguageCode
                        this.masterLanguagesFilter.flags = base_url_machine_learning + data.Flags
                        data.Detail.forEach(e => {
                            if(e.PathSample != ""){
                                this.masterLanguagesFilter.Detail.push({
                                    Gender : e.Gender,
                                    Name : e.Name,
                                    PathAvatar : base_url_machine_learning + e.PathAvatar,
                                    PathThumbnailAvatar : base_url_machine_learning + e.PathThumbnailAvatar,
                                    audio_excited : new Audio(base_url_machine_learning + e.PathSample.excited),
                                    audio_normal : new Audio(base_url_machine_learning + e.PathSample.normal),
                                    Emotion : "Normal",
                                    icon_audio : "play_svm.svg"
                                })
                            }
                        })
                        if (callback) {
                            callback();
                        }
                        this.loadMasterLangFilter = false
                    } else {
                        if (callback) {
                            callback();
                        }
                        this.loadMasterLangFilter = false
                    }
                })
            },

            setEmotionMasterLang(index, emotion){
                this.masterLanguagesFilter.Detail[index].Emotion = emotion
            },

            playPreviewAudio(index, emotion, name){
                let ind = index

                var indexByName = null

                if(this.selected_gender != null){
                    indexByName = this.masterLanguagesFilter.Detail.findIndex(function(item) {
                        return item.Name === name;
                    });
                }

                if(indexByName) {
                    ind = indexByName
                }

                this.masterLanguagesFilter.Detail.forEach((audio, i) => {
                    if(i !== ind) {
                        audio.audio_normal.pause()
                        audio.audio_excited.pause()
                        audio.icon_audio = "play_svm.svg"
                    }
                })

                let music = this.masterLanguagesFilter.Detail[ind]

                if(emotion == 'Normal'){
                    if(!music.audio_excited.paused){
                        music.audio_excited.pause()
                    }

                    if(!music.audio_normal.paused){
                        music.audio_normal.pause()
                        music.icon_audio = "play_svm.svg"
                    } else {
                        music.audio_normal.play()
                        music.icon_audio = "pause_svm.svg"
                        music.audio_normal.onended = function(){
                            music.icon_audio = "play_svm.svg"
                        }
                    }
                } else {
                    if(!music.audio_normal.paused){
                        music.audio_normal.pause()
                    }

                    if(!music.audio_excited.paused){
                        music.audio_excited.pause()
                        music.icon_audio = "play_svm.svg"
                    } else {
                        music.audio_excited.play()
                        music.icon_audio = "pause_svm.svg"
                        music.audio_excited.onended = function(){
                            music.icon_audio = "play_svm.svg"
                        }
                    }
                }
            },

            changeLang(country, flags){
                this.selectedMasterLang.country = country
                this.selectedMasterLang.flags = base_url_machine_learning + flags
                this.set_lang = country
                this.stopMasterLangAudio()
                this.getMasterLanguages()
            },

            selectGenderLang(gender){
                this.masterLanguagesFilter.Detail.forEach((audio) => {
                    audio.audio_normal.pause()
                    audio.audio_excited.pause()
                    audio.icon_audio = "play_svm.svg"
                })

                let before = gender
                if(before == this.selected_gender){
                    this.selected_gender = null
                } else {
                    this.selected_gender = gender
                }
            },

            openMasterLang(index, from){
                if(from == 'ai'){
                    this.from_ai = true;
                } else {
                    this.from_ai = false;
                }

                this.index_card = index
                this.changeLang('Indonesia', 'static/template/mp3/asset_sample_languages/Indonesia/flags.png')
                this.stopMasterLangAudio()
                this.$bvModal.show('select-voice-modal')
            },

            closeMasterLang(){
                this.stopMasterLangAudio()
                this.$bvModal.hide('select-voice-modal')
            },

            changeSSMLActiveSpeaker({ index = null, name = null }) {
                let speaker = name;

                if (index !== null) {
                    speaker = this.listTextToAudio[index].sound_type.toLowerCase();
                }

                if (speaker !== null && sessionStorage.getItem('SSMLActiveSpeaker') !== speaker) {
                    document.dispatchEvent(this.changeSSMLActiveSpeakerEvent);
                    sessionStorage.setItem('SSMLActiveSpeaker', speaker);
                }
            },

            expandCardModal(index) {
                this.changeSSMLActiveSpeaker({ index });
                this.index_card_expand = index;
                this.$bvModal.show('expanded-card-modal');
            },

            compressCardModal() {
                this.$bvModal.hide('expanded-card-modal');
            },

            selectCharacter(name, emotion, avatar){
                if(this.from_ai == false){
                    this.listTextToAudio[this.index_card].sound_type = name
                    this.listTextToAudio[this.index_card].emotion = emotion
                    this.listTextToAudio[this.index_card].avatar = avatar
                    this.listTextToAudio[this.index_card].lang = this.masterLanguagesFilter.id
                    // this.listTextToAudio[this.index_card].characters = this.masterLanguagesFilter.Detail
                    this.$bvModal.hide('select-voice-modal')
                    this.index_card = null
                } else {

                    this.ai_masterlang = {
                        avatar : avatar,
                        emotion : emotion,
                        lang : this.masterLanguagesFilter.id,
                        flags : this.masterLanguagesFilter.flags,
                        language_code : this.masterLanguagesFilter.LanguageCode,
                        sound_type : name,
                        Detail : this.masterLanguagesFilter.Detail
                    }
                    this.$bvModal.hide('select-voice-modal')
                }
                this.stopMasterLangAudio()
                this.changeSSMLActiveSpeaker({ name: name.toLowerCase() });
            },

            stopMasterLangAudio(){
                const mediaElements = this.masterLanguagesFilter.Detail.length > 0 ? this.masterLanguagesFilter.Detail : null

                // Turn off audio
                if(mediaElements != null){
                    mediaElements.forEach(element => {
                        if(element.audio_normal == null || !(element.audio_normal instanceof HTMLAudioElement)){
                            element.audio_normal = new Audio(element.audio_normal)
                        }
                        if(element.audio_excited == null || !(element.audio_excited instanceof HTMLAudioElement)){
                            element.audio_excited = new Audio(element.audio_excited)
                        }
                        element.audio_normal.pause();
                        element.audio_excited.pause();
                        element.icon_audio = 'play_svm.svg'
                    });
                }
            },

            setRatio(val){
                this.set_ratio = val
                this.generatedTtaFinal = true;
                localStorage.setItem("generatedTtaFinal", true)
            },

            onScrollMusic(){
                const musicList = this.$refs.containerMusic;
                const isAtBottom = musicList.scrollTop + musicList.clientHeight >= musicList.scrollHeight;
                const shouldLoadMore = !this.loadScrollMusic && (this.filterMusic.length == 0 || this.filterMusic[0] == 'All') && (this.totalPage == null || this.pageMusic <= this.totalPage);

                if (isAtBottom && shouldLoadMore) {
                    this.getListMusic();
                }
            },

            uploadScript(e){
                let files = e.target.files[0]
                let type = files.type;
                if(
                    type == "text/plain"
                    ||
                    type == "text/vtt"
                    ||
                    type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                ) {
                    if(files.size > 1000000){
                        this.alertFail(this.$t("file-size-max-1mb"));
                    } else {
                        this.script_file = files
                        this.script_file_name = files.name ? files.name : 'Script Import'
                    }
                } else {
                    this.alertFail(this.$t('docs-txt--vtt'))
                }

            },

            dragScript(e){
                const files = [...e.dataTransfer.files];

                let type = files[0].type;

                if(
                    type == "text/plain"
                    ||
                    type == "text/vtt"
                    ||
                    type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                )
                {
                    if(files[0].size > 1000000) {
                        this.alertFail(this.$t("file-size-max-1mb"));
                    } else {
                        this.script_file = files[0]
                        this.script_file_name = files[0].name ? files[0].name : 'Script Import'
                    }
                } else {
                    this.alertFail(this.$t('docs-txt--vtt'))
                }
            },

            closeModalImportScript(){
                this.clearDataImport()
                this.$bvModal.hide('import-script-modal')
            },

            uploadScriptProgress(){
                let formData = new FormData();
                formData.append('uploadfile', this.script_file)

                const config = {
                    onUploadProgress: progressEvent => {
                        this.showProgress = true;
                        this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    },
                    cancelToken: new axios.CancelToken(cancel => {
                        this.cancelTokenSource = cancel; // simpan cancel token ke properti cancelSource
                    })
                };

                importScriptAudio(formData, config)
                .then((response) => {
                    if(response.status){
                        response.data.forEach((i) => {
                            if(i != ''){
                                this.uploadedScript.push(i)
                            }
                        })
                        this.showProgress = false;
                        this.uploadProgress = 0;
                    }
                })


                // this.showProgress = true;
                // let delay = Math.floor(Math.random() * 3) + 3; // random antara 3-5 detik
                // this.timerUpload = setInterval(() => {
                //     this.uploadProgress += 10;
                //     if (this.uploadProgress >= 100) {
                //         clearInterval(this.timerUpload);
                //         importScriptAudio(formData)
                //         .then((response) => {
                //             if(response.status == true) {
                //                 response.data.forEach((i) => {
                //                     if(i != ''){
                //                         this.uploadedScript.push(i)
                //                     }
                //                 })
                //                 this.showProgress = false;
                //                 this.uploadProgress = 0;
                //             }
                //         })
                //     }
                // }, (delay * 1000) / 10);
            },

            cancelWhileUpload(){
                this.clearDataImport()
                this.cancelTokenSource(); // panggil cancel() pada cancel token
                this.showProgress = false;
                this.progress = 0;
            },

            importResultUpload(){
                if(this.uploadedScript.length > 0) {
                    this.uploadedScript.forEach((data) => {
                        this.listTextToAudio.push(
                            {
                                generate_first: this.listTextToAudio.length == 0 ? false : true,
                                text: data,
                                course_id : this.listTextToAudio.length > 0 ? this.listTextToAudio[0].course_id : "",
                                path : null,
                                length : 0,
                                tts_id: null,
                                sound_type: this.sound_type_upload ? this.sound_type_upload : "Sovia",
                                emotion: "Normal",
                                pitch: 0,
                                speed:null,
                                avatar: this.avatar_upload ? this.avatar_upload : null,
                                lang: this.masterLanguagesFilter.id ? this.masterLanguagesFilter.id : '',
                                iconPlay:'ic_play_shape_editor.svg',
                                isFocused : false,
                                from_ai : false,
                                characters: [],
                                loading_generate : false
                            }
                        )
                    })
                    sessionStorage.setItem("listTextToAudio", JSON.stringify(this.listTextToAudio))
                }
                this.$bvModal.hide('import-script-modal')
                this.clearDataImport()
            },

            selectCharacterUpload(type, avatar){
                this.sound_type_upload = type
                this.avatar_upload = avatar
            },

            clearDataImport(){
                clearInterval(this.timerUpload)
                this.stopMasterLangAudio()
                this.showProgress = false;
                this.uploadedScript = []
                this.uploadProgress = 0;
                this.script_file = null
                this.script_file_name = null
                this.sound_type_upload = null
                this.avatar_upload = null
            },

            backwardAudio(){
                const video = this.$refs.videoPlayer;
                if(video) {
                    if(!video.paused){
                        video.currentTime = 0;
                        video.pause()
                    } else {
                        this.stopAudio()
                        if(this.sliderPosition != 0) { this.sliderPosition = 0 }
                    }
                } else {
                    this.stopAudio()
                    if(this.sliderPosition != 0) { this.sliderPosition = 0 }
                }
            },

            handleHideSentimentAnalysisWarning() {
                const source = JSON.parse(sessionStorage.getItem('sentimentSource'));

                if (['script-default', 'script-fullscreen'].includes(source?.inputType)) {
                    this.listTextToAudio[source.index].path = null;
                }

                this.removeSentimentStorage();

                if (this.activeInputEditor) {
                    setTimeout(() => {
                        if (this.activeInputEditor instanceof HTMLElement) {
                            this.activeInputEditor.focus();
                        } else {
                            this.activeInputEditor.focus('end');
                        }
                    }, 200);
                }
            },

            autoResizeInputPrompt() {
                this.$refs.inputPrompt.style.height = '20px';
                this.$refs.inputPrompt.style.height = `${this.$refs.inputPrompt.scrollHeight}px`;
            },

            populateScriptGeneratedData(data, index, type) {
                switch (type) {
                    case 1:
                        this.isGenerated = true;
                        this.generatedScript = data.text
                        this.generatedAudio = base_url_machine_learning + data.path
                        this.listTextToAudio[index].text = data.text
                        this.listTextToAudio[index].length = data.length
                        this.listTextToAudio[index].course_id = data.course_id
                        this.listTextToAudio[index].path = base_url_machine_learning + data.path
                        this.listTextToAudio[index].audio = null
                        this.listTextToAudio[index].tts_id = data.tts_id
                        this.listTextToAudio[index].iconPlay = 'ic_play_shape_editor.svg'
                        this.listTextToAudio[index].loading_generate = false;

                        this.listTextToAudio[0].course_id = data.course_id

                        this.generatedTtaFinal = true;
                        sessionStorage.setItem("listTextToAudio", JSON.stringify(this.listTextToAudio))
                        localStorage.setItem("generatedTtaFinal", true)
                        localStorage.setItem("course_id", data.course_id)
                        this.loadingRegenerate = false;
                        this.getStatusSubscriptions()
                        this.getRuler()
                        break;
                    case 2:
                    case 3:
                        this.isGenerated = true;
                        this.generatedScript = data.text
                        this.generatedAudio = base_url_machine_learning + data.path
                        this.listTextToAudio[index].text = data.text
                        this.$set(this.listTextToAudio[index], 'length' , data.length)
                        this.listTextToAudio[index].length = data.length
                        this.listTextToAudio[index].course_id = data.course_id
                        this.listTextToAudio[index].path = base_url_machine_learning + data.path
                        this.listTextToAudio[index].audio = null
                        this.listTextToAudio[index].tts_id = data.tts_id
                        // this.listTextToAudio[index].from_ai = false
                        // this.listTextToAudio[index].characters = []
                        this.listTextToAudio[index].iconPlay = 'ic_play_shape_editor.svg'
                        this.$set(this.listTextToAudio[index], 'iconPlay', 'ic_play_shape_editor.svg')
                        this.listTextToAudio[index].loading_generate = false;
                        this.generatedTtaFinal = true;
                        sessionStorage.setItem("listTextToAudio", JSON.stringify(this.listTextToAudio))
                        this.getHistoryData()
                        localStorage.setItem("generatedTtaFinal", true)
                        this.loadingRegenerate = false;
                        this.getStatusSubscriptions()
                        this.getRuler()
                }
            },

            async populatePromptGeneratedData({ data, reader, formData }) {
                let defaultDetail = [
                    {
                        Name : 'Sovia',
                        PathAvatar : base_url_machine_learning + 'static/template/mp3/asset_sample_languages/Indonesia/Female/avatar/Sovia.png'
                    },
                    {
                        Name : 'Soleh',
                        PathAvatar : base_url_machine_learning + 'static/template/mp3/asset_sample_languages/Indonesia/Male/avatar/Soleh.png'
                    },
                ]

                let index = 0;

                if (
                    this.listTextToAudio.length > 1
                    || this.listTextToAudio[0]?.text.trim() !== ''
                ) {
                    this.listTextToAudio.push({});
                    index = this.listTextToAudio.length - 1;
                }

                this.course_id = data?.course_id || formData.course_id;
                this.listTextToAudio[index].generate_first = false;
                this.listTextToAudio[index].text = data?.text || '';
                this.listTextToAudio[index].length = data?.length || null;
                this.listTextToAudio[index].course_id = data?.course_id || formData.course_id;
                this.listTextToAudio[index].path = data?.path ? base_url_machine_learning + data.path : null;
                this.listTextToAudio[index].audio = null;
                this.listTextToAudio[index].tts_id = data?.tts_id || null;
                this.listTextToAudio[index].iconPlay = 'ic_play_shape_editor.svg';
                this.listTextToAudio[index].isFocused = true;
                this.listTextToAudio[index].characters = this.ai_masterlang.Detail ? this.ai_masterlang.Detail : defaultDetail;
                this.listTextToAudio[index].from_ai = true;
                this.listTextToAudio[index].lang = this.ai_masterlang.lang ? this.ai_masterlang.lang : null;
                this.listTextToAudio[index].emotion = this.ai_masterlang.emotion ? this.ai_masterlang.emotion : 'Normal';
                this.listTextToAudio[index].pitch = 0;
                this.listTextToAudio[index].sound_type = this.ai_masterlang.sound_type ? this.ai_masterlang.sound_type : 'Sovia';
                this.listTextToAudio[index].speed = 1;
                this.listTextToAudio[index].avatar = this.ai_masterlang.avatar ? this.ai_masterlang.avatar : null;

                localStorage.setItem('course_id', data?.course_id || formData.course_id);
                this.getStatusSubscriptions();

                this.showAi();

                this.$refs.audioCardsContainer.scrollTo({
                    top: this.$refs.audioCardsContainer.scrollHeight,
                });

                if (reader) {
                    this.listTextToAudio[index].loading_generate = true;

                    let isReading = true;

                    while (isReading) {
                        const { value, done } = await reader.read();

                        if (done) {
                            isReading = false;
                            break;
                        }

                        if (value) {
                            this.listTextToAudio[index].text += new TextDecoder('utf-8').decode(value).replaceAll('\n', '');
                            this.defaultScriptEditors[index].commands.focus('end');
                        }
                    }

                    this.listTextToAudio[index].loading_generate = false;
                }

                sessionStorage.setItem('listTextToAudio', JSON.stringify(this.listTextToAudio));

                this.promptedText = null
                this.loadingGenerateScript = false
                this.$vs.loading.close()
                this.$refs.inputPrompt.style.height = '20px';
            },

            async handleSentimentFormData() {
                if (sessionStorage.getItem('sentimentFormData')) {
                    const source = await JSON.parse(sessionStorage.getItem('sentimentSource'));
                    const input = sessionStorage.getItem('sentimentInput');
                    const { inputType, index, populateType } = source;

                    if (inputType === 'prompt') {
                        this.promptedText = input;
                        this.scriptType = source.scriptType;
                        this.ai_masterlang = source.AIMasterLang;
                        this.$refs.inputPrompt.focus();
                    } else if (inputType === 'script-default') {
                        this.listTextToAudio[index].path = null;
                        this.listTextToAudio[index].loading_generate = false;
                        this.$nextTick(() => {
                            this.defaultScriptEditors[index].commands.focus('end');
                        });
                    } else if (inputType === 'script-fullscreen') {
                        this.listTextToAudio[index].path = null;
                        this.listTextToAudio[index].loading_generate = false;
                        this.expandCardModal(index);
                        this.$nextTick(() => {
                            this.fullscreenScriptEditor.commands.focus('end');
                        });
                    } else if (inputType === 'ssml-preview') {
                        this.expandCardModal(index);
                        this.$nextTick(() => {
                            this.fullscreenScriptEditor.commands.focus('end');
                        });
                    }

                    if (sessionStorage.getItem('isAgreedSentiment')) {
                        if (inputType === 'prompt') {
                            this.loadingGenerateScript = true;
                        } else if (['script-default', 'script-fullscreen'].includes(inputType)) {
                            this.listTextToAudio[index].loading_generate = true;
                        }

                        const formData = await JSON.parse(sessionStorage.getItem('sentimentFormData'));

                        formData.result = '';
                        formData.status = 'agree';

                        let data = null;

                        try {
                            const res = await getGeneratedTextToAudioWithAgreements(formData);

                            if (res.status === 403) {
                                this.$bvModal.show('audio-quota');
                            } else if (res.status === 402) {
                                this.$bvModal.show('server-error-modal');
                            } else if (res.status === 200) {
                                ({ data } = res);
                            }
                        } catch (error) {
                            console.log(error);
                            this.$bvModal.show('server-error-modal');
                        }

                        if (data !== null) {
                            if (inputType === 'prompt') {
                                this.populatePromptGeneratedData({ data });
                            } else if (['script-default', 'script-fullscreen'].includes(inputType)) {
                                this.populateScriptGeneratedData(data, index, populateType);
                            } else if (inputType === 'ssml-preview') {
                                const SSMLPreviewHistory = await JSON.parse(sessionStorage.getItem('SSMLPreviewHistory')) || [];
                                SSMLPreviewHistory.push(data);
                                sessionStorage.setItem('SSMLPreviewHistory', JSON.stringify(SSMLPreviewHistory));
                            }
                        }

                        if (inputType === 'prompt') {
                            this.loadingGenerateScript = false;
                        } else if (['script-default', 'script-fullscreen'].includes(inputType)) {
                            this.listTextToAudio[index].loading_generate = false;
                        }
                    }

                    this.removeSentimentStorage();
                }
            },

            async handleGeneratePreviewSSML({
                plainText, SSMLText, setAudioSrc, options
            }) {
                const formData = {
                    context: this.scriptType || 'lessons',
                    course_id: this.course_id,
                    params: {
                        speaker: sessionStorage.getItem('SSMLActiveSpeaker'),
                        pitch: this.listTextToAudio[this.index_card_expand].pitch,
                        speed: 1,
                        emotion: this.listTextToAudio[this.index_card_expand].emotion.toLowerCase(),
                        regenerate: true,
                        lang: this.listTextToAudio[this.index_card_expand].lang || '',
                    },
                    text: SSMLText,
                };

                try {
                    const res = await getGeneratedTextToAudioSSML(formData);

                    let path = null;

                    if (res.status === 403) {
                        this.$bvModal.show('audio-quota');
                    } else if (res.status === 402) {
                        this.$bvModal.show('server-error-modal');
                    } else if (res.status === 406) {
                        const { path } = res.data;
                        sessionStorage.setItem('sentimentFormData', JSON.stringify({
                            ...options, plainText, audioSrc: base_url_machine_learning + path
                        }));
                        sessionStorage.setItem('sentimentInput', SSMLText);
                        sessionStorage.setItem('sentimentSource', JSON.stringify({ inputType: `ssml-preview`, index: this.index_card_expand }));
                        sessionStorage.setItem('isAgreedSentiment', false);

                        this.activeInputEditor = null;
                        this.$bvModal.show('sentiment-analysis-warning');
                    } else if (res.status === 200) {
                        ({ path } = res.data);
                    }

                    setAudioSrc(path ? base_url_machine_learning + path : false, this.listTextToAudio[this.index_card_expand].sound_type.toLowerCase());
                } catch (error) {
                    console.log(error);
                    this.$bvModal.show('server-error-modal')
                    setAudioSrc(false);
                }
            },

            handleClickUpgrade() {
                let tta = JSON.parse(sessionStorage.getItem('listTextToAudio'))

                if(tta.length > 0 ){
                    return () => {
                        this.$bvModal.hide('audio-quota');
                        this.$bvModal.show('save-to-draft-tta')
                        this.willRedirectForUpgrade = true;
                    };
                } else {
                    return null;
                }
            },

            handleClickUpgradeTta() {
                let tta = JSON.parse(sessionStorage.getItem('listTextToAudio'))

                if(tta.length > 0 ){
                    this.$bvModal.hide('audio-quota');
                    this.$bvModal.show('save-to-draft-tta')
                    this.willRedirectForUpgrade = true;
                } else {
                    this.$bvModal.hide('audio-quota');
                    this.willRedirectForUpgrade = false;
                }
            },

            handleGenerateAiImage(){
                this.outputImageAi = []
                this.generateImage = true

                let finalFormData = {
                    engine : '1',
                    text : this.formGaim.text
                }

                getImageAiGenerator(finalFormData)
                .then((response) => {
                    if(response.status) {
                        this.outputImageAi = response.data
                        this.generateImage = false
                        this.formGaim.text = ''
                    }
                })
            }
        },

        mounted() {
            this.addIndexAudioInit()
            this.getInitData()
            this.getListMusic()
            this.getHistoryData()
            this.typePlaceholder()
            this.getImageLibrary()
            this.setSideBar();
            // this.getTtaDurations();
            this.getRuler()
            this.setHandlerSection()
            this.getStatusSubscriptions();
            this.checkStatusApi();
            this.getMasterLanguages()
            this.getMasterLanguagesFilter('Indonesia', () => {
                this.defaultLangId = this.masterLanguagesFilter.id;
            });

            $("div.tta-container").parent().addClass("m-0 p-0")
            // $("section.container-page").parent().addClass("pb-0 px-0 p-60")

            this.audio = new Audio()
            this.audio.addEventListener('timeupdate', this.timeUpdate, false);
            this.musicList.forEach(music => {
                music.audio.addEventListener('loadedmetadata', () => {
                    music.duration = music.audio.duration;
                });
            });

            this.getImageSearch();

            if(localStorage.final_path_tta){
                this.finalPath = localStorage.final_path_tta
                const video = this.$refs.videoPlayer;
                if(video != undefined){
                    this.generatedVideoDuration = this.formatTime(video.duration)
                }
            }

            if(localStorage.getItem('generatedTtaFinal')){
                var isBoolean = localStorage.getItem('generatedTtaFinal')
                if(isBoolean == 'false'){
                    this.generatedTtaFinal = false
                } else if(isBoolean == 'true'){
                    this.generatedTtaFinal = true
                } else {
                    this.generatedTtaFinal = localStorage.getItem('generatedTtaFinal')
                }
            }

            $('.inner-equalizer').on('click', function(e) {
                e.stopPropagation();
            });

            document.addEventListener('click', this.hideMenu);

            // Needle function
            document.addEventListener("mouseup", this.stopDrag);
            document.addEventListener("mousemove", this.updatePosition);

            const mode = this.$route.params.mode;

            if (!['draft', 'edit'].includes(mode)) {
                let tta = JSON.parse(sessionStorage.getItem('listTextToAudio'))

                if(tta.length === 1 && tta[0].text === '' && !sessionStorage.getItem('imageArray') && !sessionStorage.getItem('musicArray') && !sessionStorage.getItem('sentimentFormData')){
                    this.$bvModal.show('introduce-ai-speech-editor');
                }

                this.handleSentimentFormData();
            }
        },

        updated() {
            if (this.$refs.containerMusic) {
                this.$refs.containerMusic.addEventListener('scroll', this.onScrollMusic);
            }
        },


        beforeDestroy() {
            document.removeEventListener('click', this.hideMenu);

            //Needle function
            document.removeEventListener("mouseup", this.stopDrag);
            document.removeEventListener("mousemove", this.updatePosition);

            if (this.$refs.containerMusic) {
                this.$refs.containerMusic.removeEventListener('scroll', this.onScrollMusic);
            }

            // this.$refs.containerMusic.removeEventListener('scroll', this.onScrollMusic);
            // window.removeEventListener('scroll', this.onScrollLoadImg);
        },
    }
</script>

<style scoped>
.tta-container {
    height: 100vh;
}
.container-page {
    /* margin-left: -260px; */
    /* min-height: calc(100vh - 120px);
    height: 100%; */
    height: 99vh;
}
.content-container {
    background-color: #0D0D0D;
    border-radius: 10px;
    width: fit-content;
}
.prompt-text {
    /* width: 30vw; */
}
.preview {
    width: 50vw;
}
.prompt-text textarea {
    width: 100%;
    height: 30vh;
    color: #8C8C8C;
    background-color: transparent;
    border-radius: 7px;
}
textarea {
    resize: none;
}
.btn-voice-gender {
    border: 2px solid #8C8C8C;
    border-radius: 50px;
}
.btn-voice-gender p {
    color: #8C8C8C;
}
.btn-active {
    background-color: #6D75F6 !important;
    /* border: 2px solid #6D75F6; */
}
.btn-active p {
    color: white !important;
}

.btn-lang {
    width: 100%;
    border: 1px solid #2D2D2D;
    background-color:transparent;
    color: white;
    padding: 10px !important;
}

.btn-language {
    padding: 0 0.5rem;
    height: 28px;
    color: white;
    display: flex;
    align-items: center;
    border: 1px solid #2D2D2D;
    border-radius: 18px;
}

.btn-language--modal {
    padding: 0 0.75rem;
    height: 35px;
}
.btn-language-options {
    width: fit-content;
}

.btn-expand {
    width: 26px;
    height: 26px;
    background-color: #2d2d2d;
    color: #fff;
}

.btn-expand--modal {
    width: 30px;
    height: 30px;
    font-size: 1.25rem;
}

.btn-generate-card {
    width: 26px;
    height: 26px;
    background-color: #6D75F6;
    color: #fff;
}

.btn-generate-card--modal {
    width: 30px;
    height: 30px;
    font-size: 1.25rem;
}

.dropdown-menu{
    background-color: #2D2D2D;
    min-width: 5rem;
    box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.75);
}
/* .dropdown-menu-video{
    background-color: #1F1F1F;
    min-width: 5rem;
} */

.select-lang {
    width: 100% !important;
    padding: 10px 10px;
    height: 18rem;
}

.btn-generate {
    color: white;
    background-color: #6D75F6;
    border: none;
    border-radius: 5px;
}
.divider {
    border-left: 2px solid #2D2D2D;
    height: 70vh;
    margin-left: 5rem;
    margin-right: 5rem;
}
.script-card {
    width: 35vw;
    background: #1F1F1F;
    border-radius: 6px;
}
.inner-script-card {
    background-color: #2D2D2D;
    border-radius: 6px;
}
.inner-script-card p {
    color: #8C8C8C;
}
.audio-player {
    width: 35vw;
    background-image: url('../../../../assets/images/bg-cover-quiz.svg');
    height: 25vh;
    position: relative;
    border-radius: 10px;
}
.audio-player audio {
    position: absolute;
    bottom: 10px;
    width: 90%;
    height: 40px;
}
audio::-webkit-media-controls-panel {
    width: 100%;
}
.no-preview {
    color: #8C8C8C;
}
.btn-edit, .btn-delete {
    background-color: #2D2D2D;
    border-radius: 4px;
}

.form-control {
    background-color: #222222;
    color: white;
    border: 0;
}

textarea{
    outline: none;
}

textarea.form-control {
    background-color: transparent !important;
    box-shadow: none;
    outline: none;
}

textarea.form-control::-webkit-scrollbar {
    display: none;
}

.input-generator {
    border: 2px solid #6D75F6 !important;
    height: 50px;
    border-radius: 3px;
}

.input-generator-container{
    min-height: 20px;
    box-sizing: border-box;
    padding: 12px 18px 12px;
    /* margin: 5px 10px; */
    background-color: #1F1F1F;
    border: 2px solid #6D75F6 !important;
    box-shadow: 0px 0px 21px 10px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 21px 10px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 21px 10px rgba(0,0,0,0.75);
    border-radius: 8px;
}

.w-input-text-group {
  position: relative;
}

.w-placeholder {
  color: rgb(99, 99, 99);
  top: 0;
  pointer-events: none;
  user-select: none;
  position: absolute;
  opacity: 1;
  transition: 0.2s padding ease-in-out;
}

#w-input-text {
  overflow-x: hidden;
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  z-index: 1;
  height: 20px;
  padding: 0 0 0 2px;
  outline: 0;
  transition: 0.2s padding ease-in-out;
}

.border-separator {
    border-right: 0.1px solid #2D2D2D;
}

.border-separator::-webkit-scrollbar {
    display: none;
}

.btn-purple {
    background-color: #6D75F6;
    border: 1px solid #6D75F6;
    color: white;
}

.section-timeline::-webkit-scrollbar {
    display: none;
}
.bg-transparent {
    background : transparent;
    color: #2D2D2D;
    width: auto;
    /* min-width: 100px; */
}

.section-function .timer{
    font-size: 10px;
    color: white;
}

.card {
    border-radius: 6px;
}

.bg-played {
    background-color: #2D2D2D;
}

.numbers {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  color: white;
}

.btn-add {
    background-color: #2D2D2D;
    border: 1px soolid #2D2D2D;
}

.ic-actions{
    width: 14px;
}

.ic-equalizer{
    width: 14px;
}

.gallery {
    /* max-height: 28rem; */
}

.gallery-img {
    border-radius: 5px 5px 5px 5px;
    /* width: 123.74px;
    height: 108.89px; */
}

.btn-thumbnail{
    background-color: transparent;
    border: none;
}

.image-selected {
    border: 3px solid #BC3BF2;
    border-radius: 10px;
}

.btn-thumbnail:focus img {
    /* border: 3px solid #BC3BF2;
    border-radius: 10px;

    -webkit-border: 3px solid #BC3BF2;
    -webkit-border-radius: 10px; */
}

.search-bar {
    height: 3rem;
    width: 100%;
    border-radius: 25px;
    border: 1px solid #2D2D2D;
    padding-left: 48px !important;
    background-color: transparent;
    padding-right: 20px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
    color: white;
}

.search-bar-voice {
    height: 2.5rem;
    width: 100%;
    border-radius:6px;
    border: 1px solid #2D2D2D;
    background-color:#2D2D2D;
    padding-left: 45px !important;
    padding-right: 20px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
}

.container-svm label {
    position: relative;
    width: 100%;
    height: 40px;
    border-radius: 25px !important;
    z-index: 2;
}

.container-svm label:before {
    content: "";
    position: absolute;
    left: 0.5rem;
    top: 4px;
    bottom: 0;
    width: 40px;
    background: url("~@/assets/images/search-icon.svg") center / contain no-repeat;
    background-size: 20px;
}

.gallery label {
    position: relative;
    width: 100%;
    height: 40px;
    border-radius: 25px !important;
    z-index: 2;
}

.gallery label:before {
    content: "";
    position: absolute;
    left: 1rem;
    top: 10px;
    bottom: 0;
    width: 40px;
    background: url("~@/assets/images/search-icon.svg") center / contain no-repeat;
    background-size: 20px;
}

.btn-impor-custom{
    border : 2px dashed #2D2D2D;
    border-radius: 10px;
}

.upload-img, .gaim {
    height: 28rem;
}

.upload-img .btn-impor-custom {
    height : 10rem;
}

.upload-script .btn-impor-custom {
    height : 20rem;
}

.container-music{
    max-height: 30rem;
    padding-right: 1em;
}

.past-upload {
    height: 15rem;
}

.container-thumbnail, .btn-impor-custom{
    height: 25rem;
}

.container-thumbnail::-webkit-scrollbar, .container-music::-webkit-scrollbar , .past-upload::-webkit-scrollbar, .select-lang::-webkit-scrollbar{
    width: 6px;
}

        /* Track */
.container-thumbnail::-webkit-scrollbar-track, .container-music::-webkit-scrollbar-track , .past-upload::-webkit-scrollbar-track, .select-lang::-webkit-scrollbar-track{
    background: #636363;
    border-radius: 4px;
}

        /* Handle */
.container-thumbnail::-webkit-scrollbar-thumb, .container-music::-webkit-scrollbar-thumb , .past-upload::-webkit-scrollbar-thumb, .select-lang::-webkit-scrollbar-thumb{
    background: #B6B6B6;
    border-radius: 4px;
    background-clip: padding-box;
}

        /* Handle on hover */
.container-thumbnail::-webkit-scrollbar-thumb:hover, .container-music::-webkit-scrollbar-thumb:hover, .past-upload::-webkit-scrollbar-thumb:hover , .select-lang::-webkit-scrollbar-thumb:hover{
    background: #888;
}

.close-image{
    right : 5px;
    z-index: 100;
}

.btn-grey{
    background-color: #2D2D2D !important;
    color: white;
    border: 0px !important;
    transition: background-color 0.5s;
}

.btn-grey:hover, .btn-expand:hover, .btn-add:hover, .btn-equalizer:hover, .pill-timeline:hover, .inner-float:hover, .btn-language:hover {
    background-color: #414141 !important;
}

.btn-close{
    background-color: #EDEDED;
    border-radius: 50%;
    width: 15px;
    height: 26px;
}

.img-form {
    width: 100px;
    height: 100px;
}

.card-music {
    background-color: #2D2D2D;
}
.card-music-upload {
    background-color: #2D2D2D;
    border-radius: 7px;
}

.card-music:hover {
    border: 1px solid #6D75F6;
}

.card-music:active {
    border: 1px solid #6D75F6;
}

.selected-music {
    border: 2px solid #6D75F6;
}

.line-slider {
    width: 1px;
    height: 100%;
    background-color: #DF2723;
    position: absolute;
    top: 0;
    transition: left 0.2s ease-out;
    z-index: 2;
    /* cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab; */
}

.line-slider:active {
    /* cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing; */
}

.line-slider:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #DF2723;
    position: absolute;
    bottom: 97%;
    left: 51%;
    transform: translateX(-50%);
}

.sticky-section{
    border-right: 1px solid rgb(45, 45, 45);
    position: sticky;
    background-color: black;
    /* width: 4036.8px; */
    width: 50px !important;
    z-index: 3;
}

.inner-equalizer input[type=range][orient=vertical]
{
    writing-mode: bt-lr; /* IE */
    -webkit-appearance: slider-vertical; /* Chromium */
    width: 8px;
    padding: 0 5px;
}

.btn-equalizer {
    box-shadow: none;
}

.btn-equalizer:focus {
    box-shadow: 0px 0px 1px 2px #2D2D2D;
}

.context-menu {
    position: absolute;
    list-style-type: none;
    color: white;
    padding: 5px;
    margin: 0;
    background-color: #2d2d2d;
    border-radius: 6px;
}

.hr-vertical{
    border-left: 1px solid #2D2D2D;
}

.title-filter{
    color: #8C8C8C;
}

.btn-clearall{
    color: #6D75F6;
}

.context-menu li:hover {
    background-color: #8c8c8c;
    border-radius: 3px;
}


.context-menu li {
    list-style-type: none;
    cursor: pointer;
    padding: 5px 10px;
}

.spin-load {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 2000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 2000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 2000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.powered-by{
    font-size: 12px;
}

.sub-text {
    font-size: 16px;
}

.btn-purple-br {
    color: white;
    background-color: #6D75F6;
    border: 1px solid #6D75F6;
    height: 2.5rem;
    border-radius: 25px;
    font-size: 12px;
}

.btn-grey-br {
    background-color: transparent;
    border: 1px solid #2D2D2D;
    color: #8C8C8C;
    height: 2.5rem;
    border-radius: 25px;
    font-size: 12px;
}

.float-ai, .card-ai-generator {
    left: 0;
    bottom: 245px;
    left: 0;
}

.float-ai-active-timeline {
    bottom: 90px !important;
}

.card-ai-generator {
    margin-left: 96px;
}

.inner-float {
    width: 40px;
    margin-left: 15px;
    height: 40px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    background-color: #2D2D2D;
    justify-content: center;
    transition: background-color 0.5s;
    color: #fff;
}

.inner-float img {
    width: 22px;
}

.btn-transparent{
    color: white !important;
    background-color: transparent !important;
    box-shadow: none;
}

.btn-generate {
    color: white;
    font-size: 14px;
    font-weight: 600;
    background-color: #2D2D2D;
}

.dropdown span, .dropdown-menu span {
    font-size: 12px;
}

.dropdown .btn {
    box-shadow: none !important;
}

.pill-timeline {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    padding: 0 0.7rem 0 0.7rem;
    width: auto;
    border-radius: 25px;
    background-color: #2D2D2D;
    color: white;
    cursor: pointer;
    transition: background-color 0.5s;
}

.pill-timeline span {
    font-size: 10px;
}

.rotate-arrow {
    transform: rotate(-180deg);
}

.past-upload .card {
    border-radius: 8px;
    background-color: #2D2D2D;
}

.past-upload .card img{
    height: 109px;
    border-radius: 8px 8px 0px 0px !important;
}

.selected-img {
    top: 10px;
    right: 10px;
    width: 11px;
    height: 11px !important;
}

.separator-bottom {
    border-bottom: 0.1px solid #2D2D2D;
}

.ratio-image:hover{
    border: 2px solid #6D75F6;
    border-radius: 12px;
}

.ratio-image__active {
    border: 2px solid #6D75F6;
    border-radius: 12px;
}

.range-audio{
    background: linear-gradient(to right, #6F52ED 50%, #FFFFFF 50%);

}

.btn-disable {
    background-color: #2D2D2D;
    border: 1px solid #2D2D2D;
    color: #585859;
}

.bottom-note {
    position: absolute;
    bottom: 0;
    height: auto;
    width: 100%;
    background-color: #6D75F61A;
}

.separator__bottom {
    border-bottom: 0.1px solid #2D2D2D;
}

.card-avatar {
    background-color: #2D2D2D;
}

.card-avatar__single {
    max-width: 8.9rem;
}

.card-avatar__button-audio {
    width: 23px;
    height: 23px;
    border-radius: 3px !important;
    background-color: white;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-avatar__button-select {
    height: 23px;
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px !important;
    font-size : 12px;
}

.container-list__script {
    height: 21rem;
    overflow: auto;
}

.container-list__script span {
    font-size: 12px;
    color: #8C8C8C;
}

.container-list__script__char {
    width: 101px;
    height: 28px;
    border-radius: 20px;
    background-color: #3C3C3C;
    display: flex;
    align-items: center;
}

.progress__upload .progress-bar {
    background-color: #6D75F6;
}

.add-script-option {
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-script-option__button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #2D2D2D;
    color: white;
    border-radius: 17px;
    padding: 5px 15px;
}

.btn-selected {
    background-color: #585859;
    color: white;
    border: 1px solid #585859;
    box-shadow: none;
}

.button__audio {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    background-color: white;
    border: none;
    color: black;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
}

.button__audio:hover {
    background-color: #b5b5b5;
}

.button__backward-skip{
    border-radius: 50%;
    width: 28px;
    height: 28px;
    background-color: transparent;
    border: none;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
}

.button__backward-skip:hover {
    background-color: #414141 !important;
}

.prompt__button--active {
  color: #6D75F6;
}

.ac__settings {
  display: grid;
  grid-template-columns: 70px 15px auto;
  gap: 8px 0;
  background-color: #2D2D2D;
  border-radius: 8px;
  font-size: 12px;
}

.ac__settings-section {
  display: contents;
}

.ac__settings-list {
  gap: 6px;
}

.ac__settings-button {
  gap: 4px;
  background-color: #414141;
  border-radius: 4px;
  font-size: 12px;
  transition: background-color 0.25s;
}

.ac__settings-button:not(.ac__settings-button--active):hover {
  background-color: #515151;
}

.ac__settings-button--active {
  background-color: #6D75F6;
}

.ac__settings-button--resolution {
  min-width: 50px;
}

.ac__settings-button--ratio {
  min-width: 50px;
}

.ac__settings-ratio-box {
  height: 12px;
  border-radius: 2px;
  border: 1px solid #FFFFFF;
}

.ac__settings-button--output {
  min-width: 40px;
}

.agi--text-info {
    font-size: 14px;
    color: white;
    font-weight: 400;
}

.agi--list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  padding-right: 24px;
  margin-right: 18px;
  overflow-y: auto;
}

.agi__character-button {
  width: 100%;
  height: 125px;
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid transparent;
  transition: background-color 0.25s, border 0.25s;
}
.agi__character-button--selectable:not(
  .agi__character-button--selected
):hover {
  border-color: #6D75F655;
}

.agi__character-button--selected {
  border-color: #6D75F6;
}

.agi__character-button-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
</style>

<style>
    .card-img-top {
        border-radius: 5px;
    }
    .vs-checkbox {
        -webkit-transition: all .2s ease;
        transition: all .2s ease;
        cursor: pointer;
        position: relative;
        display: block;
        width: 15px;
        height: 15px;
        border-radius: 2px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        overflow: hidden;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 4px;
    }
    .vs-checkbox--check .vs-icon, .vs-input--icon {
        font-size: 0.8rem;
    }
    .section-editor {
        /* width: 100%; */
        border: 1px solid #2D2D2D;
        border-right: 0;
        border-left: 0;
        position: relative;
        bottom: 0;
        left: 0;
    }
    .section-editor .bg-white {
        min-width: 100px;
    }
    .section-editor .section-second {
        border-right: 1px solid black;
    }
    .section-layer {
        border-bottom: 1px solid #2D2D2D;
        padding-top: 5px;
        padding-bottom: 5px;
        min-height: 50px;
    }

    .section-ruler {
        border-bottom: 1px solid #2D2D2D;
    }

    #resizable-tta, #resizable-music, #resizable-image {
        border-radius: 5px;
        box-shadow: inset 0 0 0 1px #2D2D2D;
        display: flex;
        overflow-x: hidden;
        position: relative;
        align-items: flex-end;
    }

    #resizable-image {
        max-height: 50px;
        background-repeat: repeat-x;
    }

    :is(.list-audio, .list-option--modal) .dropdown-menu {
        background: #2D2D2D;
        box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.55);
        -webkit-box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.55);
        -moz-box-shadow: 0px 0px 26px 5px rgba(0,0,0,0.55);
    }

    :is(.list-audio, .list-option--modal) .dropdown-item {
        background-color: transparent;
        color: white;
    }

    :is(.list-audio, .list-option--modal) .dropdown-item:hover {
        background-color: #8C8C8C;
    }

    .list-audio .list-option{
        opacity: 1;
        transition: opacity 0.2s ease-in-out;
    }

    .list-audio .list-option-hidden{
        opacity: 0;
    }

    #upload-image-editor .modal-content, #upload-music-editor .modal-content, #select-voice-modal .modal-content, #expanded-card-modal .modal-content, #import-script-modal .modal-content {
        background-color: #1F1F1F;
        color: white;
    }

    .progress .bg-primary {
        background-color: #6D75F6 !important;
    }

    #resizable-music:hover, #resizable-image:hover {
        border-left: 2px solid #6D75F6;
        border-right: 5px solid #6D75F6;
        border-top: 2px solid #6D75F6;
        border-bottom: 2px solid #6D75F6;
        border-radius: 4px;
        overflow: hidden;
    }

    #resizable-music:active, #resizable-image:active {
        border-left: 2px solid #6D75F6;
        border-right: 5px solid #6D75F6;
        border-top: 2px solid #6D75F6;
        border-bottom: 2px solid #6D75F6;
        border-radius: 4px;
        overflow: hidden;
    }

    .p-60 {
        padding-top: 60px !important;
    }

    .list-dropdown-ai-generator .btn-secondary {
        padding: 0;
        background-color: transparent;
        border: 0;
        box-shadow: none !important;
    }

    .list-dropdown-ai-generator .btn-secondary:hover {
        background-color: transparent !important;
        border-color: transparent !important;
    }

    .list-dropdown-ai-generator .show > .btn-secondary.dropdown-toggle {
        color: #fff;
        background-color: transparent !important;
        border-color: transparent !important;
    }

    .list-dropdown-ai-generator .dropdown-menu {
        background: #2D2D2D;
    }

    .list-dropdown-ai-generator .dropdown-item {
        color: white;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .list-dropdown-ai-generator .dropdown-item:hover {
        background-color: #8C8C8C;
    }

    #delete-audio-tta .modal-content {
        background-color: #1F1F1F;
        color: white;
        border-radius: 15.0499px;
    }

    li.cursor-disabled-item a{
        cursor: not-allowed !important;
    }

    .container-svm .b-skeleton-avatar {
        width: 1.5em !important;
        height: 1.5em !important;
    }

    .vue-slider-process, .vue-slider-process:hover{
        background-color: #6D75F6 !important;
    }

    #import-script-modal .modal-lg {
        max-width: 1000px;
    }

    .upload-image-editor-modal .vs-tabs--li:nth-child(2) button {
        background-image:
            url('~@/assets/images/icons/ic_generator_star_default.svg'),
            url('~@/assets/images/icons/ic_generator_star_default.svg');
        background-repeat: no-repeat;
        background-position: left 13%, right 68%;
        background-size: 14px, 8px;
    }

    .upload-image-editor-modal .vs-tabs--li:nth-child(2).activeChild button {
        background-image:
            url('~@/assets/images/icons/ic_generator_star_active.svg'),
            url('~@/assets/images/icons/ic_generator_star_active.svg');
        background-position: left 8%, right 63%;
    }
</style>