<template>
    <div>
        <b-modal id="save-to-draft-tta" centered no-close-on-backdrop header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm">
            <div class="float-right">
                <button class="btn d-flex justify-content-center align-items-center pr-0" @click="close()"><img src="@/assets/images/icons/ic_close.svg" alt=""></button>
            </div>
            <div class="mt-5 text-center mb-3">
                <img src="@/assets/images/icons/modal_warning.svg" alt="Save to Draft">
                <div class="mt-3">
                    <h5 class="font-weight-bold mb-2">{{ $t('wait') }}</h5>
                    <div class="d-flex flex-column">
                        <span class="">{{ $t('your-content-almost-finished') }}</span>
                        <span v-if="$route.params.mode == 'new'" class="">{{ $t('save-it-as-draft') }}</span>
                        <span v-else class="">Do you want to save changes?</span>
                    </div>
                </div>
                <div v-if="$route.params.mode == 'new'" class="d-flex justify-content-center mt-3">
                    <button class="btn btn-secondary text-white mr-2" @click="cancel()"><p class="mb-0 font-weight-bold">{{ $t('delete') }}</p></button>
                    <button class="btn btn-primary btn-purple-nb" @click="saveDraft()"><p class="mx-2 mb-0 font-weight-bold">{{ $t('save-to-draft') }}</p></button>
                </div>
                <div v-else class="d-flex justify-content-center mt-3">
                    <button class="btn btn-secondary text-white mr-2" @click="cancel()"><p class="mb-0 font-weight-bold">Cancel</p></button>
                    <button class="btn btn-primary btn-purple-nb" @click="saveChange()"><p class="mx-2 mb-0 font-weight-bold">Save Changes</p></button>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { postContents, updateContent } from "@/services/contents/contents.service";

export default{
    data(){
        return {
            isUpgade: this.redirectUpgrade
        }
    },

    props: {
        redirectUpgrade: {
            type: Boolean,
            default: false
        }
    },

    watch: {
        redirectUpgrade(newVal) {
            this.isUpgade = newVal
        }
    },

    methods: {
        cancel(){
            this.removeDataStorage()
            this.$emit('cancel')
            if(this.isUpgade){
                this.$router.push({ name: 'TopUp' });
            } else {
                this.$router.push({ name : 'My-Quiz' })
            }
        },

        saveDraft(){
            let content_name = "Draft"
            let content_file = ""
            let image_thumbnail = null
            let imageArray = "[]"
            let audioArray = "[]"
            let musicArray = "[]"
            let volume_music = 0.35
            let volume_audio = 1
            let job_id = null
            let ratio = ''

            if(localStorage.getItem('content_name_tta')) {
                content_name = localStorage.getItem('content_name_tta')
            }

            if(localStorage.getItem('final_path_tta')) {
                content_file = localStorage.getItem('final_path_tta')
            }

            if(localStorage.getItem('image_thumbnail_tta')) {
                image_thumbnail = localStorage.getItem('image_thumbnail_tta')
            }

            if(sessionStorage.getItem('imageArray')) {
                imageArray = sessionStorage.getItem('imageArray')
            }

            if(sessionStorage.getItem('listTextToAudio')) {
                audioArray = sessionStorage.getItem('listTextToAudio')
            }

            if(sessionStorage.getItem('musicArray')) {
                musicArray = sessionStorage.getItem('musicArray')
            }

            if(localStorage.getItem('volume_music_tta')){
                volume_music = parseFloat(localStorage.getItem('volume_music_tta'))
            }

            if(localStorage.getItem('volume_video_tta')){
                volume_audio = parseFloat(localStorage.getItem('volume_video_tta'))
            }

            if(localStorage.getItem('jobs_id_tta')){
                job_id = localStorage.getItem('jobs_id_tta')
            }

            if(localStorage.getItem("ratio_tta")) {
                ratio = localStorage.getItem("ratio_tta")
            }

            let formData = {
               "content_type": "audio",
               "content_name": content_name,
               "content_file": content_file,
               "image_thumbnail" : image_thumbnail,
               "image_array": imageArray,
               "audio_array": audioArray,
               "music_array": musicArray,
               "volume_music" : volume_music,
               "volume_audio" : volume_audio,
               "job_id" : job_id,
               "ratio" : ratio,
               "is_draft" : true
            }

            postContents(formData)
            .then((response) => {
                if(response.status == true){
                    this.removeDataStorage()
                    if(this.isUpgade){
                        this.$router.push({ name: 'TopUp' });
                    } else {
                        this.$router.push({ name : 'My-Quiz' })
                    }
                } else {
                    this.removeDataStorage()
                    if(this.isUpgade){
                        this.$router.push({ name: 'TopUp' });
                    } else {
                        this.$router.push({ name : 'My-Quiz' })
                    }
                }
            })
        },

        saveChange(){
            let mode = this.$route.params.mode
            let id = this.$route.params.id

              let is_draft = true
              if(mode === 'edit'){is_draft = false}

              let content_name = "Draft"
              let content_file = ""
              let image_thumbnail = null
              let generate_tta_final = true
              let imageArray = "[]"
              let audioArray = "[]"
              let musicArray = "[]"
              let volume_music = 0.35
              let volume_audio = 1
              let ratio = ''

              if(localStorage.getItem('content_name_tta')) {
                content_name = localStorage.getItem('content_name_tta')
              }
              if(localStorage.getItem('final_path_tta')) {
                content_file = localStorage.getItem('final_path_tta')
              }
              if(localStorage.getItem('image_thumbnail_tta')) {
                image_thumbnail = localStorage.getItem('image_thumbnail_tta')
              }
              if(sessionStorage.getItem('imageArray')) {
                imageArray = sessionStorage.getItem('imageArray')
              }
              if(sessionStorage.getItem('listTextToAudio')) {
                audioArray = sessionStorage.getItem('listTextToAudio')
              }
              if(sessionStorage.getItem('musicArray')) {
                musicArray = sessionStorage.getItem('musicArray')
              }
              if(localStorage.getItem('generatedTtaFinal')) {
                generate_tta_final = localStorage.getItem('generatedTtaFinal')
              }

              if(localStorage.getItem('volume_music_tta')){
                volume_music = parseFloat(localStorage.getItem('volume_music_tta'))
              }

              if(localStorage.getItem('volume_video_tta')){
                volume_audio = parseFloat(localStorage.getItem('volume_video_tta'))
              }

              if(localStorage.getItem('ratio_tta')) {
                ratio = localStorage.getItem('ratio_tta')
              }

              let formData = {
                "content_type": "audio",
                "content_name": content_name,
                "content_file": content_file,
                "image_thumbnail" : image_thumbnail,
                "image_array": imageArray,
                "audio_array": audioArray,
                "music_array": musicArray,
                "generate_tta_final": generate_tta_final,
                "is_draft" : is_draft,
                "volume_music" : volume_music,
                "volume_audio" : volume_audio,
                "ratio" : ratio
            }

            updateContent(formData, id)
            .then((response) => {
                if(response.status == true){
                    this.removeDataStorage()
                    if(this.isUpgade){
                        this.$router.push({ name: 'TopUp' });
                    } else {
                        this.$router.push({ name : 'My-Quiz' })
                    }
                } else {
                    this.removeDataStorage()
                    if(this.isUpgade){
                        this.$router.push({ name: 'TopUp' });
                    } else {
                        this.$router.push({ name : 'My-Quiz' })
                    }
                }
            })
        },

        removeDataStorage(){
            sessionStorage.removeItem("imageArray")
            sessionStorage.removeItem("listTextToAudio")
            sessionStorage.removeItem("musicArray")
            localStorage.removeItem("final_path_tta")
            localStorage.removeItem("image_thumbnail_tta")
            localStorage.removeItem("content_name_tta")
            localStorage.removeItem("generatedTtaFinal")
            localStorage.removeItem("course_id")
            localStorage.removeItem("volume_music_tta")
            localStorage.removeItem("volume_video_tta")
            localStorage.removeItem("jobs_id_tta")
            localStorage.removeItem("ratio_tta")
            sessionStorage.removeItem('sentimentFormData')
            sessionStorage.removeItem('sentimentInput')
            sessionStorage.removeItem('sentimentSource')
            sessionStorage.removeItem('isAgreedSentiment')
            sessionStorage.removeItem('SSMLPreviewHistory')
            sessionStorage.removeItem('SSMLActiveSpeaker');
        },

        close(){
            history.pushState(null, null, location.href);
            this.$emit('cancel')
            this.$bvModal.hide('save-to-draft-tta')
        }
    }
}
</script>
<style>
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#save-to-draft-tta .modal-content{
    background-color: #222222;
     font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#save-to-draft-tta .modal-content {
    background-color: #1F1F1F;
    color: white;
    border-radius: 15.0499px;
}
.btn-primary{
    background-color: #6D75F6;
    border: none;
}
.btn-primary:hover{
    background-color: #6D75F6;
    /* border: none; */
}
.btn-secondary{
    background-color: #2D2D2D;
    border: #2D2D2D;
    color: #ffffff;
}
.btn-secondary:hover{
    background-color: #2D2D2D;
    border: #2D2D2D;
    color: #ffffff;
}
</style>
<style scoped>
button, .btn{
    box-shadow: none;
}
</style>