<template>
    <header class="gridx">
        <vs-navbar
        class="topnavbar"
        text-color="rgba(255,255,255,0.7)"
        active-text-color="rgba(255,255,255,1)"
        >
        <div class="d-flex align-items-center justify-content-between w-100">
            <div class="d-flex align-items-center pl-4 cursor-pointer" @click="back">
                <img src="@/assets/images/icons/ic_back_nav.svg" alt="back" class="mr-2" style="width:10px;">
                <h5 v-if="$route.name == 'Generate-Text-To-Audio'" class="font-weight-normal ml-2"> Text to Audio</h5>
                <h5 v-else class="font-weight-normal ml-2"> Text to Video</h5>
            </div>
            <div class="d-flex align-items-center justify-content-center">
              <label for="name" class="position-relative" style="width:250px">
                <input type="text" v-model="content_name" class="form-control ellipsis" autocomplete="off" placeholder="File Name" id="input-name">
                <img src="@/assets/images/icons/ic_penci_fn.svg" alt="Pencil" class="position-absolute ic-pencil" :style="content_name != null && content_name != '' ? 'display:none;' : ''">
              </label>
            </div>
            <div v-if="$route.name == 'Generate-Text-To-Audio'" class="pr-4 d-flex align-items-center justify-content-end">
              <div class="dropdown information mr-1">
                <img src="@/assets/images/icons/ic_info_navtt.svg" alt="information" width="18">
                <div class="dropdown-menu tool-tip">
                  <div class="container">
                    <div class="row ml-1">
                      <span>{{ status_subscriptions.audio_generates_quota }} minutes duration left. </span>
                      <span>Top Up to get more audio duration.</span>
                    </div>
                  </div>
                </div>
              </div>
                <!-- <img src="@/assets/images/icons/ic_info_navtt.svg" alt="Info"> -->
                <h6 class="mr-3 ml-2 font-weight-normal">{{ status_subscriptions.audio_generates_quota }} mins</h6>
                <!-- <div v-else class="mr-3 w-100 d-flex align-items-center">
                  <b-skeleton animation="wave" width="100%"></b-skeleton> <span class="mx-1">/</span><b-skeleton animation="wave" width="100%"></b-skeleton>
                </div> -->
                <button v-if="generatedAudioContent" class="btn btn-fill-purple mr-2 d-flex align-items-center" @click="$bvModal.show('export-tta-modal')">
                  <img src="@/assets/images/icons/ic_export_tta.svg" alt="" class="mr-2" width="16">
                  <span>Export</span>
                </button>
                <button v-else class="btn btn-fill-dark mr-2 d-flex align-items-center">
                  <img src="@/assets/images/icons/ic_export_tta_na.svg" alt="" class="mr-2" width="16">
                  <span>Export</span>
                </button>
                <button v-if="generatedAudioContent" class="btn btn-purple text-nowrap" @click="publish"><span class="mx-2">Save</span></button>
                <button v-else class="btn btn-dark text-nowrap" style="color:#585859 !important;"><span class="mx-2">Save</span></button>
                <!-- <button v-else class="btn btn-success disabled">Publish</button> -->
            </div>
        </div>
        </vs-navbar>
        <SaveToDraftTTA :redirect-upgrade="isUpgrade" @cancel="$emit('cancel')"/>

        <!-- EXPORT MODAL -->
        <b-modal id="export-tta-modal" centered no-close-on-backdrop header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="md">
          <div class="container-fluid my-3">
            <div class="d-flex align-items-center justify-content-between">
              <h4>{{ $t('export-share-content') }}</h4>
              <button class="btn btn-close d-flex justify-content-center align-items-center close-image" @click="closeExport"><b-icon icon="x"></b-icon></button>
            </div>
            <div class="d-flex align-items-center justify-content-between mt-4">
              <div class="card card-export">
                <div class="card-body px-3 text-center">
                  <div>
                    <img src="@/assets/images/icons/export_mp3r.svg" alt="" class="mt-2">
                    <p class="mb-0 mt-1">Audio only</p>
                  </div>
                  <button v-if="!loadingMp3" class="btn btn-purple-export btn-block mt-5 d-flex align-items-center justify-content-center" @click="downloadVideomp3"> <span style="font-size:12px;">Download</span></button>
                  <button v-else class="btn btn-purple-export btn-block mt-5 d-flex align-items-center justify-content-center">
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                </div>
              </div>
              <div class="card card-export mx-2">
                <div class="card-body px-3 text-center">
                  <div>
                    <img src="@/assets/images/icons/export_mp4r.svg" alt="" class="mt-2">
                    <p class="mb-0 mt-1">Video Format</p>
                  </div>
                  <button v-if="!loadingMp4" class="btn btn-purple-export btn-block mt-5 d-flex align-items-center justify-content-center" @click="downloadVideomp4"> <span style="font-size:12px;">Download</span></button>
                  <button v-else class="btn btn-purple-export btn-block mt-5 d-flex align-items-center justify-content-center">
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                </div>
              </div>
              <div class="card card-export">
                <div class="card-body px-3 text-center">
                  <div>
                    <img src="@/assets/images/icons/export_copylink.svg" alt="" class="mt-2">
                    <p class="mb-0 mt-1">Share Link</p>
                  </div>
                  <button v-if="!loadingCopyLink" class="btn btn-purple-export btn-block mt-5 d-flex align-items-center justify-content-center" @click="copyLink"> <span style="font-size:12px;">Copy Link</span></button>
                  <button v-else class="btn btn-purple-export btn-block mt-5 d-flex align-items-center justify-content-center">
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-modal>

    </header>
</template>

<script>
import { mapGetters } from "vuex";
import { updateContent, postContents } from "@/services/contents/contents.service";
import SaveToDraftTTA from '@/components/modal/SaveToDraftTTA.vue'
import { getDataContent } from "@/services/contents/contents.service";
import { AlertUtils } from "@/mixins/AlertUtils";
import { exportUtils } from "@/mixins/ExportUtils";
import { downloadAudio } from "@/services/audio/audio.service"
import { base_url_machine_learning, base_url_home } from "../../config/base_url";

import axios from "axios"


export default {
  mixins: [AlertUtils, exportUtils],
  components : {
    SaveToDraftTTA,
  },

  props :{
    status_subscriptions: Object,
    loading_subscriptions: Boolean,
    redirectUpgrade: {
      type: Boolean,
      default: false
    }
  },

  // beforeRouteLeave(to, from, next) {
  //   this.back()
  //   next();
  // },

  data() {
    return {
      final_path_tta : false,
      loadingMp3: false,
      loadingMp4: false,
      loadingCopyLink: false,
      content_name : null,
      isUpgrade: this.redirectUpgrade,
      link : null
    }
  },

  mounted(){
    if(localStorage.final_path_tta){
      this.$store.state.generatedAudioContent = true;
    }

    if(this.$route.name == 'Generate-Text-To-Audio'){
      this.getInitDataTTA()
    }

    window.addEventListener('popstate', this.popStateBack);
  },

  watch: {
    content_name(val){
      if(this.$route.name == 'Generate-Text-To-Audio'){
        if(val != undefined){
          localStorage.content_name_tta = val
        }
      } else {
        localStorage.content_name_ttv = val
      }
    },
    redirectUpgrade(newVal) {
      this.isUpgrade = newVal
    }
  },

  computed: {
    ...mapGetters({
      generatedAudioContent : 'generatedAudioContent'
    }),
  },


  methods : {
    publish(){
      let mode = this.$route.params.mode
      let id = this.$route.params.id
      let content_name = "Content Audio"

      if(localStorage.getItem('content_name_tta')) {
          content_name = localStorage.getItem('content_name_tta')
      }

      if(mode === 'draft' || mode === 'edit'){
        let formData = {
          "content_type": "audio",
          "content_name": content_name,
          "content_file": localStorage.getItem("final_path_tta"),
          "image_thumbnail" : localStorage.getItem("image_thumbnail_tta"),
          "image_array": sessionStorage.getItem("imageArray"),
          "audio_array": sessionStorage.getItem("listTextToAudio"),
          "music_array": sessionStorage.getItem("musicArray"),
          "generate_tta_final": localStorage.getItem('generatedTtaFinal'),
          "public_mode" : this.public_mode,
          "volume_music" : parseFloat(localStorage.getItem('volume_music_tta')),
          "volume_audio" : parseFloat(localStorage.getItem('volume_video_tta')),
          "job_id" : localStorage.getItem("jobs_id_tta"),
          "ratio" : localStorage.getItem("ratio_tta"),
          "is_draft": false
        }

        updateContent(formData, id)
        .then((response) => {
            if(response.status == true){
                this.alertSuccess('Your content successfully saved.')
                this.$store.state.generatedAudioContent = false
                for (var i = 0; i < localStorage.length; i++){
                    var key = localStorage.key(i);
                    if (key != "users" && key != "token_client") {
                        localStorage.removeItem(key);
                    }
                }
                this.removeDataStorage()
                this.$router.push({ name : 'My-Quiz' })
            } else {
                console.log(response)
            }
        })
      } else {
        let formData = {
            "content_type": "audio",
            "content_name": content_name,
            "content_file": localStorage.getItem("final_path_tta"),
            "image_thumbnail" : localStorage.getItem("image_thumbnail_tta"),
            "image_array": sessionStorage.getItem("imageArray"),
            "audio_array": sessionStorage.getItem("listTextToAudio"),
            "music_array": sessionStorage.getItem("musicArray"),
            "generate_tta_final": localStorage.getItem('generatedTtaFinal'),
            "public_mode" : this.public_mode,
            "job_id" : localStorage.getItem("jobs_id_tta"),
            "ratio" : localStorage.getItem("ratio_tta"),
            "is_draft": false
        }

        postContents(formData)
        .then((response) => {
            if(response.status == true){
                this.alertSuccess('Your content successfully saved.')
                this.$store.state.generatedAudioContent = false
                for (var i = 0; i < localStorage.length; i++){
                    var key = localStorage.key(i);
                    if (key != "users" && key != "token_client") {
                        localStorage.removeItem(key);
                    }
                }
                this.removeDataStorage()
                this.$router.push({ name : 'My-Quiz' })
            } else {
                this.$router.push({ name : 'My-Quiz' })
            }
        })
      }
    },

    handleBackButton() {
      this.popStateBack();
    },

    back(){
      if(this.$route.name == 'Generate-Text-To-Audio'){
        let mode = this.$route.params.mode

        if(mode === 'draft' || mode === 'edit'){
          // Cek perubahan
          if(
            sessionStorage.getItem("init_listTextToAudio") == sessionStorage.getItem("listTextToAudio")
            &&
            sessionStorage.getItem("init_imageArray") == sessionStorage.getItem("imageArray")
            &&
            sessionStorage.getItem("init_musicArray") == sessionStorage.getItem("musicArray")
            &&
            localStorage.getItem("init_ratio_tta") == localStorage.getItem("ratio_tta")
            &&
            localStorage.getItem("init_volume_video_tta") == localStorage.getItem("volume_video_tta")
            &&
            localStorage.getItem("init_volume_music_tta") == localStorage.getItem("volume_music_tta")
            &&
            localStorage.getItem("init_content_name_tta") == localStorage.getItem("content_name_tta")
          ) {
            this.removeDataStorage()
            this.$router.push({ name : 'My-Quiz' })
          } else {
            this.$bvModal.show('save-to-draft-tta')
          }
        } else {
          if(
              !localStorage.getItem('content_name_tta') &&
              !localStorage.getItem('final_path_tta') &&
              !localStorage.getItem('image_thumbnail_tta') &&
              !sessionStorage.getItem('imageArray') &&
              !sessionStorage.getItem('listTextToAudio') &&
              !sessionStorage.getItem('musicArray')
          ){
              this.$router.push({ name : 'My-Quiz' })
          } else if (sessionStorage.getItem('listTextToAudio')){

            let tta = JSON.parse(sessionStorage.getItem('listTextToAudio'))

            if(tta.length === 1 && tta[0].text === '' && !sessionStorage.getItem('imageArray') && !sessionStorage.getItem('musicArray')){
              this.$router.push({ name : 'My-Quiz' })
            } else {
              this.$bvModal.show('save-to-draft-tta')
            }
          } else {
            this.$bvModal.show('save-to-draft-tta')
          }
        }
      } else {
        this.$router.push({ name : 'My-Quiz' })
      }
    },

    popStateBack(){
        let mode = this.$route.params.mode

        if(mode === 'draft' || mode === 'edit'){
          // Cek perubahan
          if(
            sessionStorage.getItem("init_listTextToAudio") == sessionStorage.getItem("listTextToAudio")
            &&
            sessionStorage.getItem("init_imageArray") == sessionStorage.getItem("imageArray")
            &&
            sessionStorage.getItem("init_musicArray") == sessionStorage.getItem("musicArray")
            &&
            localStorage.getItem("init_ratio_tta") == localStorage.getItem("ratio_tta")
            &&
            localStorage.getItem("init_volume_video_tta") == localStorage.getItem("volume_video_tta")
            &&
            localStorage.getItem("init_volume_music_tta") == localStorage.getItem("volume_music_tta")
            &&
            localStorage.getItem("init_content_name_tta") == localStorage.getItem("content_name_tta")
          ) {
            this.removeDataStorage()
            this.$router.push({ name : 'My-Quiz' })
          } else {
            this.$bvModal.show('save-to-draft-tta')
          }
        } else {
          if(
              !localStorage.getItem('content_name_tta') &&
              !localStorage.getItem('final_path_tta') &&
              !localStorage.getItem('image_thumbnail_tta') &&
              !sessionStorage.getItem('imageArray') &&
              !sessionStorage.getItem('listTextToAudio') &&
              !sessionStorage.getItem('musicArray')
          ){
              // this.$router.push({ name : 'My-Quiz' })
          } else if (sessionStorage.getItem('listTextToAudio')){

            let tta = JSON.parse(sessionStorage.getItem('listTextToAudio'))

            if(tta.length === 1 && tta[0].text === '' && !sessionStorage.getItem('imageArray') && !sessionStorage.getItem('musicArray')){
              // this.$router.push({ name : 'My-Quiz' })
            } else {
              this.$bvModal.show('save-to-draft-tta')
            }
          } else {
            this.$bvModal.show('save-to-draft-tta')
          }
        }
    },

    removeDataStorage(){
      sessionStorage.removeItem("imageArray")
      sessionStorage.removeItem("listTextToAudio")
      sessionStorage.removeItem("musicArray")
      localStorage.removeItem("final_path_tta")
      localStorage.removeItem("image_thumbnail_tta")
      localStorage.removeItem("content_name_tta")
      localStorage.removeItem("generatedTtaFinal")
      localStorage.removeItem("course_id")
      localStorage.removeItem("volume_music_tta")
      localStorage.removeItem("volume_video_tta")
      localStorage.removeItem("jobs_id_tta")
      localStorage.removeItem("ratio_tta")
    },

    getInitDataTTA(){
      let mode = this.$route.params.mode
      let id = this.$route.params.id
      if(mode === 'draft' || mode === 'edit'){
        getDataContent(id).then(res => {
          if(res.status == true) {
            localStorage.setItem("content_name_tta", res.data.content_name)
            this.content_name = localStorage.getItem('content_name_tta')
          }
        })
      } else {
        if(localStorage.content_name_tta){
          this.content_name = localStorage.content_name_tta;
        }
      }
    },

    downloadVideomp4() {
        let vm = this
        let finalPath = null
        let audio_name = null

        this.loadingMp4 = true

        if(localStorage.content_name_tta){
            audio_name = localStorage.content_name_tta
        }

        if(localStorage.final_path_tta) {
          finalPath = localStorage.final_path_tta
        }

        if(finalPath != null) {
            axios.get(finalPath, { responseType: 'blob' })
            .then(res => {
                vm.exportVidio(res.data, audio_name ? audio_name : 'Audio')
                vm.alertSuccess(this.$t('content-successfully'))
                vm.exportSave()
                this.loadingMp4 = false
            })
            .catch(() => {
              vm.alertFail('Something went wrong !')
              this.loadingMp4 = false
            })
        } else {
            vm.alertFail('Something went wrong !')
            this.loadingMp4 = false
        }
    },

    downloadVideomp3() {
        let vm = this
        let audio_name = null
        let jobsId = null;

        this.loadingMp3 = true

        if(localStorage.content_name_tta){
            audio_name = localStorage.content_name_tta
        }

        if(localStorage.jobs_id_tta){
          jobsId = localStorage.jobs_id_tta
        }

        if(jobsId != null && jobsId != undefined){
            let formData = {
                "job_id" : jobsId
            }
            downloadAudio(formData)
            .then((response) =>{
                if(response.status == 200){
                    let link_mp3 = base_url_machine_learning + response.data
                    axios.get(link_mp3, { responseType: 'blob' })
                    .then(res => {
                        vm.exportAudio(res.data, audio_name ? audio_name : 'Audio')
                        vm.alertSuccess('Audio successfully downloaded')
                        this.loadingMp3 = false
                        vm.exportSave()
                    })
                    .catch(() => {
                      vm.alertFail('Something went wrong !')
                      this.loadingMp3 = false
                    })
                }
            })
            .catch(() => {
              vm.alertFail('Something went wrong !')
              this.loadingMp3 = false
            })
        } else {
            vm.alertFail('Something went wrong !')
            this.loadingMp3 = false
        }
    },

    async copyLink(){
      let vm = this

      vm.loadingCopyLink = true;

      await this.exportSave()// tunggu hingga ekspor selesai
      if (vm.link != null) {
        navigator.clipboard.writeText(vm.link)
          .then(() => {
            vm.alertSuccess('Link successfully copied');
            vm.loadingCopyLink = false;
          })
          .catch(() => {
            vm.alertFail('Link failed to copy');
            vm.loadingCopyLink = false;
          });
      } else {
        vm.alertFail('Link failed to copy');
        vm.loadingCopyLink = false;
      }
    },

    async exportSave(){
      let mode = this.$route.params.mode
      let id = this.$route.params.id
      let content_name = "Content Audio"

      if(localStorage.getItem('content_name_tta')) {
          content_name = localStorage.getItem('content_name_tta')
      }

      if(mode === 'draft' || mode === 'edit'){
        let formData = {
          "content_type": "audio",
          "content_name": content_name,
          "content_file": localStorage.getItem("final_path_tta"),
          "image_thumbnail" : localStorage.getItem("image_thumbnail_tta"),
          "image_array": sessionStorage.getItem("imageArray"),
          "audio_array": sessionStorage.getItem("listTextToAudio"),
          "music_array": sessionStorage.getItem("musicArray"),
          "generate_tta_final": localStorage.getItem('generatedTtaFinal'),
          "public_mode" : this.public_mode,
          "volume_music" : parseFloat(localStorage.getItem('volume_music_tta')),
          "volume_audio" : parseFloat(localStorage.getItem('volume_video_tta')),
          "ratio" : localStorage.getItem("ratio_tta"),
          "job_id" : localStorage.getItem("jobs_id_tta"),
          "is_draft": false
        }

        await updateContent(formData, id)
        .then((response) => {
            if(response.status == true){
                // this.alertSuccess('Your content successfully saved.')
                this.link = base_url_home + 'share/' + response.data.content_name.replace(/ /g, '-') + '/' + id
            } else {
                console.log(response)
            }
        })
      } else {
        let formData = {
            "content_type": "audio",
            "content_name": content_name,
            "content_file": localStorage.getItem("final_path_tta"),
            "image_thumbnail" : localStorage.getItem("image_thumbnail_tta"),
            "image_array": sessionStorage.getItem("imageArray"),
            "audio_array": sessionStorage.getItem("listTextToAudio"),
            "music_array": sessionStorage.getItem("musicArray"),
            "generate_tta_final": localStorage.getItem('generatedTtaFinal'),
            "public_mode" : this.public_mode,
            "ratio" : localStorage.getItem("ratio_tta"),
            "job_id" : localStorage.getItem("jobs_id_tta"),
            "is_draft": false
        }

        await postContents(formData)
        .then((response) => {
            if(response.status == true){
                // this.alertSuccess('Your content successfully saved.')
                this.link = base_url_home + 'share/' + response.data.content_name.replace(/ /g, '-') + '/' + response.data._id
                this.$router.replace({ params: { mode : 'edit', id : response.data._id } })
            }
        })
      }
    },

    closeExport(){
      this.loadingCopyLink = false
      this.loadingMp3 = false
      this.loadingMp4 = false
      this.$bvModal.hide('export-tta-modal')
    }
  },

  beforeDestroy() {
    window.removeEventListener('popstate', this.popStateBack);
  },
}
</script>

<style scoped>
.topnavbar{
  background-color: #000 !important;
}

.btn-purple{
  background-color: #6D75F6;
  color: white;
}

.btn-fill-purple {
  border: 1px solid #6D75F6;
  color: #6D75F6;
}

.btn-fill-dark {
  border: 1px solid #1F1F1F;
  color: #585859;
  cursor:context-menu !important;
}

.btn-dark {
  background-color: #1F1F1F;
  color: #585859;
  cursor:context-menu !important;
}

.btn-purple-export{
  background-color: #6D75F6;
  color: white;
  width: 130px;
}

.form-control {
    background: transparent;
    border: 0;
    box-shadow: none;
    outline: none;
    color: white;
    font-size: 16px;
}

input {
  text-align: center;
  font-size: 16px;
}

::-webkit-input-placeholder {
  text-align: center;
  color: #585859;
}

:-moz-placeholder {
  text-align: center;
  color: #585859;
}

.ic-pencil {
  top: 11px;
  right: 60px;
  width: 14px;
}

.information:hover .tool-tip{
  display: block;
}

.tool-tip {
    left: -157px !important;
    width: 232px !important;
    background-color: #1F1F1F;
    color: #ffffff;
    font-size: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
}

.text-plan{
  color: #8C8C8C
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-close{
  background-color: #EDEDED;
  border-radius: 50%;
  width: 15px;
  height: 26px;
}

.card-export {
  background-color: #2D2D2D !important;
  height: 200px;
  border-radius: 10px;
}
</style>
<style>
#export-tta-modal .modal-content{
  background-color: #1F1F1F;
  color: white;
}

#export-tta-modal .modal-md {
  max-width: 590px !important;
}
</style>